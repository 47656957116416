<section class="center">

    <div >

        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px" *ngIf="!showMessage">
            <app-loading-spinner></app-loading-spinner>
        </div>
 
        <div class="mat-elevation-z8  mid" *ngIf="showMessage"> 
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px" >
                <h1>Maintenance</h1>

                    <h3 class="margin-top">Sorry for the incovenience.</h3>
                    <h3>We'll be back up and running as fast as possible.</h3>
             
            </div>
        </div>


    </div>


</section>