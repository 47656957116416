import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs/tab-group';
import { PaymentDateElement, DashboardService, PartnerNameElement } from './dashboard.service';
import { Subscription } from 'rxjs';

import { FormsModule } from '@angular/forms';
import { API } from 'src/app/api.component';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {

  private _subscriptions: Subscription[] = [];

  showRevenue: boolean = true;
  showAssetChart: boolean = true;
  showCountryChart: boolean = true;
  showDspChart: boolean = true;
  showTypeOfSaleChart: boolean = true;
  showArtistChart: boolean = true;
  showLabelChart: boolean = true;

  showArtistData: boolean = false; 
  showLabelData: boolean = false; 

  showFilters = false;

  displayPartnersSelection = false;
  partnersWithPayments: PartnerNameElement[] = [];
  componentSelectedPartner: PartnerNameElement;

  paymentDates: PaymentDateElement[] = [];
  componentSelectedDate: PaymentDateElement;


  constructor(private _dashboardService: DashboardService) {
    this.componentSelectedDate = this._dashboardService.selectedDate;

  }

  get allowPartnerChange() {
    return this._dashboardService.isAdmin || this._dashboardService.isModerator;
  }

  ngOnInit(): void {
  
    let subscription1 = this._dashboardService.accountObservable
    .subscribe(empty => {
      this.componentSelectedPartner = this._dashboardService.selectedPartner;
      if(API.DEBUG_MODE) 
      console.log("account: " + JSON.stringify(this.componentSelectedPartner));
      if(this._dashboardService.isAdmin || this._dashboardService.isModerator) {
        this.displayPartnersSelection = true;

        let subscription3 = this._dashboardService.partnerNamesObservable
        .subscribe(loadedPartnerNames => {
          this.partnersWithPayments = loadedPartnerNames;
          this.componentSelectedPartner = this._dashboardService.selectedPartner;
          if(API.DEBUG_MODE) 
          console.log("load partners: " + JSON.stringify(this.componentSelectedPartner));
        });

        this._subscriptions.push(subscription3);
      }
    });

    let subscription2 = this._dashboardService.paymentDatesObservable
    .subscribe(loadedPaymentDates => {
      this.paymentDates = loadedPaymentDates;
      this.componentSelectedDate = this._dashboardService.selectedDate;
    });

    let subscription3 = this._dashboardService.showChartsDataObservable.subscribe(showChartsMap => {
      this.showArtistData = showChartsMap.get('artist');
      this.showLabelData = showChartsMap.get('label');
      
    });

    this._subscriptions.push(subscription1);
    this._subscriptions.push(subscription2);
    this._subscriptions.push(subscription3);
  }

  ngAfterViewInit(): void {
    this.componentSelectedPartner = this._dashboardService.selectedPartner;
    if(API.DEBUG_MODE) 
    console.log("constructor: " + JSON.stringify(this.componentSelectedPartner));
    this._dashboardService.initialLoad();
    window.dispatchEvent(new Event('resize'));
  }

  ngOnDestroy() : void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onChangePartner(newPartner: PartnerNameElement) : void {
    this.componentSelectedPartner = newPartner;
    if(API.DEBUG_MODE) 
    console.log("Change partner listener: " + JSON.stringify(newPartner));
    this._dashboardService.changePartner(newPartner);
  }

  onChangeDate(newDate: PaymentDateElement) : void {
    this.componentSelectedDate = newDate;
    if(API.DEBUG_MODE) 
    console.log("Change date listener: " + JSON.stringify(newDate));
    this._dashboardService.changeDate(newDate);
  }

  overallPanelChange(event: MatTabChangeEvent) {
      this.showRevenue = !this.showRevenue;
  }

  dspPanelChange(event: MatTabChangeEvent) {
    this.showDspChart = !this.showDspChart;
    window.dispatchEvent(new Event('resize'));
  }

  countryPanelChange(event: MatTabChangeEvent) {
    this.showCountryChart = !this.showCountryChart;
    window.dispatchEvent(new Event('resize'));
  }

  typeOfSalePanelChange(event: MatTabChangeEvent) {
    this.showTypeOfSaleChart = !this.showTypeOfSaleChart;
    window.dispatchEvent(new Event('resize'));
  }

  labelPanelChange(event: MatTabChangeEvent) {
    this.showLabelChart = !this.showLabelChart;
    window.dispatchEvent(new Event('resize'));
  }

  artistPanelChange(event: MatTabChangeEvent) {
    this.showArtistChart = !this.showArtistChart;
    window.dispatchEvent(new Event('resize'));
  }

  assetPanelChange(event: MatTabChangeEvent) {
    this.showAssetChart = !this.showAssetChart;
    window.dispatchEvent(new Event('resize'));
  }
}
