import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';


import { ExportToCsv } from 'export-to-csv';
import { catchError, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError, Subscription } from 'rxjs';
import { AdminLabelService } from './admin-labels.service';
import { SnackBarComponent } from 'src/app/components/snack-bar/snack-bar/snack-bar.component';
import { AdminPartnerService } from '../admin-partners/admin-partner.service';
import { AuthService } from 'src/app/auth/auth.service';

export interface AdminLabelElement {
  identifier: string;
  fugaName: string;
  fugaId: number;
  partner: string;
  createdBy: string;
  createdOn: string;
  modifiedBy: string;
  modifiedOn: string;
}

export interface AdminLabelPartnerElement {
  identifier: string;
  name: string;
}

@Component({
  selector: 'app-admin-labels',
  templateUrl: './admin-labels.component.html',
  styleUrls: ['./admin-labels.component.scss']
})
export class AdminLabelsComponent implements OnInit, AfterViewInit, OnDestroy {

  private _subscriptions: Subscription[] = [];

  isLoading = false;

  showMetadata = false;
  private _adminShowMetadataColumns = ['identifier', 'fugaId', 'fugaName', 'partner', 'createdBy', 'createdOn', 'modifiedBy', 'modifiedOn', 'action'];
  private _adminHideMetadataColumns = ['fugaId', 'fugaName', 'partner', 'action'];
  private _moderatorHideMetadataColumns = ['fugaId', 'fugaName', 'partner'];
  displayedColumns: string[];

  dataSource = new MatTableDataSource<AdminLabelElement>();
  private _data: AdminLabelElement[] = [];
  adminSimplePartnersData: AdminLabelPartnerElement[] = [];

  editingElement: AdminLabelElement = null;
  changedElement: AdminLabelElement = null;

  private _isAdmin: boolean = false;

  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;


  constructor(private _authService: AuthService, private _adminLabelService: AdminLabelService, private _adminPartnerService: AdminPartnerService, private _snackBarComponent: SnackBarComponent) {
    this._isAdmin = this._authService.isAdmin();
    this.displayedColumns = this._isAdmin ? this._adminHideMetadataColumns : this._moderatorHideMetadataColumns;
  }

  get isAdmin(): boolean {
    return this._isAdmin;
  }

  ngOnInit(): void {
    this.dataSource.data = this._data;

    this._subscriptions.push(this.loadAllLabels());

    if (this._isAdmin) {
      this._subscriptions.push(this.loadAllSimplePartners());
    }
  }

  private loadAllLabels(): Subscription {
    return this._adminLabelService.getAllLabels()
    .subscribe(resData => {

      if (resData.out_succeed === true && resData.out_data != null) {
        this._data = <AdminLabelElement[]>resData.out_data;
        this.dataSource.data = this._data;
      }
    });
  }

  private loadAllSimplePartners(): Subscription {
    return this._adminPartnerService.getAllPartnersSimple()
    .subscribe(resData => {

      if (resData.out_succeed === true && resData.out_data != null) {
        this.adminSimplePartnersData = <AdminLabelPartnerElement[]>resData.out_data;
      }
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  doFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onEdit(selectedElement: AdminLabelElement): void {

    if (!this.isAdmin)
      return;

    this.editingElement = Object.assign({}, selectedElement);
    this.changedElement = Object.assign({}, selectedElement);
  }

  onEditCancel(): void {

    if (!this.isAdmin)
      return;

    this.editingElement = null;
    this.changedElement = null;
  }

  onEditSave(selectedElement: AdminLabelElement): void {

    if (!this.isAdmin)
      return;

    let partnerIdentifier = null;

    if (this.changedElement.partner === null || this.changedElement.partner === "") {
      partnerIdentifier = null
    } else {
      const partner = this.adminSimplePartnersData.find(element => element.name === this.changedElement.partner);
      partnerIdentifier = partner.identifier;
    }

    let subscription: Subscription = this._adminLabelService.editLabel(selectedElement.identifier, partnerIdentifier)
    .subscribe(resData => {
        if (resData.out_succeed === true && resData.out_data != null) {
          var labelElement: AdminLabelElement = <AdminLabelElement>resData.out_data;
          let index: number = this._data.findIndex(element => element === selectedElement);

          this._data.splice(index, 1, labelElement);
          this.dataSource.data = [...this._data];
        }
      });

    this._subscriptions.push(subscription);

    this.editingElement = null;
    this.changedElement = null;
  }

  onMetadata(): void {

    if (!this.isAdmin)
      return;

    this.showMetadata = !this.showMetadata;

    if (this.showMetadata) {
      this.displayedColumns = this._adminShowMetadataColumns;

    } else {
      this.displayedColumns = this._adminHideMetadataColumns;
    }
  }

  onDownload(): void {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Admin Labels CSV',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      headers: this.displayedColumns
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(this.dataSource.data);
  }
}