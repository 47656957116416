<div fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="10px" style="height: 100%;">
    <h1 mat-dialog-title>New Partner</h1>
    <div fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="10px" style="height: 100%;">
        <p>New partner details:</p>


        <mat-form-field>
            <mat-label>Partner Name</mat-label>
            <input matInput placeholder="Name" [(ngModel)]="data.name" [value]="data.name">
        </mat-form-field>

        <div>
            <mat-slide-toggle color="primary" [checked]="data.isActive" [(ngModel)]="data.isActive">Is Active?
            </mat-slide-toggle>
        </div>
        <div>
            <mat-slide-toggle color="primary" [checked]="data.allowSubPartners" [(ngModel)]="data.allowSubPartners">
                Allow
                Sub Partners?</mat-slide-toggle>
        </div>
        <div mat-dialog-actions>
            <button mat-button (click)="onCancel()" cdkFocusInitial>Cancel</button>
            <button mat-button (click)="onCreate()">Create</button>
        </div>
    </div>
</div>