import { Component, OnInit, Input, ViewChild, HostListener, OnChanges, OnDestroy } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DashboardService, DataChartElement } from 'src/app/user/dashboard/dashboard.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { API } from 'src/app/api.component';

/*
var single = [
  {
    "name": "Salvador Sobral",
    "value": 8940000
  },
  {
    "name": "USA",
    "value": 5000000
  },
  {
    "name": "France",
    "value": 7200000
  },
  {
    "name": "Germany1",
    "value": 8940000
  },
  {
    "name": "USA1",
    "value": 5000000
  },
  {
    "name": "France1",
    "value": 7200000
  },
  {
    "name": "Germany2",
    "value": 8940000
  },
  {
    "name": "USA2",
    "value": 5000000
  },
  {
    "name": "France2",
    "value": 7200000
  },
  {
    "name": "USA3",
    "value": 5000000
  },
  {
    "name": "Others",
    "value": 7200000
  }
];

var tableDate = [
  {
    "name": "Salvador Sobral",
    "revenue": 10000,
    "items": 8940000
  },
  {
    "name": "USA",
    "revenue": 9000,
    "items": 5000000
  },
  {
    "name": "France",
    "revenue": 8000,
    "items": 7200000
  },
  {
    "name": "Germany1",
    "revenue": 7000,
    "items": 8940000
  },
  {
    "name": "USA1",
    "revenue": 6000,
    "items": 5000000
  },
  {
    "name": "France1",
    "revenue": 5000,
    "items": 7200000
  },
  {
    "name": "Germany2",
    "revenue": 4000,
    "items": 8940000
  },
  {
    "name": "USA2",
    "revenue": 3000,
    "items": 5000000
  },
  {
    "name": "France2",
    "revenue": 2000,
    "items": 7200000
  },
  {
    "name": "USA3",
    "revenue": 1000,
    "items": 5000000
  },
  {
    "name": "Others",
    "revenue": 3000,
    "items": 7200000
  }
  
];
*/
@Component({
  selector: 'app-country-chart',
  templateUrl: './country-chart.component.html',
  styleUrls: ['./country-chart.component.scss']
})
export class CountryChartComponent implements OnInit, OnChanges, OnDestroy {

  @Input() showCountryChart: boolean;

  displayedColumns: string[] = ['name', 'revenue', 'items'];
  dataSource = new MatTableDataSource<DataChartElement>();
  footer: DataChartElement;

  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  private _subscriptions: Subscription[] = [];

  single: any[];
  view: any[];

  // options
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = true;
  explodeSlices: boolean = false;
  legendPosition: string = 'right';

  colorScheme = {
    domain: ['#00876c', '#459e70', '#72b373', '#9fc878', '#cedc80', '#ffee8c', '#fdcd70', '#f9ab5c', '#f18851', '#e5644e', '#d43d51']
  };

  constructor(private _dashboardService: DashboardService) {
    //Object.assign(this, { single });
  }

  ngOnInit(): void {
    this.dataSource.data = [];
    
    let subscription: Subscription = this._dashboardService.countryDataObservable
    .subscribe(data => {
      this.footer = data.shift();
      this.dataSource.data = data;
      this.single = data;
    });

    this._subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnChanges(changes) {
    window.dispatchEvent(new Event('resize'));
    if(API.DEBUG_MODE) 
    console.log("changed!!!");
  }

  onSelect(data): void {
    if(API.DEBUG_MODE) 
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    if(API.DEBUG_MODE) 
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    if(API.DEBUG_MODE) 
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.view = null;
  }
}