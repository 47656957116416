import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
  

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent implements OnInit {
  
  private static ACTION = 'Dismiss';

  private static DURATION = 5000;

  private static GREEN_BACKGROUND = 'green-snackbar';
  private static YELLOW_BACKGROUND = 'yellow-snackbar';
  private static RED_BACKGROUND = 'red-snackbar';

  // start, center, end, left, right
  private horizontalPosition: MatSnackBarHorizontalPosition = 'center';

  // top, bottom
  private verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  successMessage(message: string) {
    this._snackBar.open(message, SnackBarComponent.ACTION, {
      duration: SnackBarComponent.DURATION,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: [SnackBarComponent.GREEN_BACKGROUND]
    });
  }

  warningMessage(message: string) {
    this._snackBar.open(message, SnackBarComponent.ACTION, {
      duration: SnackBarComponent.DURATION,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: [SnackBarComponent.YELLOW_BACKGROUND]
    });
  }

  errorMessage(message: string) {
    this._snackBar.open(message, SnackBarComponent.ACTION, {
      duration: SnackBarComponent.DURATION,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: [SnackBarComponent.RED_BACKGROUND]
    });
  }

    errorMessageWithFactor(message: string, factor: number) {
      this._snackBar.open(message, SnackBarComponent.ACTION, {
        duration: SnackBarComponent.DURATION * factor,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: [SnackBarComponent.RED_BACKGROUND]
      });
  }

}
