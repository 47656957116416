import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { DashboardService } from 'src/app/user/dashboard/dashboard.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { API } from 'src/app/api.component';

/*
 var multi2 = [
  {
    "name": "Portugal",
    "series": [
      {
        "name": "Jan",
        "value": 62000000
      },
      {
        "name": "Feb",
        "value": 73000000
      },
      {
        "name": "Mar",
        "value": 89400000
      },
      {
        "name": "Abr",
        "value": 62000000
      },
      {
        "name": "May",
        "value": 73000000
      },
      {
        "name": "Jun",
        "value": 89400000
      },
      {
        "name": "Jul",
        "value": 62000000
      },
      {
        "name": "Aug",
        "value": 73000000
      },
      {
        "name": "Sep",
        "value": 89400000
      },
      {
        "name": "Oct",
        "value": 89400000
      },
      {
        "name": "Nov",
        "value": 89400000
      },
      {
        "name": "Dec",
        "value": 89400000
      }
    ]
  }
];
*/


@Component({
  selector: 'app-item-chart',
  templateUrl: './item-chart.component.html',
  styleUrls: ['./item-chart.component.scss']
})
export class ItemChartComponent implements OnInit, OnDestroy{
  private _subscriptions: Subscription[] = [];

  view: any[];
  multi2: any[] = [];

  // options
  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Month';
  yAxisLabel: string = 'Items';
  timeline: boolean = true;
  gradient: boolean = true;


  colorScheme = {
    domain: ['#E44D25']
  };

  constructor(private _dashboardService: DashboardService) {
    //Object.assign(this, { multi2 });
  }

  ngOnInit(): void {
    let subscription: Subscription = this._dashboardService.overallItemsDataObservable
    .subscribe(data => this.multi2 = [{
                                      "name": "Overall Items",
                                      "series": data
                                    }]);

    this._subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onSelect(event) {
    if(API.DEBUG_MODE) 
    console.log(event);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.view = null;
  }
}