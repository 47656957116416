


<!-- 
    <div class="flex-container-half" fxLayout="row" fxLayoutAlign="space-evenly" fxLayoutGap="30px" fxWrap="wrap">
      <mat-card fxLayout="column" fxLayoutAlign="space-between" fxWrap="wrap" matTooltip="Info about the action">
      
        <mat-card-title>100.0 €</mat-card-title>
        <mat-card-subtitle>Avg. Revenue</mat-card-subtitle>
      </mat-card>

      <mat-card fxLayout="column" fxLayoutAlign="space-between" fxWrap="wrap" aria-label="Button that shows a red tooltip">
 
        <mat-card-title>100.0 €</mat-card-title>
        <mat-card-subtitle>Total. Revenue</mat-card-subtitle>
      </mat-card>

      <mat-card fxLayout="column" fxLayoutAlign="space-between" fxWrap="wrap" aria-label="Button that shows a red tooltip">
     
        <mat-card-title>100.0 €</mat-card-title>
        <mat-card-subtitle>Total. Items</mat-card-subtitle>
      </mat-card>

      <mat-card fxLayout="column" fxLayoutAlign="space-between" fxWrap="wrap" aria-label="Button that shows a red tooltip">
    
        <mat-card-title>100.0 €</mat-card-title>
        <mat-card-subtitle>Avg. Items</mat-card-subtitle>
      </mat-card>
    </div>
  -->




  <div class="flex-container-full" fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="10px" fxWrap="wrap">
    <!--
    <div class="flex-container-full" fxLayout="row" fxLayoutAlign="space-evenly" fxLayoutGap="10px" fxWrap="wrap">
      <mat-icon matTooltip="My tooltip 1">delete</mat-icon>
      <mat-icon matTooltip="My tooltip 2">delete</mat-icon>
      <mat-icon matTooltip="My tooltip 3">delete</mat-icon>
      <mat-icon matTooltip="My tooltip 4">delete</mat-icon>

    </div>
    -->

    <ngx-charts-number-card
      [view]="view"
      [scheme]="colorScheme"
      [results]="single"
      [cardColor]="cardColor"
      (select)="onSelect($event)">

    </ngx-charts-number-card>
  </div>
  
  
  
    <div class="flex-container-full" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px" fxWrap="wrap">
  
      <mat-card class="flex-container-full" fxLayout="column" fxLayoutAlign="space-between" fxWrap="wrap" style="width: 100%;height: 100%;" *ngIf="cardSelectedIndex == 0">
  
        <mat-card-subtitle>Avg. Revenue [€]</mat-card-subtitle>
        <mat-card-content>Average Monthly Net Revenue in the selected period. To see your Average Monthly Revenue since the beginning, select “All Time” in the “Select date” field.</mat-card-content>
      </mat-card>
  
      <mat-card class="flex-container-full" fxLayout="column" fxLayoutAlign="space-between" fxWrap="wrap" style="width: 100%;height: 100%;" *ngIf="cardSelectedIndex == 1">
        <mat-card-subtitle>Total Revenue [€]</mat-card-subtitle>
        <mat-card-content>Your Total Revenue in the selected period. To see your Total Revenue since the beginning, select “All Time” in the “Select date” field.</mat-card-content>
      </mat-card>
  
      <mat-card class="flex-container-full" fxLayout="column" fxLayoutAlign="space-between" fxWrap="wrap" style="width: 100%;height: 100%;" *ngIf="cardSelectedIndex == 2">
        <mat-card-subtitle>Total Items</mat-card-subtitle>
        <mat-card-content>The total number of Streams/Sales reported in the selected period. To see your Total Items since the beginning, select “All Time” in the “Select date” field.</mat-card-content>
      </mat-card>
  
      <mat-card class="flex-container-full" fxLayout="column" fxLayoutAlign="space-between" fxWrap="wrap" style="width: 100%;height: 100%;" *ngIf="cardSelectedIndex == 3">
  
        <mat-card-subtitle>Avg. Items</mat-card-subtitle>
        <mat-card-content>The average monthly number of Streams/Sales reported in the selected period. To see your Average Monthly Items since the beginning, select “All Time” in the “Select date” field.</mat-card-content>
      </mat-card>
    </div>
  
  
  
  