import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { API } from '../../api.component';
import { AuthService } from '../../auth/auth.service';
import { ResponseData } from '../../response-data.model';

import { SnackBarComponent } from 'src/app/components/snack-bar/snack-bar/snack-bar.component';

import { catchError, tap } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';



@Injectable({ providedIn: 'root' })
export class AdminFugaService {

  private _isDownloading = new BehaviorSubject<boolean>(false);
  isDownloadingObservable = this._isDownloading.asObservable();

  private _accessToken: string = null;
  private _downloadingFiles: number = 0;

  constructor(private _authService: AuthService, private _httpClient: HttpClient, private _snackBarComponent: SnackBarComponent) {
    this.init();
  }

  init(): void {
    this._authService.serviceAccessTokenObservable.subscribe(accessToken => {
      this._accessToken = accessToken;
    });
  }

  private privateHeader() {
    return new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this._accessToken
      });
  }

  private downloadStarted(): void {
    this._downloadingFiles++;

    if(this._downloadingFiles === 1)
    this._isDownloading.next(true);
  }

  private downloadFinished(): void {
    this._downloadingFiles--;

    if(this._downloadingFiles === 0)
    this._isDownloading.next(false);
  }

  getFuga() {
    return this._httpClient.get<ResponseData>(API.FUGA_GET, { headers: this.privateHeader() })
    .pipe(
      catchError(this.handleUnexpectedError),
      tap(resData => this.defaultResponseHandling(resData, "FUGA snapshot was successfully loaded!", resData.out_succeed === true && resData.out_data != null))
      );
  }

  uploadFuga() {
    this.downloadStarted();
    return this._httpClient.post<ResponseData>(API.FUGA_UPLOAD, null, { headers: this.privateHeader() })
    .pipe(
      catchError(this.handleUnexpectedError),
      tap(resData => {
        this.defaultResponseHandling(resData, "FUGA snapshot has been successfully created.", resData.out_succeed === true && resData.out_data != null);
        this.downloadFinished();
        if(API.DEBUG_MODE) 
        console.log("Recebi 1 " + JSON.stringify(resData.out_data));
      })
    );
  }

  private defaultResponseHandling(resData: ResponseData, successMessage: string, condition: boolean) {
    if (resData.out_succeed == true && (condition == null || (condition != null && condition))) {
      if(successMessage != null)
        this._snackBarComponent.successMessage(successMessage);
    } else {
      this.handleResponseWarning(resData);
    }

    if (resData.out_error == true) {
      this.handleResponseError(resData);
    }
  }

  private handleResponseWarning(resData: ResponseData) {
    
    if(resData.out_succeed === false) {

      switch (resData.out_msg) {
        case 'UNABLE_TO_START_PROCESS':
          this._snackBarComponent.warningMessage("Unable to start process.");
          break;

        case 'UNABLE_TO_CLEAR_FUGA_TABLES':
          this._snackBarComponent.warningMessage("Unable to clear old FUGA snapshot.");
          break;
        
        case 'UNABLE_TO_FINISH_PROCESS':
          this._snackBarComponent.warningMessage("Unable to finish the process.");
          break;

        case 'UNAUTHORIZED':
          this._snackBarComponent.warningMessage("Unauthorized.");
          break;

        default:
          this._snackBarComponent.warningMessage("An unexpected error occurred, please contact your system admin.");
          break;
      }
    }
  }
  
  private handleResponseError(resData: ResponseData) {
        if(resData.out_error) {
        switch (resData.out_msg) {

          case 'UNAUTHORIZED':
            this._snackBarComponent.errorMessage("Unauthorized.");
            break;

          case 'UNKNOWN_ERROR':
            this._snackBarComponent.errorMessage("An unknown error occurred, please contact your system admin.");
            break;
        }
      }
  }

  private handleUnexpectedError(errorRes: HttpErrorResponse) {
    if(API.DEBUG_MODE) 
    console.log("Errors: " + JSON.stringify(errorRes));


    if (errorRes.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      if(API.DEBUG_MODE) 
      console.log('An error occurred:', errorRes.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      if(API.DEBUG_MODE) 
      console.log(
        `Backend returned code ${errorRes.status}, ` +
        `body was: ${errorRes.error}`);

        let errorMessage = 'An unknown error occurred!';
        if (!errorRes.error || !errorRes.error.error) {
          return throwError(errorMessage);
        }
    
        switch (errorRes.status) {
          case 400:
            this._snackBarComponent.errorMessage("A problem occurred, please contact your system admin.");
            break;

          case 401:
            this._snackBarComponent.errorMessage("Unauthorized.");
            break;

          case 500:
            this._snackBarComponent.errorMessage("An unknown error occurred, please contact your system admin.");
            break;

        }
        return throwError(errorMessage);
    }
  }
}