export class API {


    // ng serve --open

    public static PRO_BASE_URI = "https://mrp.thumbmedia.net/api/v1/";
    public static DEV_BASE_URI = "http://localhost:8080/api/v1/";


    public static DEBUG_MODE = false;
    public static BASE_URI = API.PRO_BASE_URI;
    


    // Auth Service
    public static AUTH_SERVICE = API.BASE_URI + "/auth";

    public static AUTH_ALIVE = API.AUTH_SERVICE + "/alive";
    public static AUTH_LOGIN = API.AUTH_SERVICE + "/login";
    public static AUTH_REFRESH = API.AUTH_SERVICE + "/refresh";
    public static AUTH_LOGOUT = API.AUTH_SERVICE + "/logout";
    public static AUTH_FORGOT = API.AUTH_SERVICE + "/forgot";
    public static AUTH_RESET_CODE = API.AUTH_SERVICE + "/resetcode";
    public static AUTH_RESET = API.AUTH_SERVICE + "/reset";
    public static AUTH_CHANGE = API.AUTH_SERVICE + "/change";
    public static AUTH_EDIT_PROFILE = API.AUTH_SERVICE + "/profile";

    // Accounts Service
    public static ACCOUNT_SERVICE = API.BASE_URI + "/accounts";

    public static ACCOUNT_GET_ALL = API.ACCOUNT_SERVICE;
    public static ACCOUNT_GET_ALL_AUTHORITIES = API.ACCOUNT_SERVICE + "/authorities";
    public static ACCOUNT_CREATE = API.ACCOUNT_SERVICE;
    public static ACCOUNT_EDIT = API.ACCOUNT_SERVICE;
    public static ACCOUNT_DELETE = API.ACCOUNT_SERVICE;


    // Accounts Service
    public static DASHBOARD_SERVICE = API.BASE_URI + "/dashboard";

    public static DASHBOARD_GET_ALL_PARTNERS_WITH_PAYMENTS = API.DASHBOARD_SERVICE + "/partners";
    public static DASHBOARD_GET_ALL_PARTNER_PAYMENT_DATES = API.DASHBOARD_SERVICE  + "/dates";
    public static DASHBOARD_GET_ALL_PARTNER_OVERALL_STATS = API.DASHBOARD_SERVICE  + "/overall";
    public static DASHBOARD_GET_ALL_PARTNER_MONTHLY_STATS = API.DASHBOARD_SERVICE + "/monthly";

    // Partner Service
    public static PARTNER_SERVICE = API.BASE_URI + "/partners";

    public static PARTNER_GET_ALL = API.PARTNER_SERVICE;
    public static PARTNER_GET_ALL_SIMPLE = API.PARTNER_SERVICE + "/simple";
    public static PARTNER_GET_ALL_ALL = API.PARTNER_SERVICE + "/all";
    public static PARTNER_GET_ALL_AUTHORITIES = API.PARTNER_SERVICE + "/authorities";
    public static PARTNER_CREATE = API.PARTNER_SERVICE;
    public static PARTNER_EDIT = API.PARTNER_SERVICE;
    public static PARTNER_DELETE = API.PARTNER_SERVICE;

    // Sub Partner Service
    public static SUB_PARTNER_SERVICE = API.BASE_URI + "/subpartners";

    public static SUB_PARTNER_GET_ALL = API.SUB_PARTNER_SERVICE;
    public static SUB_PARTNER_GET_ALL_SIMPLE = API.SUB_PARTNER_SERVICE + "/simple";
    public static SUB_PARTNER_CREATE = API.SUB_PARTNER_SERVICE;
    public static SUB_PARTNER_EDIT = API.SUB_PARTNER_SERVICE;
    public static SUB_PARTNER_DELETE = API.SUB_PARTNER_SERVICE;

    // Label Service
    public static LABEL_SERVICE = API.BASE_URI + "/labels";

    public static LABEL_GET_ALL = API.LABEL_SERVICE;
    public static LABEL_CREATE = API.LABEL_SERVICE;
    public static LABEL_EDIT = API.LABEL_SERVICE;
    public static LABEL_DELETE = API.LABEL_SERVICE;

    // Artist Service
    public static ARTIST_SERVICE = API.BASE_URI + "/artists";

    public static ARTIST_GET_ALL = API.ARTIST_SERVICE;
    public static ARTIST_GET_ALL_SIMPLE = API.ARTIST_SERVICE + "/simple";

    // Payment Service
    public static PAYMENT_SERVICE = API.BASE_URI + "/payments";

    public static PAYMENT_GET_ALL_FULL = API.PAYMENT_SERVICE;
    public static PAYMENT_GET_ALL_STATUS = API.PAYMENT_SERVICE + "/status";
    public static PAYMENT_GET_ALL_PARTIAL = API.PAYMENT_SERVICE + "/partial";
    public static PAYMENT_DOWNLOAD = API.PAYMENT_SERVICE + "/download";
    public static PAYMENT_CREATE = API.PAYMENT_SERVICE;
    public static PAYMENT_EDIT = API.PAYMENT_SERVICE;
    public static PAYMENT_DELETE = API.PAYMENT_SERVICE;

    // Mapping Service
    public static PAYOUT_SERVICE = API.BASE_URI + "/mappings";

    public static MAPPING_GET_ALL = API.PAYOUT_SERVICE;
    public static MAPPING_CREATE = API.PAYOUT_SERVICE;
    public static MAPPING_EDIT = API.PAYOUT_SERVICE;
    public static MAPPING_DELETE = API.PAYOUT_SERVICE;

    // Report Service
    public static REPORT_SERVICE = API.BASE_URI + "/reports";

    public static REPORT_GET_ALL = API.REPORT_SERVICE;
    public static REPORT_GET_MISSING_MATCHES_GLOBAL_DETAILS = API.REPORT_SERVICE + "/missing/global";
    public static REPORT_GET_MISSING_MATCHES_DETAILS = API.REPORT_SERVICE + "/missing/details";
    public static REPORT_GET_ALL_PAYMENTS = API.REPORT_SERVICE + "/payments";

    public static REPORT_UPLOAD = API.REPORT_SERVICE;
    public static REPORT_DOWNLOAD = API.REPORT_SERVICE + "/download";
    public static REPORT_MATCH = API.REPORT_SERVICE + "/match";
    public static REPORT_GENERATE_PAYMENTS = API.REPORT_SERVICE + "/generate";
    public static REPORT_VALIDATE_PAYMENTS = API.REPORT_SERVICE + "/validate";
    public static REPORT_PUBLISH_PAYMENTS = API.REPORT_SERVICE + "/publish";
    public static REPORT_WAIT_FOR_RECIPTS_PAYMENTS = API.REPORT_SERVICE + "/recipts";
    public static REPORT_DELETE = API.REPORT_SERVICE;

    // Fuga Service
    public static FUGA_SERVICE = API.BASE_URI + "/fuga";

    public static FUGA_GET = API.FUGA_SERVICE;
    public static FUGA_UPLOAD = API.FUGA_SERVICE;


}   