<section class="center">

    <div >
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="isLoading">
            <app-loading-spinner></app-loading-spinner>
        </div>
 
        <div class="mat-elevation-z8 mid" fxLayout="row" fxLayoutAlign="center center" *ngIf="!isLoading "> 

            <form fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px" #f="ngForm" (ngSubmit)="onSubmit(f)" *ngIf="!isLoading">
                <h1>Edit Profile</h1>
                <h3 class="margin-top">Please enter your profile details.</h3>

                <mat-form-field class="expand-width margin-top">
                    <input matInput type="text" placeholder="Your first name" ngModel [(ngModel)]="firstNameValue" name="firstName" required #firstNameInput="ngModel">
                    <mat-error *ngIf="firstNameInput.hasError('required')">Field can't be empty!</mat-error>
                </mat-form-field>
                
                <mat-form-field class="expand-width margin-top">
                    <input matInput type="text" placeholder="Your last name" ngModel [(ngModel)]="lastNameValue" name="lastName" required #lastNameInput="ngModel">
                    <mat-error *ngIf="lastNameInput.hasError('required')">Field can't be empty!</mat-error>
                </mat-form-field>

                
                <mat-form-field class="expand-width margin-top">
                    <input matInput type="email" placeholder="Your email" ngModel [(ngModel)]="emailValue" name="email" email required #emailInput="ngModel">

                    <mat-error *ngIf="!emailInput.hasError('required')">Invalid email!</mat-error>
                    <mat-error *ngIf="emailInput.hasError('required')">Field can't be empty!</mat-error>
                </mat-form-field>
    
                <div class="margin-top" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center">

                    <button mat-raised-button class="expand-half-width" (click)="onCancel()" type="button" 
                        style="background-color: black; color: white;">Cancel</button>
                    <button mat-raised-button class="expand-half-width" type="submit" color="primary" [disabled]="f.invalid">Submit</button>
                </div>

            </form>
        </div>


    </div>


</section>