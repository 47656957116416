<mat-toolbar color="primary">
    <div>
        <button mat-icon-button style="font-size: 30px" (click)="onToggleSidenav()" *ngIf="isAuthenticated">
            <mat-icon [inline]="true">menu</mat-icon>
        </button>
    </div>
    <div>
  
        <button mat-flat-button color="primary" (click)="onBrandClick()">
            <h1> Thumb Media</h1>
        </button>

    </div>



    <div fxFlex fxLayout="row" fxLayoutAlign="flex-end" *ngIf="isAuthenticated">
        

        <button mat-icon-button [matMenuTriggerFor]="menu" style="font-size: 36px" *ngIf=" partner!=null">
            <mat-icon [inline]="true">group</mat-icon>
        </button>

        <button mat-flat-button color="primary" [matMenuTriggerFor]="menu" *ngIf=" partner!=null">
            <h1> {{ partner }} </h1>
        </button>

        <mat-divider vertical="true" style="background-color: white; margin: 0 16px;" *ngIf="partner!=null"></mat-divider>
        

        <button mat-icon-button [matMenuTriggerFor]="menu" style="font-size: 30px">
            <mat-icon [inline]="true">account_circle</mat-icon>
        </button>

        <button mat-flat-button color="primary" [matMenuTriggerFor]="menu" >
            <h1> {{ firstName }} {{ lastName }}</h1>
        </button>

        <button mat-icon-button [matMenuTriggerFor]="menu">

            <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu="matMenu">

            <button mat-menu-item routerLink="/profile">
                <mat-icon>account_box</mat-icon>
                <span>Edit Profile</span>
            </button>

            <button mat-menu-item routerLink="/change">
                <mat-icon>security</mat-icon>
                <span>Change Password</span>
            </button>

            <button mat-menu-item (click)="onLogout()">
                <mat-icon>power_settings_new</mat-icon>
                <span>Logout</span>
            </button>

        </mat-menu>
    </div>
</mat-toolbar>