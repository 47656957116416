import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { API } from 'src/app/api.component';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  isAuthenticated = false;
  isAdmin = false;
  isModerator = false;
  isPartnerAccount = false;
  partner: string;


  @ViewChild('sidenav') sidenav: MatSidenav;
  isExpanded = true;
  isShowing = false;
  

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.authService.componentUserObservable.subscribe(user => {
      this.isAuthenticated = !!user; // user ? true : false;
      if(API.DEBUG_MODE) 
      console.log("sidenav is authenticated? " + this.isAuthenticated);

      if(this.isAuthenticated) {
        this.isAdmin = user.authorityId == 3;
        this.isModerator = user.authorityId == 2;
        this.isPartnerAccount = user.partner != null;
        this.partner = user.partner;

        if(API.DEBUG_MODE) 
        console.log("isAdmin: " + this.isAdmin + ", isModerator: " + this.isModerator + ", isPartnerAccount: " + this.isPartnerAccount + ", partner: " + this.partner);
      }
    });
  }

  onMouseEnter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  onMouseLeave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

}
