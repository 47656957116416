import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminSimplePartnerElement, AdminSimpleArtistElement, AdminSubPartnerElement } from '../admin-sub-partners.component';
import { AdminSubPartnerService } from '../admin-sub-partner.service';
import { SnackBarComponent } from 'src/app/components/snack-bar/snack-bar/snack-bar.component';
import { Subscription } from 'rxjs/internal/Subscription';

export interface CreateAdminSubPartnerDialogData {
  name: string;
  isActive: boolean;
  parentPartner: string;
  artist: string;
  partners: AdminSimplePartnerElement[];
  artists: AdminSimpleArtistElement[];
}


@Component({
  selector: 'app-create-admin-sub-partner-dialog',
  templateUrl: './create-admin-sub-partner-dialog.component.html'
})
export class CreateAdminSubPartnerDialog implements OnDestroy {

  private _subscriptions: Subscription[] = [];

  constructor(private _adminSubPartnerService: AdminSubPartnerService, public snackBarComponent: SnackBarComponent, private _dialogRef: MatDialogRef<CreateAdminSubPartnerDialog>,
    @Inject(MAT_DIALOG_DATA) public data: CreateAdminSubPartnerDialogData) {

  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onCancel(): void {
    this._dialogRef.close();
  }

  onCreate(): void {

    if (this.data.name === null || this.data.name.trim() === '')
      this.snackBarComponent.errorMessage("Name must be specified.");

    else if (this.data.isActive === null)
      this.snackBarComponent.errorMessage("Is active must be specified.");

    else if (this.data.parentPartner === null || this.data.parentPartner.trim() === '')
      this.snackBarComponent.errorMessage("Parent partner must be specified.");

    else if (this.data.artist === null || this.data.artist.trim() === '')
      this.snackBarComponent.errorMessage("Artist must be specified.");

    else {

      let parentPartnerIdentifier = null;

      if (this.data.parentPartner === null || this.data.parentPartner === "") {
        parentPartnerIdentifier = null
      } else {
        const parentPartner = this.data.partners.find(element => element.name === this.data.parentPartner);
        parentPartnerIdentifier = parentPartner.identifier;
      }

      let artistIdentifier = null;

      if (this.data.artist === null || this.data.artist === "") {
        artistIdentifier = null
      } else {
        const artist = this.data.artists.find(element => element.fugaName === this.data.artist);
        artistIdentifier = artist.identifier;
      }

      let subscription: Subscription = this._adminSubPartnerService.createSubPartner(this.data.name, this.data.isActive, parentPartnerIdentifier, artistIdentifier)
        .subscribe(resData => {

          if (resData.out_succeed === true && resData.out_data != null) {
            var partnerElement: AdminSubPartnerElement = <AdminSubPartnerElement>resData.out_data;
            this._dialogRef.close({ newSubPartner: partnerElement });
          }
        });

      this._subscriptions.push(subscription);
    }
  }
}
