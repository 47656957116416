<!--

<mat-sidenav-container>
    <mat-sidenav #sidenav role="navigation">
        <mat-nav-list>
            <a mat-list-item routerLink="/dashboard">
                <mat-icon>face</mat-icon>
                <span class="nav-caption">Dashboard</span>    
            </a>

            <a mat-list-item routerLink="/networds">
                <mat-icon>face</mat-icon>
                <span class="nav-caption">Networks</span>    
            </a>

            <a mat-list-item routerLink="/products">
                <mat-icon>face</mat-icon>
                <span class="nav-caption">Products</span>    
            </a>
            <a mat-list-item routerLink="/tracks">
                <mat-icon>face</mat-icon>
                <span class="nav-caption">Tracks</span>    
            </a>
            <a mat-list-item routerLink="/labels">
                <mat-icon>face</mat-icon>
                <span class="nav-caption">Labels</span>    
            </a>
            <a mat-list-item routerLink="/artists">
                <mat-icon>face</mat-icon>
                <span class="nav-caption">Artists</span>    
            </a>


            <a mat-list-item routerLink="/payments">
                <mat-icon>face</mat-icon>
                <span class="nav-caption">Payments</span>    
            </a>


            <a mat-list-item routerLink="/users">
                <mat-icon>face</mat-icon>
                <span class="nav-caption">Users</span>    
            </a>

            <a mat-list-item routerLink="/settings">
                <mat-icon>face</mat-icon>
                <span class="nav-caption">Settings</span>    
            </a>
 

        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <mat-toolbar color="primary">
            <div>
                <button mat-icon-button (click)="sidenav.toggle()">
                    <mat-icon>menu</mat-icon>
                </button>
            </div>
            <div><a routerLink="/">Logo</a></div>
            <div fxFlex fxLayout fxLayoutAlign="flex-end" fxLayoutGap="10px">
                <a routerLink="/profile">Profile</a>
            </div>
        </mat-toolbar>
        <main>
            <router-outlet></router-outlet>
        </main>
    </mat-sidenav-content>
</mat-sidenav-container> 

-->

<app-toolbar (sidenavToggle)="sidenav.toggle()"></app-toolbar>

<mat-sidenav-container class="sidenav-container" autosize>
    
    <mat-sidenav #sidenav class="sidenav" mode="side" opened="true" >
        <app-sidenav></app-sidenav>
    </mat-sidenav>

    <mat-sidenav-content class="sidenav-content">
        <router-outlet></router-outlet>
    </mat-sidenav-content>

</mat-sidenav-container>

<app-footer></app-footer>
