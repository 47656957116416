import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { API } from 'src/app/api.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  private _subscriptions: Subscription[] = [];

  isLoading = false;
  private _rememberMe: boolean = false;
  
  constructor(private _authService: AuthService, private _router: Router) { }

  ngOnInit(): void {

    if(!this._authService.isAvailable()) {
      this._router.navigate(['/maintenance']);
      
    } else {

      let subscription: Subscription = this._authService.componentUserObservable.subscribe(user => {
        var isAuthenticated = !!user; // user ? true : false;
        if(API.DEBUG_MODE) 
        console.log("login is authenticated? " + isAuthenticated);

        if(isAuthenticated) {
            this._router.navigate(['/dashboard']);
        }
      });

      this._subscriptions.push(subscription);
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  rememberMe(completed: boolean) {
    this._rememberMe = completed;
    if(API.DEBUG_MODE) 
    console.log("Remember me: " + this._rememberMe);
  }

  onSubmit(form: NgForm) {
    if(!form.valid) {
      return;
    }

    this.isLoading = true;

    this._authService.login(form.value.email, form.value.password, this._rememberMe)
    .subscribe(resData => this.isLoading = false, errorRes => this.isLoading = false);

    form.reset();
  }

}
