<div fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="30px" fxWrap="wrap">

  <div fxLayout fxLayoutGap="10px" fxLayoutAlign="space-between">
    <div>
      <h1>PARTNER / DASHBOARD</h1>
    </div>
  <!--
    <div>
      <h1>{{ componentSelectedPartner == null ? '' : componentSelectedPartner.name }}</h1>
    </div>

    <div fxLayoutGap="10px">
      <button mat-raised-button style="width: 200px;" color="primary" (click)="showFilters = !showFilters">
        <mat-icon>filter_alt</mat-icon> {{ showFilters ? 'Hide Filters' : 'Show Filters' }}
      </button>
    </div>
    -->
  </div>

  <div fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="allowPartnerChange">
    <mat-form-field fxFlex="40%" *ngIf="displayPartnersSelection">
      <mat-select placeholder="Change Partner" [ngModel]="componentSelectedPartner" (ngModelChange)="onChangePartner($event)" class='form-control'>
        <mat-option *ngFor="let option of partnersWithPayments" [value]="option">{{ option.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="10px" fxWrap="wrap">
    <app-number-card-chart></app-number-card-chart>
  </div>

  <mat-card fxLayout="column" fxLayoutAlign="space-between" fxWrap="wrap">
    <!--
    <mat-card-header>
      <mat-card-title>Overall {{ showRevenue ? 'Revenue' : 'Items' }}</mat-card-title>
    </mat-card-header>
    -->
    <mat-tab-group mat-align-tabs="center" (selectedIndexChange)="overallPanelChange($event)">
      <mat-tab label="Revenue"></mat-tab>
      <mat-tab label="Items"></mat-tab>
    </mat-tab-group>

    <div class="flex-container-full" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px" fxWrap="wrap"
      *ngIf="showRevenue">
      <app-revenue-chart class="item-content-component-full"></app-revenue-chart>
    </div>

    <div class="flex-container-full" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px" fxWrap="wrap"
      *ngIf="!showRevenue">
      <app-item-chart class="item-content-component-full"></app-item-chart>
    </div>
  </mat-card>

  <div fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="paymentDates.length > 0">
    <mat-form-field fxFlex="40%">
      <mat-select placeholder="Select Date" [ngModel]="componentSelectedDate" (ngModelChange)="onChangeDate($event)">
        <mat-option *ngFor="let option of paymentDates" [value]="option">{{ option.date }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="flex-container-half" fxLayout="row" fxLayoutAlign="space-evenly" fxLayoutGap="30px" fxWrap="wrap">


    <mat-card class="flex-container-half" style="width: 50%;" fxLayout="column" fxLayoutAlign="space-between"
      fxWrap="wrap">
      <mat-card-header>
        <!-- mat-card-title>Top 10 Assets</mat-card-title -->
        <mat-card-title>Top 10 Songs</mat-card-title>
      </mat-card-header>
      <mat-tab-group mat-align-tabs="center" (selectedIndexChange)="assetPanelChange($event)">
        <mat-tab label="Chart"></mat-tab>
        <mat-tab label="Table"></mat-tab>
      </mat-tab-group>
      <div class="flex-container-half" style="height: 100%;" fxLayout="row" fxLayoutAlign="flex-start"
        fxLayoutGap="10px" fxWrap="wrap">
        <app-asset-chart class="item-content-component-half" [showAssetChart]="showAssetChart"></app-asset-chart>
      </div>
    </mat-card>

    <mat-card class="flex-container-half" style="width: 50%;" fxLayout="column" fxLayoutAlign="space-between"
      fxWrap="wrap">
      <mat-card-header>
        <mat-card-title>Top 10 Countries</mat-card-title>
      </mat-card-header>
      <mat-tab-group mat-align-tabs="center" (selectedIndexChange)="countryPanelChange($event)">
        <mat-tab label="Chart"></mat-tab>
        <mat-tab label="Table"></mat-tab>
      </mat-tab-group>
      <div class="flex-container-half" style="height: 100%;" fxLayout="row" fxLayoutAlign="flex-start"
        fxLayoutGap="10px" fxWrap="wrap">
        <app-country-chart class="item-content-component-half" [showCountryChart]="showCountryChart">
        </app-country-chart>
      </div>
    </mat-card>



  </div>


  <div class="flex-container-half" fxLayout="row" fxLayoutAlign="space-evenly" fxLayoutGap="30px" fxWrap="wrap">

    <mat-card class="flex-container-half" style="width: 50%;" fxLayout="column" fxLayoutAlign="space-between"
      fxWrap="wrap">
      <mat-card-header>
         <!-- mat-card-title>Top 10 DSPs</mat-card-title -->
        <mat-card-title>Top 10 Platforms</mat-card-title>
      </mat-card-header>
      <mat-tab-group mat-align-tabs="center" (selectedIndexChange)="dspPanelChange($event)">
        <mat-tab label="Chart"></mat-tab>
        <mat-tab label="Table"></mat-tab>
      </mat-tab-group>
      <div class="flex-container-half" style="height: 200%;" fxLayout="row" fxLayoutAlign="space-between"
        fxLayoutGap="10px" fxWrap="wrap">
        <app-dsp-chart class="item-content-component-half" [showDspChart]="showDspChart"></app-dsp-chart>
      </div>
    </mat-card>

    <mat-card class="flex-container-half" style="width: 50%;" fxLayout="column" fxLayoutAlign="space-between"
    fxWrap="wrap">
    <mat-card-header>
      
      <!-- mat-card-title>Top 10 Types of Sale</mat-card-title -->
      <mat-card-title>Top 10 Types of Items</mat-card-title>
    </mat-card-header>
    <mat-tab-group mat-align-tabs="center" (selectedIndexChange)="typeOfSalePanelChange($event)">
      <mat-tab label="Chart"></mat-tab>
      <mat-tab label="Table"></mat-tab>
    </mat-tab-group>
    <div class="flex-container-half" style="height: 200%;" fxLayout="row" fxLayoutAlign="space-between"
      fxLayoutGap="10px" fxWrap="wrap">
      <app-type-of-sale-chart class="item-content-component-half" [showTypeOfSaleChart]="showTypeOfSaleChart">
      </app-type-of-sale-chart>
    </div>
  </mat-card>


  </div>


  <div class="flex-container-half" fxLayout="row" fxLayoutAlign="space-evenly" fxLayoutGap="30px" fxWrap="wrap" *ngIf="showArtistChart || showLabelChart">

    <mat-card class="flex-container-half" style="width: 50%;" fxLayout="column" fxLayoutAlign="space-between"
      fxWrap="wrap"  *ngIf="showArtistChart">
      <mat-card-header>
        <mat-card-title>Top 10 Artists</mat-card-title>
      </mat-card-header>
      <mat-tab-group mat-align-tabs="center" (selectedIndexChange)="artistPanelChange($event)">
        <mat-tab label="Chart"></mat-tab>
        <mat-tab label="Table"></mat-tab>
      </mat-tab-group>
      <div class="flex-container-half" style="height: 100%;" fxLayout="row" fxLayoutAlign="flex-start"
        fxLayoutGap="10px" fxWrap="wrap">
        <app-artist-chart class="item-content-component-half" [showArtistChart]="showArtistChart"></app-artist-chart>
      </div>
    </mat-card>


    <mat-card class="flex-container-half" style="width: 50%;" fxLayout="column" fxLayoutAlign="space-between"
    fxWrap="wrap"  *ngIf="showLabelChart">
    <mat-card-header>
      <mat-card-title>Top 10 Labels</mat-card-title>
    </mat-card-header>
    <mat-tab-group mat-align-tabs="center" (selectedIndexChange)="labelPanelChange($event)">
      <mat-tab label="Chart"></mat-tab>
      <mat-tab label="Table"></mat-tab>
    </mat-tab-group>
    <div class="flex-container-half" style="height: 100%;" fxLayout="row" fxLayoutAlign="flex-start"
      fxLayoutGap="10px" fxWrap="wrap">
      <app-label-chart class="item-content-component-half" [showLabelChart]="showLabelChart"></app-label-chart>
    </div>
  </mat-card>

  </div>
</div>