

<h1>MANAGEMENT / PAYMENTS</h1>

<div>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="isLoading">
        <app-loading-spinner></app-loading-spinner>
    </div>

    <div>
        <div class="flex-container-full" fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="10px" fxWrap="wrap" *ngIf="!isLoading">
        
            <ngx-charts-number-card
              [view]="view"
              [scheme]="colorScheme"
              [results]="single"
              [cardColor]="cardColor"
              >
        
            </ngx-charts-number-card>
          </div>


        <div fxLayout fxLayoutGap="10px" fxLayoutAlign="space-between">
            <div *ngIf="!isLoading && isAdmin">

                <button mat-raised-button color="primary" (click)="onCreate()">
                    <mat-icon>add</mat-icon> Create New
                </button>

            </div>

            <div fxLayoutGap="10px" *ngIf="!isLoading && isAdmin">
                <button mat-raised-button color="primary" (click)="onMetadata()">
                    <mat-icon>view_column</mat-icon> Metadata
                </button>
                <button mat-raised-button color="primary" (click)="onDownload()">
                    <mat-icon>get_app</mat-icon> Export Data
                </button>
            </div>

        </div>

        <div fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="!isLoading">
            <mat-form-field fxFlex="40%">
                <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter">
            </mat-form-field>
        </div>


        <mat-progress-bar mode="query" *ngIf="isDownloading"></mat-progress-bar>
        <mat-table matSort fxLayout="column" fxLayoutAlign="center" fxLayoutGap="10px" [dataSource]="dataSource"
            class="mat-elevation-z8" *ngIf="!isLoading">
            
            <ng-container matColumnDef="identifier">
                <mat-header-cell *matHeaderCellDef mat-sort-header="identifier">Identifier</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.identifier }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="items">
                <mat-header-cell *matHeaderCellDef mat-sort-header="items">Items</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.items == -1 ? "NA" : element.items }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="revenue">
                <mat-header-cell *matHeaderCellDef mat-sort-header="revenue">Gross Revenue</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.revenue }} € </mat-cell>
            </ng-container>

            <ng-container matColumnDef="royalty">
                <mat-header-cell *matHeaderCellDef mat-sort-header="royalty">Royalty</mat-header-cell>
                <mat-cell *matCellDef="let element" [ngClass]="{'mat-editing-cell': (editingElement == null ? false : (element.identifier==editingElement.identifier? element.statusId == 0 : false))}">
                    <mat-label *ngIf="(editingElement == null || (editingElement != null && element.statusId > 0)) ? true : element.identifier!=editingElement.identifier">{{ element.royalty }} %</mat-label>
                    <input type="number" matInput placeholder="Royalty" [(ngModel)]="changedElement.royalty" [value]="editingElement.royalty" *ngIf="(editingElement != null && element.statusId == 0) ? (editingElement == null ? false : element.identifier==editingElement.identifier) : false">
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="net">
                <mat-header-cell *matHeaderCellDef mat-sort-header="net">Net Revenue</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.net }} € </mat-cell>
            </ng-container>

            <ng-container matColumnDef="month">
                <mat-header-cell *matHeaderCellDef mat-sort-header="month">Month</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.month }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="year">
                <mat-header-cell *matHeaderCellDef mat-sort-header="year">Year</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.year }}</mat-cell>
            </ng-container>

    

            <ng-container matColumnDef="partner">
                <mat-header-cell *matHeaderCellDef mat-sort-header="partner">Partner</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.partner }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="partnerIdentifier">
                <mat-header-cell *matHeaderCellDef mat-sort-header="partnerIdentifier">PartnerId</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.partnerIdentifier }}</mat-cell>
            </ng-container>


            <ng-container matColumnDef="notes">
                <mat-header-cell *matHeaderCellDef mat-sort-header="notes">Notes</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.notes }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef mat-sort-header="type">Type</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.type }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="typeId">
                <mat-header-cell *matHeaderCellDef mat-sort-header="typeId">TypeId</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.typeId }}</mat-cell>
                <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef mat-sort-header="status">Status</mat-header-cell>
                <mat-cell *matCellDef="let element" [ngClass]="{'mat-editing-cell': (editingElement == null ? false : element.identifier==editingElement.identifier && biggerThan(element.statusId, 0))}"> 
                   
                    <mat-label *ngIf="editingElement == null ? true : element.identifier!=editingElement.identifier || lowerThan(element.statusId, 1)">{{ element.status }}</mat-label>


                    <mat-select [(value)]="changedElement.status" placeholder="Select status..." *ngIf="editingElement == null ? false : element.identifier==editingElement.identifier && biggerThan(element.statusId, 0)">
                        <mat-option *ngFor="let option of paymentStatusData" [disabled]="(option.id < element.statusId)" [value]="option.name">{{ option.name }}</mat-option>
                      </mat-select>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="statusId">
                <mat-header-cell *matHeaderCellDef mat-sort-header="statusId">StatusId</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.statusId }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="reportId">
                <mat-header-cell *matHeaderCellDef mat-sort-header="reportId">ReportId</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.reportId }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="publishedOn">
                <mat-header-cell *matHeaderCellDef mat-sort-header="publishedOn">Published On</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.publishedOn }}</mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="createdBy">
                <mat-header-cell *matHeaderCellDef mat-sort-header="createdBy">Created By</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.createdBy }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="createdOn">
                <mat-header-cell *matHeaderCellDef mat-sort-header="createdOn">Created On</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.createdOn }}</mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="modifiedBy">
                <mat-header-cell *matHeaderCellDef mat-sort-header="modifiedBy">Modified By</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.modifiedBy }}</mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="modifiedOn">
                <mat-header-cell *matHeaderCellDef mat-sort-header="modifiedOn">Modified On</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.modifiedOn }}</mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
                <mat-cell *matCellDef="let element">

                    <div>

                    <a (click)="onDownloadData(element)" *ngIf="element.typeId == 0 ? (editingElement == null ? true : element.identifier!=editingElement.identifier) : false">
                        <mat-icon>get_app</mat-icon>
                    </a>

                    <a (click)="onEditCancel()" *ngIf="(editingElement == null ? false : element.identifier==editingElement.identifier)">
                        <mat-icon>clear</mat-icon>
                    </a>
                    <a (click)="onEditSave(element)" *ngIf="(editingElement == null ? false : element.identifier==editingElement.identifier)" >
                        <mat-icon>check</mat-icon>
                    </a>

                    <a (click)="onEdit(element)" *ngIf="element.statusId != -1? (editingElement == null ? true : element.identifier!=editingElement.identifier) : false">
                        <mat-icon>edit</mat-icon>
                    </a>
                    <a (click)="onDelete(element)" *ngIf="isAdmin && ((element.statusId == 0) ? (element.typeId == 1? (editingElement == null ? true : element.identifier!=editingElement.identifier) : false) : false)">
                        <mat-icon>delete</mat-icon>
                    </a>
                   

                </div>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row [ngClass]="{'mat-green-border-row': (row.statusId >= 1), 'mat-yellow-border-row': (row.statusId == 0) , 'mat-red-border-row': (row.statusId == -1), 'table-row-make-red': (editingElement == null ? false : row.identifier===editingElement.identifier)}" *matRowDef="let row; columns: displayedColumns;"></mat-row>
       
        </mat-table>

        <mat-paginator class="mat-elevation-z8" [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 75, 100, 250]">

        </mat-paginator>
    </div>
</div>