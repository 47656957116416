import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminPartnerService } from '../admin-partner.service';
import { SnackBarComponent } from 'src/app/components/snack-bar/snack-bar/snack-bar.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { AdminPartnerElement } from '../admin-partners.component';

export interface CreateAdminPartnerDialogData {
  name: string;
  isActive: boolean;
  allowSubPartners: boolean;
}

@Component({
  selector: 'app-create-admin-partner-dialog',
  templateUrl: './create-admin-partner-dialog.component.html'
})
export class CreateAdminPartnerDialog implements OnDestroy {

  private _subscriptions: Subscription[] = [];

  constructor(private _adminPartnerService: AdminPartnerService,
    private _snackBarComponent: SnackBarComponent,
    public _dialogRef: MatDialogRef<CreateAdminPartnerDialog>,
    @Inject(MAT_DIALOG_DATA) public data: CreateAdminPartnerDialogData) { }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onCancel(): void {
    this._dialogRef.close();
  }

  onCreate(): void {
    if (this.data.name === null || this.data.name.trim() === '')
      this._snackBarComponent.errorMessage("Name must be specified.");

    else {

      let subscription: Subscription = this._adminPartnerService.createPartner(this.data.name, this.data.isActive, this.data.allowSubPartners)
        .subscribe(resData => {

          if (resData.out_succeed === true && resData.out_data != null) {
            var partnerElement: AdminPartnerElement = <AdminPartnerElement>resData.out_data;
            this._dialogRef.close({ newPartner: partnerElement });
          }
        });

      this._subscriptions.push(subscription);
    }
  }
}
