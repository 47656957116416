<h1 *ngIf="reportIdentifier==null">MANAGEMENT / REPORTS / NEW IMPORT</h1>
<h1 *ngIf="reportIdentifier!=null">MANAGEMENT / REPORTS / IMPORT {{ reportIdentifier }}</h1>
<p></p>
<div>

    <div>
        <mat-progress-bar mode="indeterminate" *ngIf="sending"></mat-progress-bar>
        <mat-horizontal-stepper [linear]="isLinear" #stepper style="min-height: 100vh;">
            <mat-step [stepControl]="firstFormGroup" [completed]="isCompleted">

                <form [formGroup]="firstFormGroup" fxLayout="column" fxLayoutGap="75px" fxLayoutAlign="flex-end">
                    <ng-template matStepLabel>Import Report</ng-template>

                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-evenly">

                        <mat-form-field class="example-full-width">
                            <mat-label>Month and Year</mat-label>
                            <input matInput [matDatepickerFilter]="myFilter" [matDatepicker]="dp" [formControl]="date"
                                disabled>
                            <mat-datepicker-toggle matSuffix [disabled]="sending" [for]="dp"></mat-datepicker-toggle>
                            <mat-datepicker #dp [disabled]="sending" startView="multi-year"
                                (yearSelected)="chosenYearHandler($event)"
                                (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker"
                                [disabled]="lowerThan(1, reportStatusId)">
                            </mat-datepicker>

                        </mat-form-field>

                        <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center">

                            <input type="file" [disabled]="sending" id="file"
                                (change)="handleFileInput($event.target.files)" [disabled]="lowerThan(1, reportStatusId)">

                        </div>
                    </div>

                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center">
                        <button mat-raised-button [disabled]="sending" (click)="cancel(stepper)" type="button"
                            style="width: 200px; background-color: black; color: white;">Cancel</button>
                        <button mat-raised-button [disabled]="sending" (click)="uploadFile(stepper)" type="button"
                            color="primary" style="width: 200px;" [disabled]="lowerThan(1, reportStatusId)">Upload Report</button>
                    </div>
                </form>

            </mat-step>

            <mat-step [stepControl]="secondFormGroup" [completed]="isCompleted">
                <form [formGroup]="secondFormGroup" fxLayout="column" fxLayoutGap="75px" fxLayoutAlign="flex-end">
                    <ng-template matStepLabel>Select FUGA Snapshot</ng-template>

                    <div fxLayout="column" fxLayoutGap="25px" fxLayoutAlign="flex-end">

                        <h3>FUGA Snapshot Details</h3>

                        <mat-table matSort [dataSource]="fugaDataSource" class="mat-elevation-z8">

                            <ng-container matColumnDef="status">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="status">Status</mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.status }}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="labels">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="labels">Labels</mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.labels }}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="artists">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="artists">Artists</mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.artists }}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="products">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="products">Products</mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.products }}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="productAssets">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="productAssets">Product Assets
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.productAssets }}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="start">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="start">Start</mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.start }}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="finish">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="finish">Finish</mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.finish }}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="createdBy">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="createdBy">Created By
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.createdBy }}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="createdOn">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="createdOn">Created On
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.createdOn }}</mat-cell>
                                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                            </ng-container>

                            <ng-container matColumnDef="modifiedBy">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="modifiedBy">Modified By
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.modifiedBy }}</mat-cell>
                                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                            </ng-container>

                            <ng-container matColumnDef="modifiedOn">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="modifiedOn">Modified On
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.modifiedOn }}</mat-cell>
                                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                            </ng-container>



                            <mat-header-row *matHeaderRowDef="fugaDisplayedColumns; sticky: true"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: fugaDisplayedColumns;"></mat-row>
                        </mat-table>


                    </div>

                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center">

                        <button mat-raised-button [disabled]="sending" (click)="cancel(stepper)" type="button"
                            style="width: 200px; background-color: black; color: white;">Cancel</button>

                        <button mat-raised-button matStepperNext [disabled]="sending" type="button" color="primary"
                            style="width: 200px;" [disabled]="lowerThan(4, reportStatusId)">Use Current Snapshot</button>
                        <button mat-raised-button [disabled]="(sending || lowerThan(4, reportStatusId))" (click)="uploadFuga(stepper)" type="button"
                            color="primary" style="width: 200px;">Create New Snapshot</button>

                    </div>
                </form>
            </mat-step>

            <mat-step [stepControl]="thirdFormGroup" [completed]="isCompleted">
                <form [formGroup]="thirdFormGroup" fxLayout="column" fxLayoutGap="75px" fxLayoutAlign="flex-end">
                    <ng-template matStepLabel>Match Data</ng-template>

                    <div fxLayout="column" fxLayoutGap="25px" fxLayoutAlign="flex-end">


                        <h3>Matching Global Details</h3>

                        <mat-table matSort [dataSource]="matchGlobalDataSource" class="mat-elevation-z8">

                            <ng-container matColumnDef="label">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="label">Label</mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.label }}</mat-cell>

                            </ng-container>

                            <ng-container matColumnDef="totalItems">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="totalItems">Total Items
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.totalItems }}</mat-cell>

                            </ng-container>

                            <ng-container matColumnDef="totalRevenue">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="totalRevenue">Total Revenue
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.totalRevenue }}</mat-cell>

                            </ng-container>

                            <mat-header-row *matHeaderRowDef="matchGlobalDisplayedColumns; sticky: true"
                                color="primary">
                            </mat-header-row>
                            <mat-row *matRowDef="let row; columns: matchGlobalDisplayedColumns;"></mat-row>
                        </mat-table>
                    </div>

                    <div fxLayout="column" fxLayoutGap="25px" fxLayoutAlign="flex-end">
                        <h3>Matching Details</h3>

                        <div fxLayout fxLayoutGap="10px" fxLayoutAlign="space-between">
                            <div *ngIf="isAdmin">
                            </div>
                
                            <div fxLayoutGap="10px" *ngIf="isAdmin">
                           
                                <button mat-raised-button color="primary" (click)="onDownload()">
                                    <mat-icon>get_app</mat-icon> Export Data
                                </button>
                            </div>
                
                        </div>

                        <mat-table matSort [dataSource]="matchDataSource" class="mat-elevation-z8">

                            <ng-container matColumnDef="label">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="label">Report Label Name
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.label }}</mat-cell>
                                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                            </ng-container>

                            <ng-container matColumnDef="upc_code">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="upc_code">Report UPC Code
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.upc_code }}</mat-cell>
                                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                            </ng-container>

                            <ng-container matColumnDef="isrc_code">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="isrc_code">Report ISRC Code
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.isrc_code }}</mat-cell>
                                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                            </ng-container>

                            <ng-container matColumnDef="total_items">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="total_items">Report Total Items
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.total_items }}</mat-cell>
                                <mat-footer-cell *matFooterCellDef> {{ getTotalItems() }} </mat-footer-cell>
                            </ng-container>

                            <ng-container matColumnDef="total_revenue">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="total_revenue">Report Total Revenue
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.total_revenue }} €</mat-cell>
                                <mat-footer-cell *matFooterCellDef> {{ getTotalRevenue() }} €</mat-footer-cell>
                            </ng-container>

                            <ng-container matColumnDef="fuga_product_id">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="fuga_product_id">FUGA Product ID
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.fuga_product_id }}</mat-cell>
                                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                            </ng-container>

                            <ng-container matColumnDef="fuga_product_upc">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="fuga_product_upc">FUGA Product UPC
                                    Code
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.fuga_product_upc }}</mat-cell>
                                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                            </ng-container>

                            <ng-container matColumnDef="error_message">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="error_message">Error Message
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.error_message }}</mat-cell>
                                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                            </ng-container>

                            <ng-container matColumnDef="was_mapped">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="was_mapped">Was Mapped
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.was_mapped }}</mat-cell>
                                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="matchDisplayedColumns; sticky: true" color="primary">
                            </mat-header-row>
                            <mat-row *matRowDef="let row; columns: matchDisplayedColumns;"></mat-row>
                            <mat-footer-row class="table-row-make-black"
                                *matFooterRowDef="matchDisplayedColumns; sticky: true"></mat-footer-row>
                        </mat-table>

                    </div>

                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center">
                        <button mat-raised-button [disabled]="sending" (click)="cancel(stepper)" type="button"
                            style="width: 200px; background-color: black; color: white;">Cancel</button>
                            
                        <button mat-raised-button [disabled]="(sending || lowerThan(8, reportStatusId))" (click)="match(stepper)" type="button"
                            color="primary" style="width: 200px;">Match Report with FUGA</button>

                        <button mat-raised-button [disabled]="(sending || lowerThan(8, reportStatusId))" (click)="continue(stepper)" type="button"
                            color="primary" style="width: 200px;">Continue</button>
                    </div>
                </form>
            </mat-step>


            <mat-step [stepControl]="fourthFormGroup" [completed]="isCompleted">
                <form [formGroup]="fourthFormGroup" fxLayout="column" fxLayoutGap="75px" fxLayoutAlign="flex-end">
                    <ng-template matStepLabel>Payments</ng-template>

                    <div fxLayout="column" fxLayoutGap="25px" fxLayoutAlign="flex-end">

                        <h3>Matching Global Details</h3>

                        <mat-table matSort [dataSource]="matchGlobalDataSource" class="mat-elevation-z8">

                            <ng-container matColumnDef="label">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="label">Label</mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.label }}</mat-cell>

                            </ng-container>

                            <ng-container matColumnDef="totalItems">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="totalItems">Total Items
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.totalItems }}</mat-cell>

                            </ng-container>

                            <ng-container matColumnDef="totalRevenue">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="totalRevenue">Total Revenue
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.totalRevenue }}</mat-cell>

                            </ng-container>

                            <mat-header-row *matHeaderRowDef="matchGlobalDisplayedColumns; sticky: true"
                                color="primary">
                            </mat-header-row>
                            <mat-row *matRowDef="let row; columns: matchGlobalDisplayedColumns;"></mat-row>
                        </mat-table>

                    </div>




                    <div fxLayout="column" fxLayoutGap="25px" fxLayoutAlign="flex-end">

                        <h3> Payments</h3>



                        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center">

                            <button mat-raised-button [disabled]="sending || !lowerThan(reportStatusId, 8)" (click)="generatePayments(stepper)" type="button"
                                color="primary" style="width: 200px;">Generate</button>
    
                            <button mat-raised-button [disabled]="sending || !lowerThan(reportStatusId, 8)" (click)="validatePayments(stepper)" type="button"
                                color="primary" style="width: 200px;">Validate</button>
    
                            <button mat-raised-button [disabled]="sending || !lowerThan(reportStatusId, 9)" (click)="publishPayments(stepper)" type="button"
                                color="primary" style="width: 200px;">Publish</button>
    
                            <button mat-raised-button [disabled]="sending || !lowerThan(reportStatusId, 10)" (click)="waitForReciptsPayments(stepper)" type="button"
                                color="primary" style="width: 200px;">Wait for recipts</button>
    
                        </div>


                        <div fxLayout fxLayoutGap="10px" fxLayoutAlign="space-between">
                            <div *ngIf="isAdmin">
                            </div>
                
                            <div fxLayoutGap="10px" *ngIf="isAdmin">
                           
                                <button mat-raised-button color="primary" (click)="onDownloadGeneratedPayments()">
                                    <mat-icon>get_app</mat-icon> Export Data
                                </button>
                            </div>
                
                        </div>

                        <mat-table matSort [dataSource]="paymentsDataSource" class="mat-elevation-z8">


                            <ng-container matColumnDef="year">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="year">Year</mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.year }}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="month">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="month">Month</mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.month }}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="partner">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="partner">Partner</mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.partner }}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="items">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="items">Items</mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.items }} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="gross_revenue">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="gross_revenue">Gross Revenue</mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.gross_revenue }} € </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="royalty">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="royalty">Royalty</mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.royalty }} %</mat-cell>
                            </ng-container>
                
                            <ng-container matColumnDef="net_revenue">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="net_revenue">Net Revenue</mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.net_revenue }} € </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="type">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="type">Type</mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.type }}</mat-cell>
                            </ng-container>


                            <ng-container matColumnDef="status">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="status">Status</mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.status }}</mat-cell>
                            </ng-container>


                            <ng-container matColumnDef="publishedOn">
                                <mat-header-cell *matHeaderCellDef mat-sort-header="publishedOn">Published On </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{ element.publishedOn }}</mat-cell>
                            </ng-container>

                          

                            <mat-header-row *matHeaderRowDef="paymentsDisplayedColumns; sticky: true"> </mat-header-row>
                            <mat-row *matRowDef="let row; columns: paymentsDisplayedColumns;"></mat-row>
                        </mat-table>

                    </div>

                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center">

                        <button mat-raised-button [disabled]="sending" (click)="finish(stepper)" type="button"
                            color="primary" style="width: 200px;">Finish</button>
                    </div>
                </form>
            </mat-step>
        </mat-horizontal-stepper>

    </div>

</div>