import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { ExportToCsv } from 'export-to-csv';
import { Subscription } from 'rxjs';
import { SnackBarComponent } from 'src/app/components/snack-bar/snack-bar/snack-bar.component';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialog } from 'src/app/components/dialogs/delete-dialog/delete-dialog.component';
import { AdminAccountService } from './admin-accounts.service';
import { AdminPartnerService } from '../admin-partners/admin-partner.service';
import { CreateAdminAccountDialog } from './create-admin-account-dialog/create-admin-account-dialog.component';
import { AuthService } from 'src/app/auth/auth.service';
import { API } from 'src/app/api.component';

export interface AdminAccountElement {
  identifier: string;
  firstName: string;
  lastName: string;
  email: string;
  isVerified: boolean;
  isBlocked: boolean;
  authority: string;
  partner: string;
  lastLoginOn: string;
  createdBy: string;
  createdOn: string;
  modifiedBy: string;
  modifiedOn: string;
}

export interface AdminSimplePartnerElement {
  identifier: string;
  name: string;
}

export interface AdminSimpleAccountAuthorityElement {
  id: string;
  name: string;
}


@Component({
  selector: 'app-admin-accounts',
  templateUrl: './admin-accounts.component.html',
  styleUrls: ['./admin-accounts.component.scss']
})
export class AdminAccountsComponent implements OnInit, AfterViewInit, OnDestroy {

  private _subscriptions: Subscription[] = [];

  isLoading = false;

  showMetadata = false;
  private _adminShowMetadataColumns = ['identifier', 'name', 'email', 'isBlocked', 'authority', 'partner', 'lastLoginOn', 'createdBy', 'createdOn', 'modifiedBy', 'modifiedOn', 'action'];
  private _adminHideMetadataColumns = ['name', 'email', 'isBlocked', 'authority', 'partner', 'lastLoginOn', 'action'];
  private _moderatorHideMetadataColumns = ['name', 'email', 'isBlocked', 'authority', 'partner', 'lastLoginOn'];
  displayedColumns: string[];

  dataSource = new MatTableDataSource<AdminAccountElement>();
  private _data: AdminAccountElement[] = [];
  adminSimpleAccountAuthoritiesData: AdminSimpleAccountAuthorityElement[] = [];
  adminSimplePartnersData: AdminSimplePartnerElement[] = [];

  editingElement: AdminAccountElement = null;
  changedElement: AdminAccountElement = null;

  private _isAdmin: boolean = false;

  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private _authService: AuthService, private _adminAccountService: AdminAccountService, private _adminPartnerService: AdminPartnerService, private _snackBarComponent: SnackBarComponent, public _dialog: MatDialog) {
    this._isAdmin = this._authService.isAdmin();
    this.displayedColumns = this._isAdmin ? this._adminHideMetadataColumns : this._moderatorHideMetadataColumns;
  }

  get isAdmin(): boolean {
    return this._isAdmin;
  }

  ngOnInit(): void {
    this.dataSource.data = this._data;

    if (this._isAdmin) {
      this._subscriptions.push(this.loadAllAccountAuthorities());
      this._subscriptions.push(this.loadAllSimplePartners());
    }

    this._subscriptions.push(this.loadAllAccounts());
  }

  private loadAllAccounts(): Subscription {
    return this._adminAccountService.getAllAccounts()
    .subscribe(resData => {
      if (resData.out_succeed === true && resData.out_data != null) {

        this._data = <AdminAccountElement[]>resData.out_data;
        this.dataSource.data = this._data;
      }
    });
  }

  private loadAllAccountAuthorities(): Subscription {
    return this._adminAccountService.getAllAccountAuthorities()
    .subscribe(resData => {

      if (resData.out_succeed === true && resData.out_data != null) {
        this.adminSimpleAccountAuthoritiesData = <AdminSimpleAccountAuthorityElement[]>resData.out_data;
      }
    });
  }

  private loadAllSimplePartners(): Subscription {
    return this._adminPartnerService.getAllPartnersSimple()
    .subscribe(resData => {

      if (resData.out_succeed === true && resData.out_data != null) {
        this.adminSimplePartnersData = <AdminSimplePartnerElement[]>resData.out_data;
      }
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());

    this._dialog.closeAll();
  }

  doFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onCreate(): void {
    if (!this.isAdmin)
      return;

    const dialogRef = this._dialog.open(CreateAdminAccountDialog, {
      width: '400px',
      height: '500px',
      disableClose: true,
      data: { firstName: '', lastName: '', email: '', authority: '', partner: '', authorities: this.adminSimpleAccountAuthoritiesData, partners: this.adminSimplePartnersData }
    });

    let subscription: Subscription = dialogRef.afterClosed()
    .subscribe(result => {

      if (result != null) {

        this._data.splice(0, 0, result.newAccount);

        this.dataSource.data = [...this._data];
      }
    });

    this._subscriptions.push(subscription);
  }

  onEdit(selectedElement: AdminAccountElement): void {

    if (!this.isAdmin)
      return;

    this.editingElement = Object.assign({}, selectedElement);
    this.changedElement = Object.assign({}, selectedElement);
  }


  onEditCancel(): void {

    if (!this.isAdmin)
      return;

    this.editingElement = null;
    this.changedElement = null;
  }

  onEditSave(selectedElement: AdminAccountElement): void {

    if (!this.isAdmin)
      return;

    if(this.changedElement.authority === null || this.changedElement.authority.trim() === '') 
      this._snackBarComponent.errorMessage("Authority must be specified.");

    else if(this.changedElement.authority != 'Admin' && (this.changedElement.partner === null || this.changedElement.partner.trim() === '')) 
      this._snackBarComponent.errorMessage("Partner must be specified.");

    else {

      let authorityId = null;

      if (this.changedElement.authority === null || this.changedElement.authority === "") {
        authorityId = null
      } else {
        const authority = this.adminSimpleAccountAuthoritiesData.find(element => element.name === this.changedElement.authority);
        authorityId = authority.id;
      }

      let partnerIdentifier = null;

      if (this.changedElement.partner === null || this.changedElement.partner === "") {
        partnerIdentifier = null
      } else {
        const partner = this.adminSimplePartnersData.find(element => element.name === this.changedElement.partner);
        partnerIdentifier = partner.identifier;
      }

      let subscription: Subscription = this._adminAccountService.editAccount(selectedElement.identifier, this.changedElement.isBlocked, authorityId, partnerIdentifier)
      .subscribe(resData => {
          if (resData.out_succeed === true && resData.out_data != null) {
            var partnerElement: AdminAccountElement = <AdminAccountElement>resData.out_data;

            let index: number = this._data.findIndex(element => element === selectedElement);
            if(API.DEBUG_MODE) 
            console.log(this._data.findIndex(element => element.identifier === selectedElement.identifier));

            this._data.splice(index, 1, partnerElement);
            this.dataSource.data = [...this._data];

            this._snackBarComponent.successMessage("Account " + selectedElement.firstName + " " + selectedElement.lastName + " was successfully edited!");
          }
        });

      this._subscriptions.push(subscription);

      this.editingElement = null;
      this.changedElement = null;
    }
  }

  onDelete(selectedElement: AdminAccountElement): void {

    if (!this.isAdmin)
      return;

    const dialogRef = this._dialog.open(DeleteDialog, {
      width: '400px',
      data: { name: selectedElement.firstName + " " + selectedElement.lastName, delete: false }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(API.DEBUG_MODE) 
      console.log('The dialog was closed ' + JSON.stringify(result));

      if (result) {
        let subscription: Subscription = this._adminAccountService.removeAccount(selectedElement.identifier)
        .subscribe(resData => {
            if (resData.out_succeed === true) {
              let index: number = this._data.findIndex(element => element === selectedElement);

              this._data.splice(index, 1)
              this.dataSource.data = [...this._data];
            }
          });

        this._subscriptions.push(subscription);
      }
    });
  }

  onMetadata(): void {

    if (!this.isAdmin)
      return;

    this.showMetadata = !this.showMetadata;

    if (this.showMetadata) {
      this.displayedColumns = this._adminShowMetadataColumns;

    } else {
      this.displayedColumns = this._adminHideMetadataColumns;
    }
  }

  onDownload(): void {

    if (!this.isAdmin)
      return;

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Admin Accounts CSV',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      headers: this.displayedColumns
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(this.dataSource.data);
  }
}