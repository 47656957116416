import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { API } from '../../api.component';
import { AuthService } from '../../auth/auth.service';
import { ResponseData } from '../../response-data.model';
import { SnackBarComponent } from 'src/app/components/snack-bar/snack-bar/snack-bar.component';

import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class AdminSubPartnerService {

  private _accessToken: string = null;

  constructor(private _authService: AuthService, private _httpClient: HttpClient, private _snackBarComponent: SnackBarComponent) {
    this.init();
  }

  init(): void {
    this._authService.serviceAccessTokenObservable.subscribe(accessToken => {
      this._accessToken = accessToken;
    });
  }

  private privateHeader() {
    return new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this._accessToken
      });
  }

  getAllSubPartners() {
    return this._httpClient.get<ResponseData>(API.SUB_PARTNER_GET_ALL, { headers: this.privateHeader() })
    .pipe(
      catchError(this.handleUnexpectedError),
      tap(resData => this.defaultResponseHandling(resData, "Subpartners were successfully loaded!", resData.out_succeed === true && resData.out_data != null))
    );
  }

  getAllSubPartnersSimple() {
    return this._httpClient.get<ResponseData>(API.SUB_PARTNER_GET_ALL_SIMPLE, { headers: this.privateHeader() })
    .pipe(
      catchError(this.handleUnexpectedError),
      tap(resData => this.defaultResponseHandling(resData, null, resData.out_succeed === true && resData.out_data != null))
    );
  }

  createSubPartner(name: string, isActive: boolean, parentPartnerIdentifier: string, artistIdentifier: string) {
    const httpBody = { name: name, isActive: isActive, parentPartnerIdentifier: parentPartnerIdentifier, artistIdentifier: artistIdentifier };
    return this._httpClient.post<ResponseData>(API.SUB_PARTNER_CREATE, httpBody, { headers: this.privateHeader() })
    .pipe(
      catchError(this.handleUnexpectedError),
      tap(resData => this.defaultResponseHandling(resData, "Subpartner has been successfully created.", resData.out_succeed === true && resData.out_data != null))
    );
  }

  editSubPartner(identifier: string, name: string, isActive: boolean) {
    const httpBody = { identifier: identifier, name: name, isActive: isActive };
    return this._httpClient.put<ResponseData>(API.SUB_PARTNER_EDIT, httpBody, { headers: this.privateHeader() })
    .pipe(
      catchError(this.handleUnexpectedError),
      tap(resData => this.defaultResponseHandling(resData, "Subpartner has been successfully edited.", resData.out_succeed === true && resData.out_data != null))
    );
  }

  removeSubPartner(identifier: string) {
    return this._httpClient.delete<ResponseData>(API.SUB_PARTNER_DELETE + "/" + identifier, { headers: this.privateHeader() })
    .pipe(
      catchError(this.handleUnexpectedError),
      tap(resData => this.defaultResponseHandling(resData, "Subpartner has been successfully deleted.", resData.out_succeed === true && resData.out_data != null))
    );
  }
  
  private defaultResponseHandling(resData: ResponseData, successMessage: string, condition: boolean) {
    if (resData.out_succeed == true && (condition == null || (condition != null && condition))) {
      if(successMessage != null)
        this._snackBarComponent.successMessage(successMessage);
    } else {
      this.handleResponseWarning(resData);
    }

    if (resData.out_error == true) {
      this.handleResponseError(resData);
    }
  }

  private handleResponseWarning(resData: ResponseData) {
    
    if(resData.out_succeed === false) {

      switch (resData.out_msg) {
        case 'SUBPARTNER_ALREADY_EXISTS':
          this._snackBarComponent.warningMessage("Name already in use.");
          break;

        case 'UNABLE_TO_FIND_PARENT_PARTNER':
          this._snackBarComponent.warningMessage("Unable to find parent partner.");
          break;
        
        case 'UNABLE_TO_FIND_ARTIST':
          this._snackBarComponent.warningMessage("Unable to find artist.");
          break;
          
        case 'SUB_PARTNER_DOES_NOT_EXIST':
          this._snackBarComponent.warningMessage("Subpartner doesn't exist.");
          break;

        case 'NAME_ALREADY_IN_USE':
          this._snackBarComponent.warningMessage("Name already in use.");
          break;

        case 'ARTIST_ALREADY_IN_A_SUBPARTNER':
          this._snackBarComponent.warningMessage("Artist already in a subpartner.");
          break;
          
        case 'UNAUTHORIZED':
          this._snackBarComponent.warningMessage("Unauthorized.");
          break;

        default:
          this._snackBarComponent.warningMessage("An unexpected error occurred, please contact your system admin.");
          break;
      }
    }
  }
  
  private handleResponseError(resData: ResponseData) {
        if(resData.out_error) {
        switch (resData.out_msg) {

          case 'UNAUTHORIZED':
            this._snackBarComponent.errorMessage("Unauthorized.");
            break;

          case 'UNKNOWN_ERROR':
            this._snackBarComponent.errorMessage("An unknown error occurred, please contact your system admin.");
            break;
        }
      }
  }

  private handleUnexpectedError(errorRes: HttpErrorResponse) {
    if(API.DEBUG_MODE) 
    console.log("Errors: " + JSON.stringify(errorRes));


    if (errorRes.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      if(API.DEBUG_MODE) 
      console.log('An error occurred:', errorRes.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      if(API.DEBUG_MODE) 
      console.log(
        `Backend returned code ${errorRes.status}, ` +
        `body was: ${errorRes.error}`);

        let errorMessage = 'An unknown error occurred!';
        if (!errorRes.error || !errorRes.error.error) {
          return throwError(errorMessage);
        }
    
        switch (errorRes.status) {
          case 400:
            this._snackBarComponent.errorMessage("A problem occurred, please contact your system admin.");
            break;

          case 401:
            this._snackBarComponent.errorMessage("Unauthorized.");
            break;

          case 500:
            this._snackBarComponent.errorMessage("An unknown error occurred, please contact your system admin.");
            break;

        }
        return throwError(errorMessage);
    }
  }
  
}