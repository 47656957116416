import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { DashboardService } from 'src/app/user/dashboard/dashboard.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { API } from 'src/app/api.component';

@Component({
  selector: 'app-number-card-chart',
  templateUrl: './number-card-chart.component.html',
  styleUrls: ['./number-card-chart.component.scss'],
})
export class NumberCardChartComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription[] = [];

  cardSelectedIndex = 1;



  single: any[];
  view: any[];

  colorScheme = {
    domain: [


      /*'#7ba441',*/
      
      '#a9b957',
      '#d5ce71',
      '#ffee8c',
      '#fab661' 
      
      /*, '#ed7c4f'*/

      ,
    ],
  };
  cardColor: string = '#232837';

  constructor(private _dashboardService: DashboardService) {}

  ngOnInit(): void {
    let subscription: Subscription =
      this._dashboardService.cardsDataObservable.subscribe(
        (data) => (this.single = data)
      );

    this._subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  onSelect(event) { 
    if(API.DEBUG_MODE) 
    console.log('Selected cartd: ' + event.label);

    switch (event.label) {
      case 'Avg. Revenue [€]':
        this.cardSelectedIndex = 0;
        break;

      case 'Total Revenue [€]':
        this.cardSelectedIndex = 1;
        break;

      case 'Total Items':
        this.cardSelectedIndex = 2;
        break;

      case 'Avg. Items':
        this.cardSelectedIndex = 3;
        break;

      default:
        this.cardSelectedIndex = -1;
        break;
    }

    window.dispatchEvent(new Event('resize'));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.view = null;
  }
}
