<h1>MANAGEMENT / FUGA Snapshot</h1>

<div>
   
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="isLoading">
        <app-loading-spinner></app-loading-spinner>
    </div>

    <div>
        <div fxLayout fxLayoutGap="10px" fxLayoutAlign="space-between">
            <div *ngIf="!isLoading">

                <button mat-raised-button color="primary" (click)="onCreate()" [disabled]="isDownloading" *ngIf="isAdmin">
                    <mat-icon>add</mat-icon> Create New Snapshot
                </button>

            </div>

            <div fxLayoutGap="10px" *ngIf="!isLoading && isAdmin">
                <button mat-raised-button color="primary" (click)="onMetadata()">
                    <mat-icon>view_column</mat-icon> Metadata
                </button>
                <button mat-raised-button color="primary" (click)="onDownload()">
                    <mat-icon>get_app</mat-icon> Export Data
                </button>
            </div>

        </div>

        <div fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="!isLoading">
            <mat-form-field fxFlex="40%">
                <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter">
            </mat-form-field>
        </div>

        <mat-progress-bar mode="indeterminate" *ngIf="isDownloading"></mat-progress-bar>
        <mat-table matSort fxLayout="column" fxLayoutAlign="center" fxLayoutGap="10px" [dataSource]="dataSource"
            class="mat-elevation-z8" *ngIf="!isLoading">

            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef mat-sort-header="status">Status</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.status }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="labels">
                <mat-header-cell *matHeaderCellDef mat-sort-header="labels">Labels</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.labels }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="artists">
                <mat-header-cell *matHeaderCellDef mat-sort-header="artists">Artists</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.artists }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="products">
                <mat-header-cell *matHeaderCellDef mat-sort-header="products">Products</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.products }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="productAssets">
                <mat-header-cell *matHeaderCellDef mat-sort-header="productAssets">Product Assets</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.productAssets }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="assets">
                <mat-header-cell *matHeaderCellDef mat-sort-header="partner">Assets</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.assets }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="start">
                <mat-header-cell *matHeaderCellDef mat-sort-header="start">Start</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.start }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="finish">
                <mat-header-cell *matHeaderCellDef mat-sort-header="finish">Finish</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.finish }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="createdBy">
                <mat-header-cell *matHeaderCellDef mat-sort-header="createdBy">Created By</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.createdBy }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="createdOn">
                <mat-header-cell *matHeaderCellDef mat-sort-header="createdOn">Created On</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.createdOn }}</mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="modifiedBy">
                <mat-header-cell *matHeaderCellDef mat-sort-header="modifiedBy">Modified By</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.modifiedBy }}</mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="modifiedOn">
                <mat-header-cell *matHeaderCellDef mat-sort-header="modifiedOn">Modified On</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.modifiedOn }}</mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>



            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <mat-paginator class="mat-elevation-z8" [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 75, 100, 250]" *ngIf="!isLoading">

        </mat-paginator>
    </div>
</div>