import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { API } from '../../api.component';
import { AuthService } from '../../auth/auth.service';
import { ResponseData } from '../../response-data.model';
import { SnackBarComponent } from 'src/app/components/snack-bar/snack-bar/snack-bar.component';

import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';



@Injectable({ providedIn: 'root' })
export class AdminReportService {

  private _accessToken: string = null;

  constructor(private _authService: AuthService, private _httpClient: HttpClient, private _snackBarComponent: SnackBarComponent) {
    this.init();
  }

  init(): void {
    this._authService.serviceAccessTokenObservable.subscribe(accessToken => {
      this._accessToken = accessToken;
    });
  }

  private privateHeader() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + this._accessToken
    });
  }

  getAllReports() {
    return this._httpClient.get<ResponseData>(API.REPORT_GET_ALL, { headers: this.privateHeader() })
      .pipe(
        catchError(this.handleUnexpectedError),
        tap(resData => this.defaultResponseHandling(resData, "Reports were successfully loaded!", resData.out_succeed === true && resData.out_data != null))
      );
  }

  getMissingMatchesGlobalDetails(identifier: string) {
    return this._httpClient.get<ResponseData>(API.REPORT_GET_MISSING_MATCHES_GLOBAL_DETAILS + "/" + identifier, { headers: this.privateHeader() })
      .pipe(
        catchError(this.handleUnexpectedError),
        tap(resData => this.defaultResponseHandling(resData, "Report missing matches were successfully loaded!", resData.out_succeed === true && resData.out_data != null))
      );
  }

  getMissingMatchesDetails(identifier: string) {
    return this._httpClient.get<ResponseData>(API.REPORT_GET_MISSING_MATCHES_DETAILS + "/" + identifier, { headers: this.privateHeader() })
      .pipe(
        catchError(this.handleUnexpectedError),
        tap(resData => this.defaultResponseHandling(resData, "Report missing matches were successfully loaded!", resData.out_succeed === true && resData.out_data != null))
      );
  }

  getAllReportsPayments(identifier: string) {
    return this._httpClient.get<ResponseData>(API.REPORT_GET_ALL_PAYMENTS + "/" + identifier, { headers: this.privateHeader() })
      .pipe(
        catchError(this.handleUnexpectedError),
        tap(resData => this.defaultResponseHandling(resData, "Report payments were successfully loaded!", resData.out_succeed === true && resData.out_data != null))
      );
  }

  getReportData(identifier: string) {
    const httpBody = { identifier: identifier };

    const httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'text/plain',
        'Authorization': 'Bearer ' + this._accessToken
      }),
      responseType: 'text' as 'json'
    };

    return this._httpClient.post<string>(API.REPORT_DOWNLOAD, httpBody, httpHeaders);
  }


  // https://www.ahmedbouchefra.com/angular/angular-9-8-tutorial-example-upload-files-with-formdata-httpclient-rxjs-and-material-progressbar/
  uploadReport(formData: FormData, year: number, month: number) {
    const httpHeaders = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this._accessToken,
      })
    };

    return this._httpClient.post<ResponseData>(API.REPORT_UPLOAD + "/" + year + "/" + month, formData, httpHeaders)
      .pipe(
        catchError(this.handleUnexpectedError),
        tap(resData => this.defaultResponseHandling(resData, "Report was successfully imported.", resData.out_succeed === true))
      );
  }

  matchData(reportIdentifier: string) {
    const httpBody = { identifier: reportIdentifier };
    return this._httpClient.post<ResponseData>(API.REPORT_MATCH, httpBody, { headers: this.privateHeader() })
      .pipe(
        catchError(this.handleUnexpectedError),
        tap(resData => this.defaultResponseHandling(resData, "Report data was successfully matched.", resData.out_succeed === true && resData.out_data != null))
      );
  }

  generatePayments(reportIdentifier: string) {
    const httpBody = { identifier: reportIdentifier };
    return this._httpClient.post<ResponseData>(API.REPORT_GENERATE_PAYMENTS, httpBody, { headers: this.privateHeader() })
      .pipe(
        catchError(this.handleUnexpectedError),
        tap(resData => this.defaultResponseHandling(resData, "Report payments were successfully generated.", resData.out_succeed === true && resData.out_data != null))
      );
  }

  validatePayments(reportIdentifier: string) {
    const httpBody = { identifier: reportIdentifier };
    return this._httpClient.post<ResponseData>(API.REPORT_VALIDATE_PAYMENTS, httpBody, { headers: this.privateHeader() })
      .pipe(
        catchError(this.handleUnexpectedError),
        tap(resData => this.defaultResponseHandling(resData, "Report payments were successfully validated.", resData.out_succeed === true && resData.out_data != null))
      );
  }

  publishPayments(reportIdentifier: string) {
    const httpBody = { identifier: reportIdentifier };
    return this._httpClient.post<ResponseData>(API.REPORT_PUBLISH_PAYMENTS, httpBody, { headers: this.privateHeader() })
      .pipe(
        catchError(this.handleUnexpectedError),
        tap(resData => this.defaultResponseHandling(resData, "Report payments were successfully changed to 'sent to partners'.", resData.out_succeed === true && resData.out_data != null))
      );
  }

  waitForReciptsPayments(reportIdentifier: string) {
    const httpBody = { identifier: reportIdentifier };
    return this._httpClient.post<ResponseData>(API.REPORT_WAIT_FOR_RECIPTS_PAYMENTS, httpBody, { headers: this.privateHeader() })
      .pipe(
        catchError(this.handleUnexpectedError),
        tap(resData => this.defaultResponseHandling(resData, "Report payments were successfully changed to 'wait for recipts'.", resData.out_succeed === true && resData.out_data != null))
      );
  }

  removeReport(identifier: string) {
    return this._httpClient.delete<ResponseData>(API.REPORT_DELETE + "/" + identifier, { headers: this.privateHeader() })
      .pipe(
        catchError(this.handleUnexpectedError),
        tap(resData => this.defaultResponseHandling(resData, "Report has been successfully deleted.", resData.out_succeed === true && resData.out_data != null))
      );
  }

  private defaultResponseHandling(resData: ResponseData, successMessage: string, condition: boolean) {
    if (resData.out_succeed == true && (condition == null || (condition != null && condition))) {
      if (successMessage != null)
        this._snackBarComponent.successMessage(successMessage);
    } else {
      this.handleResponseWarning(resData);
    }

    if (resData.out_error == true) {
      this.handleResponseError(resData);
    }
  }

  private handleResponseWarning(resData: ResponseData) {

    if (resData.out_succeed === false) {

      switch (resData.out_msg) {

        case 'UNABLE_TO_CREATE_NEW_IMPORT':
          this._snackBarComponent.warningMessage("Unable to create new import process.");
          break;

        case 'REPORT_ALREADY_EXISTS':
          this._snackBarComponent.warningMessage("Report already imported for that date.");
          break;


        case 'MISSING_ROW_CONFIGURATIONS':
          this._snackBarComponent.warningMessage("Unable to match all the entries.");
          break;

        case 'INVALID_STATUS_TO_MATCH':
          this._snackBarComponent.warningMessage("Invalid status to match data.");
          break;


        case 'INVALID_STATUS_TO_GENERATE':
          this._snackBarComponent.warningMessage("Invalid status to generate payments.");
          break;

        case 'UNABLE_TO_GENERATE_PAYMENTS':
          this._snackBarComponent.warningMessage("Unable to generate payments.");
          break;


        case 'INVALID_STATUS_TO_VALIDATE':
          this._snackBarComponent.warningMessage("Invalid status to validate payments.");
          break;

        case 'UNABLE_TO_VALIDATE_PAYMENTS':
          this._snackBarComponent.warningMessage("Unable to validate payments.");
          break;


        case 'INVALID_STATUS_TO_PUBLISH':
          this._snackBarComponent.warningMessage("Invalid status to publish payments.");
          break;

        case 'UNABLE_TO_PUBLISH_PAYMENTS':
          this._snackBarComponent.warningMessage("Unable to publish payments.");
          break;

        case 'INVALID_STATUS_TO_WAIT_FOR_PAYMENTS_RECIPTS':
          this._snackBarComponent.warningMessage("Invalid status to change payments to 'Wait for recipts'.");
          break;

        case 'UNABLE_TO_WAIT_FOR_PAYMENTS_RECIPTS':
          this._snackBarComponent.warningMessage("Unable to change payments to 'Wait for recipts'.");
          break;

        case 'UNABLE_TO_FINISH_IMPORT':
          this._snackBarComponent.warningMessage("Unable to finish the import process.");
          break;

        case 'INVALID_STATUS_TO_FINISH':
          this._snackBarComponent.warningMessage("Invalid status to finish the import process.");
          break;

        case 'UNAUTHORIZED':
          this._snackBarComponent.warningMessage("Unauthorized.");
          break;

        default:
          this._snackBarComponent.warningMessage("An unexpected error occurred, please contact your system admin.");
          break;
      }
    }
  }

  private handleResponseError(resData: ResponseData) {
    if (resData.out_error) {
      switch (resData.out_msg) {

        case 'UNAUTHORIZED':
          this._snackBarComponent.errorMessage("Unauthorized.");
          break;

        case 'UNKNOWN_ERROR':
          this._snackBarComponent.errorMessage("An unknown error occurred, please contact your system admin.");
          break;

        default:
          this._snackBarComponent.errorMessage("Error: " + resData.out_msg);
          break;
      }
    }
  }

  private handleUnexpectedError(errorRes: HttpErrorResponse) {
    if(API.DEBUG_MODE) 
    console.log("Errors: " + JSON.stringify(errorRes));


    if (errorRes.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      if(API.DEBUG_MODE) 
      console.log('An error occurred:', errorRes.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      if(API.DEBUG_MODE) 
      console.log(
        `Backend returned code ${errorRes.status}, ` +
        `body was: ${errorRes.error}`);

      let errorMessage = 'An unknown error occurred!';
      if (!errorRes.error || !errorRes.error) {
        return throwError(errorMessage);
      }

      switch (errorRes.status) {
        case 400:
          this._snackBarComponent.errorMessage("A problem occurred, please contact your system admin.");
          break;

        case 401:
          this._snackBarComponent.errorMessage("Unauthorized.");
          break;

        case 500:
          if(errorRes.error.out_error != null 
              && errorRes.error?.out_msg != null
              && errorRes.error.out_error == true) {

            let message: string = "" + errorRes.error.out_msg;
            this._snackBarComponent.errorMessageWithFactor(message, 3);
          } else {
            this._snackBarComponent.errorMessage("An unknown error occurred, please contact your system admin.");
          }

          break;
      }
      return throwError(errorMessage);
    }
  }
}