import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

export interface DeleteDialogData {
  name: string;
}

@Component({
  selector: 'delete-dialog',
  templateUrl: './delete-dialog.component.html'
})
export class DeleteDialog {

  constructor(
    public dialogRef: MatDialogRef<DeleteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteDialogData) { }

  onNoClick(): void {
    this.dialogRef.close(false);

  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }
}