<h1>MANAGEMENT / PARTNERS</h1>

<div>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="isLoading">
        <app-loading-spinner></app-loading-spinner>
    </div>

    <div>
        <div fxLayout fxLayoutGap="10px" fxLayoutAlign="space-between">
            <div *ngIf="!isLoading && isAdmin">

                <button mat-raised-button color="primary" (click)="onCreate()">
                    <mat-icon>add</mat-icon> Create New
                </button>

            </div>

            <div fxLayoutGap="10px" *ngIf="!isLoading && isAdmin">
                <button mat-raised-button color="primary" (click)="onMetadata()">
                    <mat-icon>view_column</mat-icon> Metadata
                </button>
                <button mat-raised-button color="primary" (click)="onDownload()">
                    <mat-icon>get_app</mat-icon> Export Data
                </button>
            </div>

        </div>

        <div fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="!isLoading">
            <mat-form-field fxFlex="40%">
                <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter">
            </mat-form-field>
        </div>


        <mat-table matSort fxLayout="column" fxLayoutAlign="center" fxLayoutGap="10px" [dataSource]="dataSource"
            class="mat-elevation-z8" *ngIf="!isLoading">


            <ng-container matColumnDef="identifier">
                <mat-header-cell *matHeaderCellDef mat-sort-header="identifier">Identifier</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.identifier }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header="name">Name</mat-header-cell>
                <mat-cell *matCellDef="let element" [ngClass]="{'mat-editing-cell': (editingElement == null ? false : element.identifier==editingElement.identifier)}"> 
                    
                    <mat-label *ngIf="editingElement == null ? true : element.identifier!=editingElement.identifier">{{ element.name }}</mat-label>
                    <input matInput placeholder="Name" [(ngModel)]="changedElement.name" [value]="editingElement.name" *ngIf="editingElement == null ? false : element.identifier==editingElement.identifier">
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="isActive">
                <mat-header-cell *matHeaderCellDef mat-sort-header="isActive">Active</mat-header-cell>
                <mat-cell *matCellDef="let element" [ngClass]="{'mat-editing-cell': (editingElement == null ? false : element.identifier==editingElement.identifier)}">
                    <mat-slide-toggle color="primary" [checked]="element.isActive" [disabled]="true" *ngIf="editingElement == null ? true : element.identifier!=editingElement.identifier"></mat-slide-toggle>
                    <mat-slide-toggle color="primary" [checked]="element.isActive" [(ngModel)]="changedElement.isActive" *ngIf="editingElement == null ? false : element.identifier==editingElement.identifier"></mat-slide-toggle>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="allowSubPartners">
                <mat-header-cell *matHeaderCellDef mat-sort-header="allowSubPartners">Allow Sub Partners</mat-header-cell>
                <mat-cell *matCellDef="let element" [ngClass]="{'mat-editing-cell': (editingElement == null ? false : element.identifier==editingElement.identifier)}">
                    <mat-slide-toggle color="primary" [checked]="element.allowSubPartners" [disabled]="true" *ngIf="editingElement == null ? true : element.identifier!=editingElement.identifier"></mat-slide-toggle>
                    <mat-slide-toggle color="primary" [checked]="element.allowSubPartners" [(ngModel)]="changedElement.allowSubPartners" *ngIf="editingElement == null ? false : element.identifier==editingElement.identifier"></mat-slide-toggle>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="accounts">
                <mat-header-cell *matHeaderCellDef mat-sort-header="accounts">Accounts</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.accounts }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="payments">
                <mat-header-cell *matHeaderCellDef mat-sort-header="payments">Payments</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.payments }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="labels">
                <mat-header-cell *matHeaderCellDef mat-sort-header="labels">Labels</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.labels }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="createdBy">
                <mat-header-cell *matHeaderCellDef mat-sort-header="createdBy">Created By</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.createdBy }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="createdOn">
                <mat-header-cell *matHeaderCellDef mat-sort-header="createdOn">Created On</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.createdOn }}</mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="modifiedBy">
                <mat-header-cell *matHeaderCellDef mat-sort-header="modifiedBy">Modified By</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.modifiedBy }}</mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="modifiedOn">
                <mat-header-cell *matHeaderCellDef mat-sort-header="modifiedOn">Modified On</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.modifiedOn }}</mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
                <mat-cell *matCellDef="let element">

                    <div *ngIf="isAdmin">

                    <a (click)="onEditCancel()" *ngIf="editingElement == null ? false : element.identifier==editingElement.identifier">
                        <mat-icon>clear</mat-icon>
                    </a>
                    <a (click)="onEditSave(element)" *ngIf="editingElement == null ? false : element.identifier==editingElement.identifier">
                        <mat-icon>check</mat-icon>
                    </a>

                    <a (click)="onEdit(element)" *ngIf="editingElement == null ? true : element.identifier!=editingElement.identifier">
                        <mat-icon>edit</mat-icon>
                    </a>
                    <a (click)="onDelete(element)" *ngIf="editingElement == null ? true : element.identifier!=editingElement.identifier">
                        <mat-icon>delete</mat-icon>
                    </a>

                </div>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row [ngClass]="{'table-row-make-red': (editingElement == null ? false : row.identifier===editingElement.identifier)}" *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <mat-paginator class="mat-elevation-z8" [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 75, 100, 250]" *ngIf="!isLoading">

        </mat-paginator>
    </div>
</div>