import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { API } from '../../api.component';
import { AuthService } from '../../auth/auth.service';
import { ResponseData } from '../../response-data.model';
import { SnackBarComponent } from 'src/app/components/snack-bar/snack-bar/snack-bar.component';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AdminAccountService {
  private _accessToken: string = null;

  constructor(
    private _authService: AuthService,
    private _httpClient: HttpClient,
    private _snackBarComponent: SnackBarComponent
  ) {
    this.init();
  }

  init(): void {
    this._authService.serviceAccessTokenObservable.subscribe((accessToken) => {
      this._accessToken = accessToken;
    });
  }

  private privateHeader() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Bearer ' + this._accessToken,
    });
  }

  getAllAccounts() {
    return this._httpClient
      .get<ResponseData>(API.ACCOUNT_GET_ALL, { headers: this.privateHeader() })
      .pipe(
        catchError(this.handleUnexpectedError),
        tap((resData) =>
          this.defaultResponseHandling(
            resData,
            'Accounts were successfully loaded!',
            resData.out_succeed === true && resData.out_data != null
          )
        )
      );
  }

  getAllAccountAuthorities() {
    return this._httpClient
      .get<ResponseData>(API.ACCOUNT_GET_ALL_AUTHORITIES, {
        headers: this.privateHeader(),
      })
      .pipe(
        catchError(this.handleUnexpectedError),
        tap((resData) =>
          this.defaultResponseHandling(
            resData,
            'Account authorities were successfully loaded!',
            resData.out_succeed === true && resData.out_data != null
          )
        )
      );
  }

  createAccount(
    firstName: string,
    lastName: string,
    email: string,
    authorityId: number,
    partnerIdentifier: string
  ) {
    const httpBody = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      authorityId: authorityId,
      partnerIdentifier: partnerIdentifier,
    };

    return this._httpClient
      .post<ResponseData>(API.ACCOUNT_CREATE, httpBody, {
        headers: this.privateHeader(),
      })
      .pipe(
        catchError(this.handleUnexpectedError),
        tap((resData) =>
          this.defaultResponseHandling(
            resData,
            'Account has been successfully created.',
            resData.out_succeed === true && resData.out_data != null
          )
        )
      );
  }

  editAccount(
    identifier: string,
    isBlocked: boolean,
    authorityId: number,
    partnerIdentifier: string
  ) {
    const httpBody = {
      identifier: identifier,
      isBlocked: isBlocked,
      authorityId: authorityId,
      partnerIdentifier: partnerIdentifier,
    };

    return this._httpClient
      .put<ResponseData>(API.ACCOUNT_EDIT, httpBody, {
        headers: this.privateHeader(),
      })
      .pipe(
        catchError(this.handleUnexpectedError),
        tap((resData) =>
          this.defaultResponseHandling(
            resData,
            'Account has been successfully edited.',
            resData.out_succeed === true && resData.out_data != null
          )
        )
      );
  }

  removeAccount(identifier: string) {
    return this._httpClient
      .delete<ResponseData>(API.ACCOUNT_DELETE + '/' + identifier, {
        headers: this.privateHeader(),
      })
      .pipe(
        catchError(this.handleUnexpectedError),
        tap((resData) =>
          this.defaultResponseHandling(
            resData,
            'Account has been successfully deleted.',
            resData.out_succeed === true && resData.out_data != null
          )
        )
      );
  }

  private defaultResponseHandling(
    resData: ResponseData,
    successMessage: string,
    condition: boolean
  ) {
    if (
      resData.out_succeed == true &&
      (condition == null || (condition != null && condition))
    ) {
      if (successMessage != null)
        this._snackBarComponent.successMessage(successMessage);
    } else {
      this.handleResponseWarning(resData);
    }

    if (resData.out_error == true) {
      this.handleResponseError(resData);
    }
  }

  private handleResponseWarning(resData: ResponseData) {
    if (resData.out_succeed === false) {
      switch (resData.out_msg) {
        case 'ACCOUNT_ALREADY_EXISTS':
          this._snackBarComponent.warningMessage('Email already in use.');
          break;

        case 'ACCOUNT_DOES_NOT_EXIST':
          this._snackBarComponent.warningMessage("Account doesn't exist.");
          break;

        case 'UNAUTHORIZED':
          this._snackBarComponent.warningMessage('Unauthorized.');
          break;

        default:
          this._snackBarComponent.warningMessage(
            'An unexpected error occurred, please contact your system admin.'
          );
          break;
      }
    }
  }

  private handleResponseError(resData: ResponseData) {
    if (resData.out_error) {
      switch (resData.out_msg) {
        case 'UNAUTHORIZED':
          this._snackBarComponent.errorMessage('Unauthorized.');
          break;

        case 'UNKNOWN_ERROR':
          this._snackBarComponent.errorMessage(
            'An unknown error occurred, please contact your system admin.'
          );
          break;
      }
    }
  }

  private handleUnexpectedError(errorRes: HttpErrorResponse) {
    if (API.DEBUG_MODE) console.log('Errors: ' + JSON.stringify(errorRes));

    if (errorRes.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      if (API.DEBUG_MODE)
        console.log('An error occurred:', errorRes.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      if (API.DEBUG_MODE)
        console.log(
          `Backend returned code ${errorRes.status}, ` +
            `body was: ${errorRes.error}`
        );

      let errorMessage = 'An unknown error occurred!';
      if (!errorRes.error || !errorRes.error.error) {
        return throwError(errorMessage);
      }

      switch (errorRes.status) {
        case 400:
          this._snackBarComponent.errorMessage(
            'A problem occurred, please contact your system admin.'
          );
          break;

        case 401:
          this._snackBarComponent.errorMessage('Unauthorized.');
          break;

        case 500:
          this._snackBarComponent.errorMessage(
            'An unknown error occurred, please contact your system admin.'
          );
          break;
      }
      return throwError(errorMessage);
    }
  }
}
