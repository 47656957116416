
import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { FormsModule, ReactiveFormsModule  } from '@angular/forms';

// Flex Layout
import { FlexLayoutModule } from '@angular/flex-layout';

// Http Client
import { HttpClientModule } from '@angular/common/http';

// Angular Materials
import { MaterialModule } from './material.module';

// Ngx Charts
import { NgxChartsModule } from '@swimlane/ngx-charts';

// Angular Routing
import { AppRoutingModule } from './app-routing.module';

// App Component
import { AppComponent } from './app.component';

// Auth Components
import { LoginComponent } from './auth/login/login.component';
import { ForgotComponent } from './auth/forgot/forgot.component';

import { ChangeComponent } from './auth/change/change.component';
import { ProfileComponent } from './auth/profile/profile.component';

import { SnackBarComponent } from './components/snack-bar/snack-bar/snack-bar.component';

// Admin Components
import { AdminAccountsComponent } from './admin/admin-accounts/admin-accounts.component';
import { AdminPartnersComponent } from './admin/admin-partners/admin-partners.component';
import { AdminLabelsComponent } from './admin/admin-labels/admin-labels.component';
import { AdminPaymentsComponent } from './admin/admin-payments/admin-payments.component';
import { AdminReportsComponent } from './admin/admin-reports/admin-reports.component';
import { AdminFugaComponent } from './admin/admin-fuga/admin-fuga.component';
import { AdminNewReportComponent } from './admin/admin-reports/admin-new-report/admin-new-report.component';
import { AdminSubPartnersComponent } from './admin/admin-sub-partners/admin-sub-partners.component';
import { AdminArtistsComponent } from './admin/admin-artists/admin-artists.component';

// User Components
import { DashboardComponent } from './user/dashboard/dashboard.component';
import { PaymentsComponent } from './user/payments/payments.component';

import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';

// Services
import { AuthService } from './auth/auth.service';
import { PaymentService } from './user/payments/payments.service';
import { AdminPartnerService } from './admin/admin-partners/admin-partner.service';
import { AdminReportService } from './admin/admin-reports/admin-reports.service';
import { AdminPaymentService } from './admin/admin-payments/admin-payments.service';
import { AdminFugaService } from './admin/admin-fuga/admin-fuga.service';
import { AdminSubPartnerService } from './admin/admin-sub-partners/admin-sub-partner.service';
import { AdminAccountService } from './admin/admin-accounts/admin-accounts.service';
import { AdminLabelService } from './admin/admin-labels/admin-labels.service';
import { AdminArtistService } from './admin/admin-artists/admin-artists.service';
import { AdminMappingService } from './admin/admin-mappings/admin-mapping.service';

// Dialogs
import { CreateAdminPartnerDialog } from './admin/admin-partners/create-admin-partner-dialog/create-admin-partner-dialog.component';
import { CreateAdminSubPartnerDialog } from './admin/admin-sub-partners/create-admin-sub-partner-dialog/create-admin-sub-partner-dialog.component';
import { CreateAdminAccountDialog } from './admin/admin-accounts/create-admin-account-dialog/create-admin-account-dialog.component';
import { CreateAdminPaymentDialog } from './admin/admin-payments/create-admin-payment-dialog/create-admin-payment-dialog.component';
import { DetailsDialog } from './components/dialogs/details-dialog/details-dialog.component';
import { DeleteDialog } from './components/dialogs/delete-dialog/delete-dialog.component';
import { UnavailableComponent } from './components/unavailable/unavailable.component';
import { NumberCardChartComponent } from './charts/number-card-chart/number-card-chart.component';
import { RevenueChartComponent } from './charts/revenue-chart/revenue-chart.component';
import { ItemChartComponent } from './charts/item-chart/item-chart.component';
import { CountryChartComponent } from './charts/country-chart/country-chart.component';
import { DspChartComponent } from './charts/dsp-chart/dsp-chart.component';
import { ArtistChartComponent } from './charts/artist-chart/artist-chart.component';
import { LabelChartComponent } from './charts/label-chart/label-chart.component';
import { AssetChartComponent } from './charts/asset-chart/asset-chart.component';
import { TypeOfSaleChartComponent } from './charts/type-of-sale-chart/type-of-sale-chart.component';
import { DashboardService } from './user/dashboard/dashboard.service';
import { AdminMappingsComponent } from './admin/admin-mappings/admin-mappings.component';
import { CreateAdminMappingDialog } from './admin/admin-mappings/create-admin-mapping-dialog/create-admin-mapping-dialog.component';


// Create new component command
// ng g c dashboard/welcome --module app.module

@NgModule({
  declarations: [
    AppComponent,

    // Auth Components
    LoginComponent,
    ForgotComponent,

    ChangeComponent,
    ProfileComponent,

    // User Components
    DashboardComponent,
    PaymentsComponent,

    // Admin Components
    AdminAccountsComponent,
    AdminPartnersComponent,
    AdminSubPartnersComponent,
    AdminLabelsComponent,
    AdminArtistsComponent,
    AdminPaymentsComponent,
    AdminReportsComponent,
    AdminFugaComponent,
    AdminMappingsComponent,
    AdminNewReportComponent,

    // Layout Components
    ToolbarComponent,
    SidenavComponent,
    FooterComponent,

    // Charts
    NumberCardChartComponent,

    // Other Components
    LoadingSpinnerComponent,
    

    
    SnackBarComponent,
    
    // Dialogs
    DeleteDialog,
    DetailsDialog,
    CreateAdminPartnerDialog,
    CreateAdminSubPartnerDialog,
    CreateAdminAccountDialog,
    CreateAdminPaymentDialog,
    CreateAdminMappingDialog,
    UnavailableComponent,
    RevenueChartComponent,
    ItemChartComponent,
    CountryChartComponent,
    DspChartComponent,
    ArtistChartComponent,
    LabelChartComponent,
    AssetChartComponent,
    TypeOfSaleChartComponent,
    

    

  ],
  imports: [
    HttpClientModule,
    
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    AppRoutingModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,

    //ChartsModule
    NgxChartsModule,

  ],
  // Colocar Servicos (Singleton)
  providers: [DashboardService, SnackBarComponent, AuthService, AdminMappingService, AdminAccountService, AdminPartnerService, AdminSubPartnerService, AdminLabelService, AdminArtistService, AdminPaymentService, AdminReportService, AdminFugaService, PaymentService],
  bootstrap: [AppComponent]
})
export class AppModule { }
