import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { map, tap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { API } from '../api.component';

@Injectable({ providedIn: 'root' })
export class AuthAdminGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.authService.componentUserObservable
            .pipe(
                take(1),
                //map(user => !!user && user.authorityId != 1),
                map(user => !!user  && user.authorityId != 1),
                tap(isAuthenticated => {
                    if (!isAuthenticated) {
                        if(API.DEBUG_MODE) 
                        console.log("Guard: " + this.router.url)

                        this.router.navigate(['/login']);
                    }
                })
            );
    }

}