import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { AdminPartnerService } from './admin-partner.service';

import { ExportToCsv } from 'export-to-csv';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialog } from 'src/app/components/dialogs/delete-dialog/delete-dialog.component';
import { CreateAdminPartnerDialog } from './create-admin-partner-dialog/create-admin-partner-dialog.component';
import { AuthService } from 'src/app/auth/auth.service';

export interface AdminPartnerElement {
  identifier: string;
  name: string;
  isActive: boolean;
  allowSubPartners: boolean;
  accounts: number;
  payments: number;
  labels: number;
  createdBy: string;
  createdOn: string;
  modifiedBy: string;
  modifiedOn: string;
}

@Component({
  selector: 'app-admin-partners',
  templateUrl: './admin-partners.component.html',
  styleUrls: ['./admin-partners.component.scss']
})
export class AdminPartnersComponent implements OnInit, AfterViewInit, OnDestroy {

  private _subscriptions: Subscription[] = [];

  isLoading = false;

  showMetadata = false;
  private _adminShowMetadataColumns = ['identifier', 'name', 'isActive', 'allowSubPartners', 'createdBy', 'accounts', 'payments', 'labels', 'createdOn', 'modifiedBy', 'modifiedOn', 'action'];
  private _adminHideMetadataColumns = ['name', 'isActive', 'allowSubPartners', 'accounts', 'payments',  'labels', 'action'];
  private _moderatorHideMetadataColumns = ['name', 'isActive', 'allowSubPartners', 'accounts', 'payments', 'labels'];
  displayedColumns: string[];

  dataSource = new MatTableDataSource<AdminPartnerElement>();
  private _data: AdminPartnerElement[] = [];

  editingElement: AdminPartnerElement = null;
  changedElement: AdminPartnerElement = null;

  private _isAdmin: boolean = false;


  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private _authService: AuthService, private _adminPartnerService: AdminPartnerService, public _dialog: MatDialog) {
    this._isAdmin = this._authService.isAdmin();
    this.displayedColumns = this._isAdmin ? this._adminHideMetadataColumns : this._moderatorHideMetadataColumns;
  }

  get isAdmin(): boolean {
    return this._isAdmin;
  }

  ngOnInit(): void {
    this.dataSource.data = this._data;

    this._subscriptions.push(this.loadAllPartners());
  }

  private loadAllPartners(): Subscription {
    return this._adminPartnerService.getAllPartners()
      .subscribe(resData => {
        if (resData.out_succeed === true && resData.out_data != null) {
          this._data = <AdminPartnerElement[]>resData.out_data;
          this.dataSource.data = this._data;
        }
      });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());

    this._dialog.closeAll();
  }

  doFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onCreate(): void {

    if (!this.isAdmin)
      return;

    const dialogRef = this._dialog.open(CreateAdminPartnerDialog, {
      width: '400px',
      height: '300px',
      disableClose: true,
      data: { name: '', isActive: true, allowSubPartners: false }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result != null) {

        this._data.splice(0, 0, result.newPartner);
        this.dataSource.data = [...this._data];
      }
    });
  }

  onEdit(selectedElement: AdminPartnerElement): void {

    if (!this.isAdmin)
      return;

    this.editingElement = Object.assign({}, selectedElement);
    this.changedElement = Object.assign({}, selectedElement);
  }


  onEditCancel(): void {

    if (!this.isAdmin)
      return;

    this.editingElement = null;
    this.changedElement = null;
  }

  onEditSave(selectedElement: AdminPartnerElement): void {

    if (!this.isAdmin)
      return;

    let subscription: Subscription = this._adminPartnerService.editPartner(selectedElement.identifier, this.changedElement.name, this.changedElement.isActive, this.changedElement.allowSubPartners)
      .subscribe(resData => {
        if (resData.out_succeed === true && resData.out_data != null) {
          var partnerElement: AdminPartnerElement = <AdminPartnerElement>resData.out_data;
          let index: number = this._data.findIndex(element => element === selectedElement);
          this._data.splice(index, 1, partnerElement);
          this.dataSource.data = [...this._data];
        }
      });

    this._subscriptions.push(subscription);

    this.editingElement = null;
    this.changedElement = null;
  }

  onDelete(selectedElement: AdminPartnerElement): void {

    if (!this.isAdmin)
      return;

    const dialogRef = this._dialog.open(DeleteDialog, {
      width: '400px',
      data: { name: selectedElement.name, delete: false }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        let subscription: Subscription = this._adminPartnerService.removePartner(selectedElement.identifier)
          .subscribe(resData => {

            if (resData.out_succeed === true) {
              let index: number = this._data.findIndex(element => element === selectedElement);
              this._data.splice(index, 1);
              this.dataSource.data = [...this._data];
            }
          });

        this._subscriptions.push(subscription);
      }
    });
  }

  onMetadata(): void {

    if (!this.isAdmin)
      return;

    this.showMetadata = !this.showMetadata;

    if (this.showMetadata) {
      this.displayedColumns = this._adminShowMetadataColumns;

    } else {
      this.displayedColumns = this._adminHideMetadataColumns;
    }
  }

  onDownload(): void {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Admin Partners CSV',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      headers: this.displayedColumns
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(this.dataSource.data);
  }
}