import { Component, Inject, OnDestroy } from '@angular/core';
import { AdminSimplePartnerElement, AdminSimpleAccountAuthorityElement, AdminAccountElement } from '../admin-accounts.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdminAccountService } from '../admin-accounts.service';
import { Subscription } from 'rxjs';
import { SnackBarComponent } from 'src/app/components/snack-bar/snack-bar/snack-bar.component';

export interface CreateAdminAccountDialogData {
  firstName: string;
  lastName: string;
  email: string;
  authority: string;
  partner: string;
  authorities: AdminSimpleAccountAuthorityElement[];
  partners: AdminSimplePartnerElement[];
}

@Component({
  selector: 'app-create-admin-account-dialog',
  templateUrl: './create-admin-account-dialog.component.html'
})
export class CreateAdminAccountDialog implements OnDestroy {

  private _subscriptions: Subscription[] = [];

  constructor(private _adminAccountService: AdminAccountService, private _snackBarComponent: SnackBarComponent, public _dialogRef: MatDialogRef<CreateAdminAccountDialog>,
    @Inject(MAT_DIALOG_DATA) public data: CreateAdminAccountDialogData) {

  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onCancel(): void {
    this._dialogRef.close();
  }

  onCreate(): void {

    let emailRegex = RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$');

    let emailLowerCase = this.data.email.toLowerCase();

    if (this.data.firstName === null || this.data.firstName.trim() === '')
      this._snackBarComponent.errorMessage("First name must be specified.");

    else if (this.data.lastName === null || this.data.lastName.trim() === '')
      this._snackBarComponent.errorMessage("Last name must be specified.");

    else if (this.data.email === null || this.data.email.trim() === '' || !emailRegex.test(emailLowerCase))
      this._snackBarComponent.errorMessage("A valid email must be specified.");

    else if (this.data.authority === null || this.data.authority.trim() === '')
      this._snackBarComponent.errorMessage("Authority must be specified.");

    else if (this.data.partner === null || this.data.partner.trim() === '')
      this._snackBarComponent.errorMessage("Partner must be specified.");

    else {

      let authorityId = null;

      if (this.data.authority === null || this.data.authority === "") {
        authorityId = null
      } else {
        const authority = this.data.authorities.find(element => element.name === this.data.authority);
        authorityId = authority.id;
      }

      let partnerIdentifier = null;

      if (this.data.partner === null || this.data.partner === "") {
        partnerIdentifier = null
      } else {
        const partner = this.data.partners.find(element => element.name === this.data.partner);
        partnerIdentifier = partner.identifier;
      }

      let subscription: Subscription = this._adminAccountService.createAccount(this.data.firstName, this.data.lastName, emailLowerCase, authorityId, partnerIdentifier)
        .subscribe(resData => {

          if (resData.out_succeed === true && resData.out_data != null) {
            var accountElement: AdminAccountElement = <AdminAccountElement>resData.out_data;
            this._dialogRef.close({ newAccount: accountElement });
          }
        });

      this._subscriptions.push(subscription);
    }
  }
}
