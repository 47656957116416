
import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';


import { Subscription } from 'rxjs';
import { AdminReportService } from './admin-reports.service';
import { SnackBarComponent } from 'src/app/components/snack-bar/snack-bar/snack-bar.component';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ExportToCsv } from 'export-to-csv';
import { MatDialog } from '@angular/material/dialog';
import { DetailsDialog } from 'src/app/components/dialogs/details-dialog/details-dialog.component';
import { DeleteDialog } from 'src/app/components/dialogs/delete-dialog/delete-dialog.component';
import { API } from 'src/app/api.component';



export interface AdminReportElement {
  identifier: string;
  statusId: number;
  status: string;
  year: number;
  month: number;
  fileName: string;
  start: string;
  finish: string;
  fileRows: number;
  importedRows: number;
  importDetails: string;
  createdBy: string;
  createdOn: string;
  modifiedBy: string;
  modifiedOn: string;
}


@Component({
  selector: 'app-admin-reports',
  templateUrl: './admin-reports.component.html',
  styleUrls: ['./admin-reports.component.scss']
})
export class AdminReportsComponent implements OnInit, AfterViewInit, OnDestroy {

  private _subscriptions: Subscription[] = [];

  isLoading = false;
  downloadingFiles: number = 0;

  showMetadata = false;
  private _adminShowMetadataColumns = ['identifier', 'year', 'month', 'status', 'fileRows', 'importedRows', 'start', 'finish', 'createdBy', 'createdOn', 'modifiedBy', 'modifiedOn', 'action'];
  private _adminHideMetadataColumns = ['year', 'month', 'status', 'fileRows', 'importedRows', 'start', 'finish', 'fileVersion', 'action'];
  private _moderatorHideMetadataColumns = ['year', 'month', 'status', 'fileRows', 'importedRows', 'start', 'finish', 'fileVersion', 'fileName'];
  displayedColumns: string[];

  dataSource = new MatTableDataSource<AdminReportElement>();
  private _data: AdminReportElement[] = [];

  private _isAdmin: boolean = false;

  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;


  constructor(private _authService: AuthService, private _adminReportService: AdminReportService, private _router: Router, private _snackBarComponent: SnackBarComponent, private _dialog: MatDialog) { 
    this._isAdmin = this._authService.isAdmin();
    this.displayedColumns = this._isAdmin ? this._adminHideMetadataColumns : this._moderatorHideMetadataColumns;
  }

  get isAdmin(): boolean {
    return this._isAdmin;
  }

  ngOnInit(): void {
    this.dataSource.data = this._data;

    this._subscriptions.push(this.loadAllReports());
  }

  private loadAllReports(): Subscription {
    return this._adminReportService.getAllReports()
    .subscribe(resData => {
      if (resData.out_succeed === true && resData.out_data != null) {
        this._data = <AdminReportElement[]>resData.out_data;
        this.dataSource.data = this._data;
      }
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  doFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onDisplayDetails(element: AdminReportElement): void {
    if (!this.isAdmin)
      return;

    const dialogRef = this._dialog.open(DetailsDialog, {
      width: '400px',
      data: { message: JSON.stringify(element.importDetails) }
    });

    dialogRef.afterClosed().subscribe();
  }

  onDownloadData(element: AdminReportElement): void {
    this._snackBarComponent.successMessage("Download started...");
    this.downloadingFiles++;
    
    let subscription: Subscription = this._adminReportService.getReportData(element.identifier)
    .subscribe(resData => {
      if (resData != null) {
        if(API.DEBUG_MODE) 
        console.log(resData);

        const blob = new Blob([resData], { type: 'text/csv;charset=utf-8' });
        let filename = element.fileName;
        saveAs(blob, filename);

        this._snackBarComponent.successMessage("File downloaded with success!");

      } else {
        this._snackBarComponent.errorMessage("Unable to download file.");
      }

      this.downloadingFiles--;
    });

    this._subscriptions.push(subscription);
  }

  onCreate(): void {

    if (!this.isAdmin)
    return;
    if(API.DEBUG_MODE) 
    console.log("Create Listener: ");
    this._router.navigate(['/admin/reports/0/new']);
  }

  onEdit(selectedElement: AdminReportElement): void {

    if (!this.isAdmin)
    return;
    
    this._router.navigate(['/admin/reports/' + selectedElement.statusId + '/' + selectedElement.identifier]);
  }

  onDelete(selectedElement: AdminReportElement): void {
    if (!this.isAdmin)
      return;

    const dialogRef = this._dialog.open(DeleteDialog, {
      width: '400px',
      data: { name: "Report " + selectedElement.identifier, delete: false }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        let subscription: Subscription = this._adminReportService.removeReport(selectedElement.identifier)
        .subscribe(resData => {
    
            if (resData.out_succeed === true) {
              let index: number = this._data.findIndex(element => element === selectedElement);
              if(API.DEBUG_MODE) 
              console.log(this._data.findIndex(element => element.identifier === selectedElement.identifier));
    
              this._data.splice(index, 1)
              this.dataSource.data = [...this._data];
            }
          });
    
          this._subscriptions.push(subscription);
      }
    });


  }

  onMetadata(): void {

    if (!this.isAdmin)
    return;

    this.showMetadata = !this.showMetadata;

    if (this.showMetadata) {
      this.displayedColumns = this._adminShowMetadataColumns;

    } else {
      this.displayedColumns = this._adminHideMetadataColumns;
    }
  }

  onDownload(): void {

    if (!this.isAdmin)
    return;

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Admin Reports CSV',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(this.dataSource.data);
  }

}
