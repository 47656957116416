<div fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="10px" style="height: 100%;">
    <h1 mat-dialog-title>New Mapping</h1>
    <div fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="10px" style="height: 100%;">
        <p>New mapping details:</p>


        <mat-form-field>
            <mat-label>Old UPC</mat-label>
            <input matInput name="oldUpc" placeholder="Old UPC" [(ngModel)]="data.oldUpc" [value]="data.oldUpc">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Old ISRC</mat-label>
            <input matInput name="oldIsrc" placeholder="Old ISRC" [(ngModel)]="data.oldIsrc" [value]="data.oldIsrc">
        </mat-form-field>

        <mat-form-field>
            <mat-label>New UPC</mat-label>
            <input matInput name="newUpc" placeholder="New UPC" [(ngModel)]="data.newUpc" [value]="data.newUpc">
        </mat-form-field>

        <mat-form-field>
            <mat-label>New ISRC</mat-label>
            <input matInput name="newIsrc" placeholder="New ISRC" [(ngModel)]="data.newIsrc" [value]="data.newIsrc">
        </mat-form-field>


        <div mat-dialog-actions>
            <button mat-button (click)="onCancel()" cdkFocusInitial>Cancel</button>
            <button mat-button (click)="onCreate()">Create</button>
        </div>
    </div>
</div>