<section class="center">

    <div >
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="isLoading">
            <app-loading-spinner></app-loading-spinner>
        </div>
 
        <div class="mat-elevation-z8 mid" fxLayout="row" fxLayoutAlign="center center" *ngIf="!isLoading "> 

            <form fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px" #f="ngForm" (ngSubmit)="onSubmit(f)" *ngIf="!isLoading">
                <h1>Change Password</h1>
                <h3 class="margin-top">Please enter your current and new password.</h3>

                <mat-form-field class="expand-width margin-top" hintLabel="Should be at least 8 characters long.">
                    <input matInput type="password" placeholder="Your current password" ngModel name="password0" required minlength="8" #pw0Input="ngModel">
                    <!--    <mat-hint align="end">{{ pwInput.value?.length }} / 8</mat-hint> -->
                    <mat-error *ngIf="pw0Input.hasError('required')">Field can't be empty!</mat-error>
                    <mat-error *ngIf="!pw0Input.hasError('required') && pw0Input.errors?.minlength">Should be at least 8
                        characters long.</mat-error>
                </mat-form-field>
                
                <mat-form-field class="expand-width margin-top" hintLabel="Should be at least 8 characters long.">
                    <input matInput type="password" placeholder="Your new password" ngModel name="password1" required minlength="8" #pw1Input="ngModel">
                    <!--    <mat-hint align="end">{{ pwInput.value?.length }} / 8</mat-hint> -->
                    <mat-error *ngIf="pw1Input.hasError('required')">Field can't be empty!</mat-error>
                    <mat-error *ngIf="!pw1Input.hasError('required') && pw1Input.errors?.minlength">Should be at least 8
                        characters long.</mat-error>
                </mat-form-field>

                
                <mat-form-field class="expand-width margin-top" hintLabel="Should be at least 8 characters long.">
                    <input matInput type="password" placeholder="Confirm your new password" ngModel name="password2" required minlength="8" #pw2Input="ngModel">
                    <!--    <mat-hint align="end">{{ pwInput.value?.length }} / 8</mat-hint> -->
                    <mat-error *ngIf="pw2Input.value != ''">Passwords don't match!</mat-error>
                </mat-form-field>
    
                <div class="margin-top" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center">

                    <button mat-raised-button class="expand-half-width" (click)="onCancel()" type="button" 
                    style="background-color: black; color: white;">Cancel</button>
                    <button mat-raised-button class="expand-half-width" type="submit" color="primary" [disabled]="f.invalid">Submit</button>
                </div>

            </form>
        </div>


    </div>


</section>