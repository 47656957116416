import {
  Component,
  OnInit,
  Input,
  ViewChild,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs/internal/Subscription';
import {
  DashboardService,
  DataChartElement,
} from 'src/app/user/dashboard/dashboard.service';
import { API } from 'src/app/api.component';

/*
var single = [
  {
    "name": "Salvador Sobral",
    "value": 8940000
  },
  {
    "name": "USA",
    "value": 5000000
  },
  {
    "name": "France",
    "value": 7200000
  },
  {
    "name": "Germany1",
    "value": 8940000
  },
  {
    "name": "USA1",
    "value": 5000000
  },
  {
    "name": "France1",
    "value": 7200000
  },
  {
    "name": "Germany2",
    "value": 8940000
  },
  {
    "name": "USA2",
    "value": 5000000
  },
  {
    "name": "France2",
    "value": 7200000
  },
  {
    "name": "USA3",
    "value": 5000000
  },
  {
    "name": "Others",
    "value": 7200000
  }
];

*/

@Component({
  selector: 'app-label-chart',
  templateUrl: './label-chart.component.html',
  styleUrls: ['./label-chart.component.scss'],
})
export class LabelChartComponent implements OnInit, OnDestroy {
  @Input() showLabelChart: boolean;

  displayedColumns: string[] = ['name', 'revenue', 'items'];
  dataSource = new MatTableDataSource<DataChartElement>();
  footer: DataChartElement;
  displayLabelData: boolean;

  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  private _subscriptions: Subscription[] = [];

  single: any[];
  view: any[];

  // options
  showXAxis: boolean = false;
  showYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = true;
  yAxisLabel: string = 'Revenue';
  showYAxisLabel: boolean = true;
  xAxisLabel: string = '';
  showXAxisLabel: boolean = false;

  colorScheme = {
    domain: [
      '#00876c',
      '#459e70',
      '#72b373',
      '#9fc878',
      '#cedc80',
      '#ffee8c',
      '#fdcd70',
      '#f9ab5c',
      '#f18851',
      '#e5644e',
      '#d43d51',
    ],
  };

  constructor(private _dashboardService: DashboardService) {
    //Object.assign(this, { single });
  }

  ngOnInit(): void {
    this.dataSource.data = [];

    let subscription: Subscription =
      this._dashboardService.labelDataObservable.subscribe((data) => {
        this.footer = data.shift();
        this.dataSource.data = data;
        data.pop();
        this.single = data;

        if(API.DEBUG_MODE) 
        console.log("Labels Data: " + (data.length))

        this.displayLabelData = false;
      });

    this._subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  onSelect(data): void {
    if(API.DEBUG_MODE) 
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    if(API.DEBUG_MODE) 
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    if(API.DEBUG_MODE) 
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.view = null;
  }
}
