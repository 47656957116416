import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { API } from '../../api.component';
import { AuthService } from '../../auth/auth.service';
import { ResponseData } from '../../response-data.model';
import { SnackBarComponent } from 'src/app/components/snack-bar/snack-bar/snack-bar.component';

import { catchError, tap } from 'rxjs/operators';
import { BehaviorSubject, Subject, throwError } from 'rxjs';



export interface PartnerNameElement {
  identifier: string;
  name: string;
}

export interface PaymentDateElement {
  identifier: string;
  date: string;
}

export interface NameValueElement {
  name: string;
  value: number;
}

export interface DataChartElement {
  name: string;
  value: number; // revenue
  items: number;
}


@Injectable({ providedIn: 'root' })
export class DashboardService {

  private _partnerNames = new BehaviorSubject<PartnerNameElement[]>([]);
  partnerNamesObservable = this._partnerNames.asObservable();

  private _paymentDates = new BehaviorSubject<PaymentDateElement[]>([]);
  paymentDatesObservable = this._paymentDates.asObservable();

  private _numberCardsData = new BehaviorSubject<NameValueElement[]>([]);
  cardsDataObservable = this._numberCardsData.asObservable();

  private _overallRevenueData = new BehaviorSubject<NameValueElement[]>([]);
  overallRevenueDataObservable = this._overallRevenueData.asObservable();

  private _overallItemsData = new BehaviorSubject<NameValueElement[]>([]);
  overallItemsDataObservable = this._overallItemsData.asObservable();

  private _countryData = new BehaviorSubject<DataChartElement[]>([]);
  countryDataObservable = this._countryData.asObservable();

  private _dspData = new BehaviorSubject<DataChartElement[]>([]);
  dspDataObservable = this._dspData.asObservable();

  private _typeOfSaleData = new BehaviorSubject<DataChartElement[]>([]);
  typeOfSaleDataObservable = this._typeOfSaleData.asObservable();

  private _labelData = new BehaviorSubject<DataChartElement[]>([]);
  labelDataObservable = this._labelData.asObservable();

  private _artistData = new BehaviorSubject<DataChartElement[]>([]);
  artistDataObservable = this._artistData.asObservable();

  private _assetData = new BehaviorSubject<DataChartElement[]>([]);
  assetDataObservable = this._assetData.asObservable();

  private _showCharts = new BehaviorSubject<Map<String, boolean>>(new Map());
  showChartsDataObservable = this._showCharts.asObservable();

  private _accountSubject = new Subject();
  accountObservable = this._assetData.asObservable();

  private _selectedPartner: PartnerNameElement;
  private _selectedDate: PaymentDateElement;
  
  private _accessToken: string = null;
  private _partner: string = null;
  private _isAuthenticated: boolean = false;
  private _isAdmin: boolean = false;
  private _isModerator: boolean = false;


  constructor(private _authService: AuthService, private _httpClient: HttpClient, private _snackBarComponent: SnackBarComponent) {
    this.init();
  }

  get isAdmin() {
    return this._isAdmin;
  }

  get isModerator() {
    return this._isModerator;
  }

  get selectedPartner() : PartnerNameElement {
    return this._selectedPartner;
  }

  get selectedDate(): PaymentDateElement {
    return this._selectedDate;
  }

  private init(): void {

    this._authService.serviceAccessTokenObservable
    .subscribe(accessToken => {
      this._accessToken = accessToken;
    });

    this._authService.componentUserObservable
    .subscribe(user => {
      this._isAuthenticated = !!user; 

      if(this._isAuthenticated) {
        this._partner = user.partner;
        this._isAdmin = user.authorityId == 3;
        this._isModerator = user.authorityId == 2;

        this._selectedPartner = {'identifier': '', 'name': this._partner};
        this._accountSubject.next();
      }
    });
  }



  private privateHeader() {
    return new HttpHeaders({
        //'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this._accessToken
      });
  }

  initialLoad(): void {
    this._selectedPartner = {'identifier': '', 'name': this._partner};
    if(this._isAdmin || this._isModerator) {
      this.getAllPartnerWithPayments();
    }

    this.getAllPartnerPaymentDates(null);
    this.getAllPartnerOverallStats(null);
    this.getAllPartnerMonthlyStats(null, null);
  }

  changePartner(componentSelectedPartner: PartnerNameElement): void {
    if(!this._isAdmin && !this.isModerator)
      return;

    let partnerIdentifier = componentSelectedPartner != null && componentSelectedPartner.identifier != null ? componentSelectedPartner.identifier : null;

    if(partnerIdentifier != null && partnerIdentifier.trim() == '')
      partnerIdentifier = null;

      if(this._selectedPartner != null && partnerIdentifier != this._selectedPartner.identifier) {
        this._selectedPartner = componentSelectedPartner;

        this.getAllPartnerPaymentDates(partnerIdentifier);
        this.getAllPartnerOverallStats(partnerIdentifier);
        this.getAllPartnerMonthlyStats(partnerIdentifier, null);
      }
  }

  changeDate(componentSelectedDate: PaymentDateElement): void {
    let paymentIdentifier = componentSelectedDate != null && componentSelectedDate.identifier != null ? componentSelectedDate.identifier : null;

    if(paymentIdentifier != null && paymentIdentifier.trim() == '')
      paymentIdentifier = null;

    if(this._selectedDate != null && this._selectedDate.identifier != paymentIdentifier) {
      this._selectedDate = componentSelectedDate;

      let partnerIdentifier = this._selectedPartner != null ? this._selectedPartner.identifier : null;

      this.getAllPartnerMonthlyStats(partnerIdentifier, paymentIdentifier);
    }
  }

  private getAllPartnerPaymentDates(partnerIdentifier: string): void {
    let requestParameters = new HttpParams();
    if(partnerIdentifier != null)
      requestParameters = requestParameters.append("identifier", partnerIdentifier);

    this._httpClient.get<ResponseData>(API.DASHBOARD_GET_ALL_PARTNER_PAYMENT_DATES, {headers: this.privateHeader(), params: requestParameters })
    .pipe(
      catchError(this.handleUnexpectedError),
      tap(resData =>  this.defaultResponseHandling(resData, null, resData.out_succeed === true && resData.out_data != null))
      )
      .subscribe(resData => {
        if (resData.out_succeed === true && resData.out_data != null) {

          let paymentDates: PaymentDateElement[] = resData.out_data;
          this._selectedDate = paymentDates[0];
          this._paymentDates.next(paymentDates);
        }
      });
  }

  private getAllPartnerOverallStats(partnerIdentifier: string): void {
    let requestParameters = new HttpParams();
    if(partnerIdentifier != null)
      requestParameters = requestParameters.append("identifier", partnerIdentifier);

    this._httpClient.get<ResponseData>(API.DASHBOARD_GET_ALL_PARTNER_OVERALL_STATS, {headers: this.privateHeader(), params: requestParameters })
    .pipe(
      catchError(this.handleUnexpectedError),
      tap(resData => this.defaultResponseHandling(resData, null, resData.out_succeed === true && resData.out_data != null))
      )
      .subscribe(resData => {
        if (resData.out_succeed === true && resData.out_data != null) {

          if(resData.out_data.overall_stats?.cards != null) {
            let numberCards: NameValueElement[] = resData.out_data.overall_stats.cards;
            this._numberCardsData.next(numberCards);
          }

          if(resData.out_data.overall_stats?.overall_revenue != null) {
            let overallRevenue: NameValueElement[] = resData.out_data.overall_stats.overall_revenue;
            if(API.DEBUG_MODE) 
            console.log("Overall Revenue: " + JSON.stringify(overallRevenue));
              
            this._overallRevenueData.next(overallRevenue);
          }

          if(resData.out_data.overall_stats?.overall_items != null) {
            let overallItems: NameValueElement[] = resData.out_data.overall_stats.overall_items;
            if(API.DEBUG_MODE) 
            console.log("Overall Items: " + JSON.stringify(overallItems));
            this._overallItemsData.next(overallItems);
          }
        }
      });
  }

  private getAllPartnerMonthlyStats(partnerIdentifier: string, paymentIdentifier: string): void {
    let requestParameters = new HttpParams();
    if(partnerIdentifier != null)
      requestParameters = requestParameters.append("par_identifier", partnerIdentifier);
    if(paymentIdentifier != null)
      requestParameters = requestParameters.append("pay_identifier", paymentIdentifier);

    this._httpClient.get<ResponseData>(API.DASHBOARD_GET_ALL_PARTNER_MONTHLY_STATS, {headers: this.privateHeader(), params: requestParameters})
    .pipe(
      catchError(this.handleUnexpectedError),
      tap(resData =>  this.defaultResponseHandling(resData, null, resData.out_succeed === true && resData.out_data != null))
      )
      .subscribe(resData => {

        if (resData.out_succeed === true && resData.out_data != null) {
          if(API.DEBUG_MODE) 
          console.log(JSON.stringify(resData.out_data));
        
          if(resData.out_data.monthly_stats?.country != null) {
            let countryStats: DataChartElement[] = resData.out_data.monthly_stats.country;
            this._countryData.next(countryStats);
          }

          if(resData.out_data.monthly_stats?.dsp != null) {
            let dspStats: DataChartElement[] = resData.out_data.monthly_stats.dsp;
            this._dspData.next(dspStats);
          }

          if(resData.out_data.monthly_stats?.type_of_sale != null) {
            let typeOfSaleStats: DataChartElement[] = resData.out_data.monthly_stats.type_of_sale;
            this._typeOfSaleData.next(typeOfSaleStats);
          }

          if(resData.out_data.monthly_stats?.label != null) {
            let labelStats: DataChartElement[] = resData.out_data.monthly_stats.label;
            this._labelData.next(labelStats);
          }

          if(resData.out_data.monthly_stats?.artist != null) {
            let artistStats: DataChartElement[] = resData.out_data.monthly_stats.artist;
            this._artistData.next(artistStats);
          }

          if(resData.out_data.monthly_stats?.asset != null) {
            let assetStats: DataChartElement[] = resData.out_data.monthly_stats.asset;
            this._assetData.next(assetStats);
          }

          if(resData.out_data.monthly_stats?.artist != null && resData.out_data.monthly_stats?.label != null) {
            let showChartsMap: Map<String, boolean> = new Map();
            showChartsMap.set('asset', true);
            showChartsMap.set('country', true);
            showChartsMap.set('dsp', true);
            showChartsMap.set('type_of_sale', true);
            showChartsMap.set('artist', resData.out_data.monthly_stats?.artist.length > 1);
            showChartsMap.set('label', resData.out_data.monthly_stats?.label.length > 1);
            
            this._showCharts.next(showChartsMap);
          }
        }
      });
  }



  getAllPartnerWithPayments(): void {
    this._httpClient.get<ResponseData>(API.DASHBOARD_GET_ALL_PARTNERS_WITH_PAYMENTS, {headers: this.privateHeader() })
    .pipe(
      catchError(this.handleUnexpectedError),
      tap(resData =>  this.defaultResponseHandling(resData, null, resData.out_succeed === true && resData.out_data != null))
      )
      .subscribe(resData => {
        if (resData.out_succeed === true && resData.out_data != null) {

          let partnersWithPayments: PartnerNameElement[] = resData.out_data;

          if(API.DEBUG_MODE) {
            console.log('PARTNERS --------------------------');
            console.log(partnersWithPayments);
          }
          

          if(this._selectedPartner != null && (this._selectedPartner.identifier == null || this._selectedPartner.identifier === '')) {
            let result = partnersWithPayments.filter(partnerWithPayment => partnerWithPayment.name === this._selectedPartner.name);
            if(result.length > 0) {
              this._selectedPartner = result[0];
            }
          }

          this._partnerNames.next(partnersWithPayments);
        }
      });
  }

  /*
  getAllManagementPartnerPaymentDates() {
    return this._httpClient.get<ResponseData>(API.DASHBOARD_GET_ALL_MANAGEMENT_PARTNER_PAYMENT_DATES, {headers: this.privateHeader() })
    .pipe(
      catchError(this.handleUnexpectedError),
      tap(resData =>  this.defaultResponseHandling(resData, null, resData.out_succeed === true && resData.out_data != null))
      );
  }

  getAllManagementPartnerOverallStats() {
    return this._httpClient.get<ResponseData>(API.DASHBOARD_GET_ALL_MANAGEMENT_PARTNER_OVERALL_STATS, {headers: this.privateHeader() })
    .pipe(
      catchError(this.handleUnexpectedError),
      tap(resData =>  this.defaultResponseHandling(resData, null, resData.out_succeed === true && resData.out_data != null))
      );
  }

  getAllManagementPartnerMonthlyStats() {
    return this._httpClient.get<ResponseData>(API.DASHBOARD_GET_ALL_MANAGEMENT_PARTNER_MONTHLY_STATS, {headers: this.privateHeader() })
    .pipe(
      catchError(this.handleUnexpectedError),
      tap(resData =>  this.defaultResponseHandling(resData, null, resData.out_succeed === true && resData.out_data != null))
      );
  }
*/


  private defaultResponseHandling(resData: ResponseData, successMessage: string, condition: boolean) {
    if (resData.out_succeed == true && (condition == null || (condition != null && condition))) {

      if(successMessage != null)
        this._snackBarComponent.successMessage(successMessage);
    } else {
      this.handleResponseWarning(resData);
    }

    if (resData.out_error == true) {
      this.handleResponseError(resData);
    }
  }


  private handleResponseWarning(resData: ResponseData) {
    
    if(resData.out_succeed === false) {

      switch (resData.out_msg) {
        case 'UNKNOWN_ERROR':
          this._snackBarComponent.warningMessage("An unexpected error occurred, please contact your system admin.");
          break;

        default:
          this._snackBarComponent.warningMessage("An unexpected error occurred, please contact your system admin.");
          break;
      }
    }
  }
  
  private handleResponseError(resData: ResponseData) {
        if(resData.out_error) {
        switch (resData.out_msg) {

          case 'UNAUTHORIZED':
            this._snackBarComponent.errorMessage("Unauthorized.");
            break;

          case 'UNKNOWN_ERROR':
            this._snackBarComponent.errorMessage("An unknown error occurred, please contact your system admin.");
            break;
        }
      }
  }

  private handleUnexpectedError(errorRes: HttpErrorResponse) {
    if(API.DEBUG_MODE) 
    console.log("Errors: " + JSON.stringify(errorRes));


    if (errorRes.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      if(API.DEBUG_MODE) 
      console.log('An error occurred:', errorRes.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      if(API.DEBUG_MODE) 
      console.log(
        `Backend returned code ${errorRes.status}, ` +
        `body was: ${errorRes.error}`);

        let errorMessage = 'An unknown error occurred!';
        if (!errorRes.error || !errorRes.error.error) {
          return throwError(errorMessage);
        }
    
        switch (errorRes.status) {
          case 400:
            this._snackBarComponent.errorMessage("A problem occurred, please contact your system admin.");
            break;

          case 401:
            this._snackBarComponent.errorMessage("Unauthorized.");
            break;

          case 500:
            this._snackBarComponent.errorMessage("An unknown error occurred, please contact your system admin.");
            break;

        }
        return throwError(errorMessage);
    }
  }

}