import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminMappingService } from '../admin-mapping.service';
import { SnackBarComponent } from 'src/app/components/snack-bar/snack-bar/snack-bar.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { AdminMappingElement } from '../admin-mappings.component';

export interface CreateAdminMappingDialogData {
  oldUpc: string;
  oldIsrc: string;
  newUpc: string;
  newIsrc: string;
}

@Component({
  selector: 'app-create-admin-mapping-dialog',
  templateUrl: './create-admin-mapping-dialog.component.html'
})
export class CreateAdminMappingDialog implements OnDestroy {

  private _subscriptions: Subscription[] = [];

  constructor(private _adminMappingService: AdminMappingService,
    private _snackBarComponent: SnackBarComponent,
    public _dialogRef: MatDialogRef<CreateAdminMappingDialog>,
    @Inject(MAT_DIALOG_DATA) public data: CreateAdminMappingDialogData) { }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onCancel(): void {
    this._dialogRef.close();
  }

  onCreate(): void {
    let containsUpc = this.data.oldUpc != null && this.data.oldUpc.trim() != '';
    let containsIsrc = this.data.oldIsrc != null && this.data.oldIsrc.trim() != '';

    if(!containsUpc && !containsIsrc) 
      this._snackBarComponent.errorMessage("At least one of the old fields must be specified.");

    else if (containsUpc && (this.data.newUpc === null || this.data.newUpc.trim() === ''))
    this._snackBarComponent.errorMessage("New upc must be specified.");

    else if (containsIsrc && (this.data.newIsrc === null || this.data.newIsrc.trim() === ''))
    this._snackBarComponent.errorMessage("New isrc must be specified.");

    else {

      let subscription: Subscription = this._adminMappingService.createMapping(this.data.oldUpc, this.data.oldIsrc, this.data.newUpc, this.data.newIsrc)
        .subscribe(resData => {

          if (resData.out_succeed === true && resData.out_data != null) {
            var mappingElement: AdminMappingElement = <AdminMappingElement>resData.out_data;
            this._dialogRef.close({ newMapping: mappingElement });
          }
        });

      this._subscriptions.push(subscription);
    }
  }
}
