import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth.service';
import { SnackBarComponent } from 'src/app/components/snack-bar/snack-bar/snack-bar.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { API } from 'src/app/api.component';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit, OnDestroy {

  private _subscriptions: Subscription[] = [];

  isLoading = false;
  status: number = 0;
  private _email = null;
  private _code = null;


  constructor(private _authService: AuthService, private _router: Router, private _snackBarComponent: SnackBarComponent) { }

  ngOnInit(): void {

    if(!this._authService.isAvailable()) {
      this._router.navigate(['/maintenance']);
    }

    /*
    let subscription: Subscription = this._authService.componentUserObservable.subscribe(user => {
      var isAuthenticated = !!user; // user ? true : false;
      console.log("login is authenticated? " + isAuthenticated);

      if (isAuthenticated) {
        if (user.authorityId == 1) {
          this._router.navigate(['/dashboard']);
        } else {
          this._router.navigate(['/admin/dashboard']);
        }
      }
    });


    this._subscriptions.push(subscription);
        */
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onCancel() {
    this.status = 0;
    this._email = null;
    this._code = null;
    this._router.navigate(['/login']);
  }

  onSubmit(form: NgForm) {

    if (!form.valid) {
      return;
    }

    this.isLoading = true;
    this._email = form.value.email;

    this._authService.forgot(form.value.email)
      .subscribe(resData => {

        if (resData.out_succeed === true) {
          this.status = 1;
        } 
        
        this.isLoading = false;

      }, error => {

        this.status = 0;
        this.isLoading = false;
      });

    form.reset();
  }

  onSubmitCode(form: NgForm) {

    if (!form.valid) {
      return;
    }

    this.isLoading = true;
    this._code = form.value.code;

    if(API.DEBUG_MODE) 
    console.log(this._code)

    this._authService.resetCode(this._email, this._code)
      .subscribe(resData => {
        if (resData.out_succeed === true) {
          this.status = 2;
        }

        this.isLoading = false;

      }, error => {

        this.status = 0;
        this.isLoading = false;
      });

    form.reset();
  }

  onSubmitNewPassword(form: NgForm) {

    if (!form.valid) {
      return;
    }

    let password1 = form.value.password1;
    let password2 = form.value.password2;

    if (password1 != password2) {
      this._snackBarComponent.errorMessage("New passwords don't match...");

    } else {

      this.isLoading = true;

      this._authService.reset(this._email, this._code, password1)
        .subscribe(resData => {

          if (resData.out_succeed === true) {
            this.onCancel();
          }
  
          this.isLoading = false;

        }, error => {
          
          this.status = 0;
          this.isLoading = false;
        });

      form.reset();
    }
  }
}