import { Timestamp } from 'rxjs';
import { API } from '../api.component';

export class User {

    constructor(
        private _identifier: string, 
        private _firstName: string, 
        private _lastName: string, 
        private _email: string, 
        private _authorityId: number,
        private _isBlocked: boolean,
        private _partner: string, 
        ) { 
            if(API.DEBUG_MODE) 
            console.log("USER: " + this._firstName + " " + this._lastName + " " + this._partner);
        }

    get identifier() {
        return this._identifier;
    }

    get firstName() {
        return this._firstName;
    }

    setFirstName(firstName: string) {
        this._firstName = firstName;
    }

    get lastName() {
        return this._lastName;
    }

    setLastName(lastName: string) {
        this._lastName = lastName;
    }

    get email() {
        return this._email;
    }

    setEmail(email: string) {
        this._email = email;
    }

    get authorityId() {
        return this._authorityId;
    }

    get isBlocked() {
        return this._isBlocked;
    }

    get partner() {
        return this._partner;
    }
}