import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';


import { ExportToCsv } from 'export-to-csv';
import { AdminFugaService } from './admin-fuga.service';
import { SnackBarComponent } from 'src/app/components/snack-bar/snack-bar/snack-bar.component';
import { AuthService } from 'src/app/auth/auth.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { API } from 'src/app/api.component';

export interface AdminFugaElement {
  status: string;
  statusId: number;
  labels: number;
  artists: number;
  products: number;
  productAssets: number;
  assets: number;
  start: string;
  finish: string;
  createdBy: string;
  createdOn: string;
  modifiedBy: string;
  modifiedOn: string;
}


@Component({
  selector: 'app-admin-fuga',
  templateUrl: './admin-fuga.component.html',
  styleUrls: ['./admin-fuga.component.scss']
})
export class AdminFugaComponent implements OnInit, AfterViewInit, OnDestroy {

  private _subscriptions: Subscription[] = [];

  isLoading = false;
  private _isDownloading = false;

  showMetadata = false;
  private _adminShowMetadataColumns = ['status', 'labels', 'artists', 'products', 'productAssets', 'assets', 'start', 'finish', 'createdBy', 'createdOn', 'modifiedBy', 'modifiedOn'];
  private _adminHideMetadataColumns = ['status', 'labels', 'artists', 'products', 'productAssets', 'assets', 'start', 'finish'];
  private _moderatorHideMetadataColumns = ['status', 'labels', 'artists', 'products', 'productAssets', 'assets', 'start', 'finish'];
  displayedColumns: string[];

  dataSource = new MatTableDataSource<AdminFugaComponent>();
  private _data: AdminFugaComponent[] = [];

  private _isAdmin: boolean = false;

  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private _authService: AuthService, private _adminFugaService: AdminFugaService, private _snackBarComponent: SnackBarComponent) {
    this._isAdmin = this._authService.isAdmin();
    this.displayedColumns = this._isAdmin ? this._adminHideMetadataColumns : this._moderatorHideMetadataColumns;
  }

  get isDownloading() {
    return this._isDownloading;
  }

  get isAdmin(): boolean {
    return this._isAdmin;
  }

  ngOnInit() {
    this.dataSource.data = this._data;

    this._subscriptions.push(this.isDownloadingStatus());
    this._subscriptions.push(this.loadAllFugaDetails());
  }

  private isDownloadingStatus(): Subscription {
    return this._adminFugaService.isDownloadingObservable
    .subscribe(isDownloading => this._isDownloading = isDownloading)
  }

  private loadAllFugaDetails(): Subscription {
    return this._adminFugaService.getFuga()
    .subscribe(resData => {
      if (resData.out_succeed === true && resData.out_data != null) {
        this._data = <AdminFugaComponent[]>resData.out_data;
        this.dataSource.data = this._data;
      }
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  doFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onCreate(): void {
    if (!this.isAdmin)
      return;
    
    this._adminFugaService.uploadFuga()
    .subscribe(resData => {
      if(API.DEBUG_MODE)
        console.log("Recebi 2 " + JSON.stringify(resData.out_data));
        if (resData.out_succeed === true && resData.out_data != null) {
          
            this._data = <AdminFugaComponent[]>resData.out_data;
            this.dataSource.data = this._data;
          }
      });
  }

  onMetadata(): void {

    if (!this.isAdmin)
      return;

    this.showMetadata = !this.showMetadata;

    if (this.showMetadata) {
      this.displayedColumns = this._adminShowMetadataColumns;

    } else {
      this.displayedColumns = this._adminHideMetadataColumns;
    }
  }

  onDownload(): void {
    
    if (!this.isAdmin)
      return;

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Admin FUGA Snapshot CSV',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      headers: this.displayedColumns
  };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(this.dataSource.data);
  }
}
