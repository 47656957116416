import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { DashboardService } from 'src/app/user/dashboard/dashboard.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { API } from 'src/app/api.component';

/*
 var multi = [
  {
    "name": "Germany",
    "series": [
      {
        "name": "Jan",
        "value": 62000000
      },
      {
        "name": "Feb",
        "value": 7300000
      },
      {
        "name": "Mar",
        "value": 89400000
      },
      {
        "name": "Abr",
        "value": 6200000
      },
      {
        "name": "May",
        "value": 73000000
      },
      {
        "name": "Jun",
        "value": 89400000
      },
      {
        "name": "Jul",
        "value": 6200000
      },
      {
        "name": "Aug",
        "value": 73000000
      },
      {
        "name": "Sep",
        "value": 894000
      },
      {
        "name": "Oct",
        "value": 89400000
      },
      {
        "name": "Nov",
        "value": 8940000
      },
      {
        "name": "Dec",
        "value": 89400000
      }
    ]
  }
];
*/

@Component({
  selector: 'app-revenue-chart',
  templateUrl: './revenue-chart.component.html',
  styleUrls: ['./revenue-chart.component.scss']
})
export class RevenueChartComponent implements OnInit, OnDestroy {

  private _subscriptions: Subscription[] = [];
  view: any[];
  multi: any[] = [];

  // options
  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Month';
  yAxisLabel: string = 'Revenue [€]';
  timeline: boolean = true;
  gradient: boolean = true;


  colorScheme = {
    domain: ['#5AA454']
  };

  constructor(private _dashboardService: DashboardService) {
  }

  ngOnInit(): void {
    let subscription: Subscription = this._dashboardService.overallRevenueDataObservable
    .subscribe(data => this.multi = [{
                        "name": "Overall Revenue",
                        "series": data
                      }]);

    this._subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onSelect(event) {
    if(API.DEBUG_MODE) 
    console.log(event);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.view = null;
  }
}
