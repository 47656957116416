
<!-- (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()" -->
<div *ngIf="isAuthenticated">


      
        <mat-accordion fxLayout="column" fxLayoutAlign="flex-start" displayMode="flat" multi="true">
            <mat-expansion-panel *ngIf="isPartnerAccount" expanded="true" >
              <mat-expansion-panel-header>
                <mat-panel-title>
                    <h2 style="margin: 0;">  Partner </h2>

                </mat-panel-title>
                <mat-divider></mat-divider>
                
              </mat-expansion-panel-header>
             

              <mat-nav-list>
    
                <a mat-list-item routerLink="/dashboard">
                    <mat-icon>dashboard</mat-icon>
                    <span class="nav-caption" *ngIf="isExpanded || isShowing">Dashboard</span>
                </a>
    
                <a mat-list-item routerLink="/payments">
                    <mat-icon>payment</mat-icon>
                    <span class="nav-caption" *ngIf="isExpanded || isShowing">Payments</span>
                </a>
    
            </mat-nav-list>
       

              
            </mat-expansion-panel>

            <mat-expansion-panel *ngIf="isAdmin || isModerator" expanded="true">
              <mat-expansion-panel-header>
                <mat-panel-title >
                   
                    <h2 style="margin: 0;">  Management </h2>
                </mat-panel-title>
                <mat-divider></mat-divider>

              </mat-expansion-panel-header>
             
              <mat-nav-list >
                <a mat-list-item routerLink="/admin/accounts">
                    <mat-icon>group</mat-icon>
                    <span class="nav-caption" *ngIf="isExpanded || isShowing">Accounts</span>
                </a>
    
                <a mat-list-item routerLink="/admin/partners">
                    <mat-icon>group_work</mat-icon>
                    <span class="nav-caption" *ngIf="isExpanded || isShowing">Partners</span>
                </a>

                <a mat-list-item routerLink="/admin/subpartners">
                    <mat-icon>account_tree</mat-icon>
                    <span class="nav-caption" *ngIf="isExpanded || isShowing">SubPartners</span>
                </a>
    
                <a mat-list-item routerLink="/admin/labels">
                    <mat-icon>album</mat-icon>
                    <span class="nav-caption" *ngIf="isExpanded || isShowing">Labels</span>
                </a>

                <a mat-list-item routerLink="/admin/artists">
                    <mat-icon>record_voice_over</mat-icon>
                    <span class="nav-caption" *ngIf="isExpanded || isShowing">Artists</span>
                </a>
    
                <a mat-list-item routerLink="/admin/payments">
                    <mat-icon>payment</mat-icon>
                    <span class="nav-caption" *ngIf="isExpanded || isShowing">Payments</span>
                </a>
    
                <a mat-list-item routerLink="/admin/fuga">
                    <mat-icon>storage</mat-icon>
                    <span class="nav-caption" *ngIf="isExpanded || isShowing">Fuga Music</span>
                </a>

                <a mat-list-item routerLink="/admin/mappings">
                    <mat-icon>sync_alt</mat-icon>
                    <span class="nav-caption" *ngIf="isExpanded || isShowing">Mappings</span>
                </a>
    
                <a mat-list-item routerLink="/admin/reports">
                    <mat-icon>receipt</mat-icon>
                    <span class="nav-caption" *ngIf="isExpanded || isShowing">Reports</span>
                </a>
    
                <!--
                <a mat-list-item routerLink="/admin/settings" *ngIf="isAdmin">
                    <mat-icon>settings</mat-icon>
                    <span class="nav-caption" *ngIf="isExpanded || isShowing">Settings</span>
                </a>
                -->
    
    
            </mat-nav-list>


            </mat-expansion-panel>
          </mat-accordion>








   

        <!-- 
    </div>
-->


</div>