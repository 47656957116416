import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-unavailable',
  templateUrl: './unavailable.component.html',
  styleUrls: ['./unavailable.component.scss']
})
export class UnavailableComponent implements OnInit, OnDestroy {

  showMessage = false;
  private _timer: any;

  constructor() { }
 

  ngOnInit(): void {
    this._timer = setTimeout(() => {
      this.showMessage = true;
    }, 
    
    10000);
  }

  ngOnDestroy(): void {
    if (this._timer) {
      clearTimeout(this._timer);
    }
    this._timer = null;
  }

}
