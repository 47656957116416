<section class="center">

    <div >
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="isLoading">
            <app-loading-spinner></app-loading-spinner>
        </div>
 
        <div class="mat-elevation-z8 mid" *ngIf="!isLoading"> 
            <form fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px" #f0="ngForm" (ngSubmit)="onSubmit(f0)" *ngIf="!isLoading && status == 0">
                <h1>Forgot your password?</h1>
                <h3 class="margin-top">Don't worry, reseting your password is easy.</h3>
                <mat-divider></mat-divider>
                
                <mat-form-field class="expand-width margin-top">
                    <input matInput type="email" placeholder="Your email" ngModel name="email" email required #emailInput="ngModel">

                    <mat-error *ngIf="!emailInput.hasError('required')">Invalid email!</mat-error>
                    <mat-error *ngIf="emailInput.hasError('required')">Field can't be empty!</mat-error>
                </mat-form-field>
    
                <div class="margin-top" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center">

                    <button mat-raised-button class="expand-half-width" (click)="onCancel()" type="button" 
                    style="background-color: black; color: white;">Cancel</button>
                    <button mat-raised-button class="expand-half-width" type="submit" color="primary" [disabled]="f0.invalid">Submit</button>
                </div>

            </form>


            <form fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px" #f1="ngForm" (ngSubmit)="onSubmitCode(f1)" *ngIf="!isLoading && status == 1">
                <h1>Reset Code</h1>
                <h3 class="margin-top">The reset code was sent to your email.</h3>
                
                <mat-form-field class="expand-width margin-top">
                    <input matInput type="text" placeholder="Your reset code" ngModel name="code" required minlength="4" maxlength="4" #codeInput="ngModel">

                    <mat-error *ngIf="codeInput.hasError('required')">Field can't be empty!</mat-error>
                    <mat-error *ngIf="!codeInput.hasError('required') && codeInput.errors?.minlength">Should be at least 4
                        digits long.</mat-error>
                </mat-form-field>
    
                <div class="margin-top" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center">

                    <button mat-raised-button class="expand-half-width" (click)="onCancel()" type="button" 
                    style="background-color: black; color: white;">Cancel</button>
                    <button mat-raised-button class="expand-half-width" type="submit" color="primary" [disabled]="f1.invalid">Submit</button>
                </div>

            </form>



            <form fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px" #f2="ngForm" (ngSubmit)="onSubmitNewPassword(f2)" *ngIf="!isLoading && status == 2">
                <h1>Reset Password</h1>
                <h3 class="margin-top">Please enter a new password.</h3>
                
                <mat-form-field class="expand-width margin-top" hintLabel="Should be at least 8 characters long.">
                    <input matInput type="password" placeholder="Your password" ngModel name="password1" required minlength="8" #pw1Input="ngModel">
                    <mat-error *ngIf="pw1Input.hasError('required')">Field can't be empty!</mat-error>
                    <mat-error *ngIf="!pw1Input.hasError('required') && pw1Input.errors?.minlength">Should be at least 8
                        characters long.</mat-error>
                </mat-form-field>

                
                <mat-form-field class="expand-width margin-top" hintLabel="Should be at least 8 characters long.">
                    <input matInput type="password" placeholder="Your password" ngModel name="password2" required minlength="8" #pw2Input="ngModel">
                    <mat-error *ngIf="pw2Input.value != ''">Passwords don't match!</mat-error>
                </mat-form-field>
    
                <div class="margin-top" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center">

                    <button mat-raised-button class="expand-half-width" (click)="onCancel()" type="button" 
                    style="background-color: black; color: white;">Cancel</button>
                    <button mat-raised-button class="expand-half-width" type="submit" color="primary" [disabled]="f2.invalid">Submit</button>
                </div>

            </form>
        </div>


    </div>


</section>