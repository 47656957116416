import { Component, OnInit, EventEmitter, Output, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { API } from 'src/app/api.component';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {


  @Output() sidenavToggle = new EventEmitter<void>();
  isAuthenticated: boolean = false;
  firstName: String;
  lastName: String;
  partner: String;
  

  constructor(private authService: AuthService, private router: Router, private changeDetector: ChangeDetectorRef) {}

  ngOnInit() {
    this.authService.componentUserObservable.subscribe(user => {
      this.isAuthenticated = !!user; // user ? true : false;
      if(API.DEBUG_MODE) {
        console.log("toolbar: is authenticated? " + this.isAuthenticated);
        console.log("toolbar user: " + JSON.stringify(user));
      }

      if(this.isAuthenticated) {
        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.partner = user.partner;
        if(API.DEBUG_MODE) 
        console.log("Alterei: " + this.firstName + " " + this.lastName + " " + this.partner);
        this.changeDetector.detectChanges();
      }
    });
  }

  onToggleSidenav() {
    this.sidenavToggle.emit();
    window.dispatchEvent(new Event('resize'));
  }

  onLogout() {
    this.authService.logout().subscribe();
  }

  onBrandClick() {
    this.router.navigate(['/']);
  }


}
