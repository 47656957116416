<h1>MANAGEMENT / ARTISTS</h1>

<div>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="isLoading">
        <app-loading-spinner></app-loading-spinner>
    </div>

    <div>
        <div fxLayout fxLayoutGap="10px" fxLayoutAlign="space-between">
            <div *ngIf="!isLoading">

            </div>

            <div fxLayoutGap="10px" *ngIf="!isLoading && isAdmin">
                <button mat-raised-button color="primary" (click)="onMetadata()">
                    <mat-icon>view_column</mat-icon> Metadata
                </button>
                <button mat-raised-button color="primary" (click)="onDownload()">
                    <mat-icon>get_app</mat-icon> Export Data
                </button>
            </div>

        </div>

        <div fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="!isLoading">
            <mat-form-field fxFlex="40%">
                <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter">
            </mat-form-field>
        </div>

        

        <mat-table matSort fxLayout="column" fxLayoutAlign="center" fxLayoutGap="10px" [dataSource]="dataSource"
            class="mat-elevation-z8" *ngIf="!isLoading">

            <ng-container matColumnDef="identifier">
                <mat-header-cell *matHeaderCellDef mat-sort-header="identifier">Identifier</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.identifier }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="fugaName">
                <mat-header-cell *matHeaderCellDef mat-sort-header="fugaName">FUGA Name</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.fugaName }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="fugaId">
                <mat-header-cell *matHeaderCellDef mat-sort-header="fugaId">FUGA ID</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.fugaId }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="createdBy">
                <mat-header-cell *matHeaderCellDef mat-sort-header="createdBy">Created By</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.createdBy }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="createdOn">
                <mat-header-cell *matHeaderCellDef mat-sort-header="createdOn">Created On</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.createdOn }}</mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="modifiedBy">
                <mat-header-cell *matHeaderCellDef mat-sort-header="modifiedBy">Modified By</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.modifiedBy }}</mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="modifiedOn">
                <mat-header-cell *matHeaderCellDef mat-sort-header="modifiedOn">Modified On</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.modifiedOn }}</mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row [ngClass]="{'table-row-make-red': (editingElement == null ? false : row.identifier===editingElement.identifier)}" *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <mat-paginator class="mat-elevation-z8" [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 75, 100, 250]" *ngIf="!isLoading">

        </mat-paginator>
    </div>
</div>