<h1>PARTNER / PAYMENTS</h1>

<div>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="isLoading">
        <app-loading-spinner></app-loading-spinner>
    </div>

    <div>

        <div class="flex-container-full" fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="10px" fxWrap="wrap" *ngIf="!isLoading">
        
            <ngx-charts-number-card
              [view]="view"
              [scheme]="colorScheme"
              [results]="single"
              [cardColor]="cardColor"
              >
        
            </ngx-charts-number-card>
          </div>

        <div fxLayout fxLayoutGap="10px" fxLayoutAlign="flex-end">

            <div fxLayoutGap="10px" *ngIf="!isLoading">
                <button mat-raised-button color="primary" (click)="onDownload()">
                    <mat-icon>get_app</mat-icon> Export Data
                </button>
            </div>

        </div>

        <div fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="!isLoading">
            <mat-form-field fxFlex="40%">
                <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter">
            </mat-form-field>
        </div>


        <mat-progress-bar mode="query" *ngIf="isDownloading"></mat-progress-bar>
        <mat-table matSort fxLayout="column" fxLayoutAlign="center" fxLayoutGap="10px" [dataSource]="dataSource"
            class="mat-elevation-z8" *ngIf="!isLoading">

            <ng-container matColumnDef="partner">
                <mat-header-cell *matHeaderCellDef mat-sort-header="partner">Partner</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.partner }}</mat-cell>
                <mat-footer-cell *matFooterCellDef>Total:</mat-footer-cell>
            </ng-container>
            
            <ng-container matColumnDef="month">
                <mat-header-cell *matHeaderCellDef mat-sort-header="month">Month</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.month }}</mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="year">
                <mat-header-cell *matHeaderCellDef mat-sort-header="year">Year</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.year }}</mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="items">
                <mat-header-cell *matHeaderCellDef mat-sort-header="items">Items</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.items }} </mat-cell>
                <mat-footer-cell *matFooterCellDef> {{ getTotalItems() }} </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="revenue">
                <mat-header-cell *matHeaderCellDef mat-sort-header="revenue">Gross Revenue</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.revenue }} € </mat-cell>
                <mat-footer-cell *matFooterCellDef> {{ getTotalRevenue() }} €</mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="royalty">
                <mat-header-cell *matHeaderCellDef mat-sort-header="royalty">Royalty</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.royalty }} % </mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="net">
                <mat-header-cell *matHeaderCellDef mat-sort-header="net">Net Revenue</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.net }} € </mat-cell>
                <mat-footer-cell *matFooterCellDef> {{ getTotalNet() }} €</mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef mat-sort-header="type">Type</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.type }}</mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef mat-sort-header="status">Status</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.status }}</mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="publishedOn">
                <mat-header-cell *matHeaderCellDef mat-sort-header="publishedOn">Published On</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.publishedOn }}</mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <div>
                    <a (click)="onDownloadData(element)">
                        <mat-icon>get_app</mat-icon>
                    </a>
                </div>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>


            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            <mat-footer-row class="table-row-make-black" *matFooterRowDef="displayedColumns; sticky: true"></mat-footer-row>
        </mat-table>

        <mat-paginator class="mat-elevation-z8" [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 75, 100, 250]" *ngIf="!isLoading">

        </mat-paginator>
    </div>
</div>