<h1>MANAGEMENT / REPORTS</h1>

<div>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="isLoading">
        <app-loading-spinner></app-loading-spinner>
    </div>

    <div>
        <div fxLayout fxLayoutGap="10px" fxLayoutAlign="space-between">
            <div *ngIf="!isLoading && isAdmin">

                <button mat-raised-button color="primary" (click)="onCreate()">
                    <mat-icon>add</mat-icon> Create New
                </button>

            </div>

            <div fxLayoutGap="10px" *ngIf="!isLoading && isAdmin">
                <button mat-raised-button color="primary" (click)="onMetadata()">
                    <mat-icon>view_column</mat-icon> Metadata
                </button>
                <button mat-raised-button color="primary" (click)="onDownload()">
                    <mat-icon>get_app</mat-icon> Export Data
                </button>
            </div>

        </div>

        <div fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="!isLoading">
            <mat-form-field fxFlex="40%">
                <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter">
            </mat-form-field>
        </div>


        <mat-progress-bar mode="query" *ngIf="downloadingFiles > 0"></mat-progress-bar>
        <mat-table matSort fxLayout="column" fxLayoutAlign="center" fxLayoutGap="10px" [dataSource]="dataSource"
            class="mat-elevation-z8" *ngIf="!isLoading">
            
            <ng-container matColumnDef="identifier">
                <mat-header-cell *matHeaderCellDef mat-sort-header="identifier">Identifier</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.identifier }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef mat-sort-header="status">Status</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.status }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="month">
                <mat-header-cell *matHeaderCellDef mat-sort-header="month">Month</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.month }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="year">
                <mat-header-cell *matHeaderCellDef mat-sort-header="year">Year</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.year }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="fileName">
                <mat-header-cell *matHeaderCellDef mat-sort-header="fileName">File Name</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.fileName }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="fileRows">
                <mat-header-cell *matHeaderCellDef mat-sort-header="fileRows">File Rows</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.fileRows }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="importedRows">
                <mat-header-cell *matHeaderCellDef mat-sort-header="importedRows">Imported Rows</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.importedRows }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="importDetails">
                <mat-header-cell *matHeaderCellDef mat-sort-header="importDetails">Imported Details</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.importDetails }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="start">
                <mat-header-cell *matHeaderCellDef mat-sort-header="start">Start</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.start }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="finish">
                <mat-header-cell *matHeaderCellDef mat-sort-header="finish">Finish</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.finish }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="fileVersion">
                <mat-header-cell *matHeaderCellDef mat-sort-header="fileVersion">Version</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.fileVersion }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="createdBy">
                <mat-header-cell *matHeaderCellDef mat-sort-header="createdBy">Created By</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.createdBy }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="createdOn">
                <mat-header-cell *matHeaderCellDef mat-sort-header="createdOn">Created On</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.createdOn }}</mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="modifiedBy">
                <mat-header-cell *matHeaderCellDef mat-sort-header="modifiedBy">Modified By</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.modifiedBy }}</mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="modifiedOn">
                <mat-header-cell *matHeaderCellDef mat-sort-header="modifiedOn">Modified On</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.modifiedOn }}</mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
                <mat-cell *matCellDef="let element">

                <div>
                    <a (click)="onDisplayDetails(element)" *ngIf="element.statusId == 2">
                        <mat-icon>article</mat-icon>
                    </a>

                    <a (click)="onDownloadData(element)" *ngIf="element.statusId != 2 && element.statusId != 10">
                        <mat-icon>get_app</mat-icon>
                    </a>

                    <a (click)="onEdit(element)" *ngIf="isAdmin && element.statusId == 9">
                        <mat-icon>visibility</mat-icon>
                    </a>

                    <a (click)="onEdit(element)" *ngIf="isAdmin && (element.statusId != 2 && element.statusId != 9 && element.statusId != 10)">
                        <mat-icon>edit</mat-icon>
                    </a>
                    <a (click)="onDelete(element)" *ngIf="isAdmin && (element.statusId != 9)">
                        <mat-icon>delete</mat-icon>
                    </a>

                </div>

                </mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row 
                *matRowDef="let row; columns: displayedColumns;"

                [ngClass]="{'mat-green-border-row': (row.statusId == 9),  'mat-red-border-row': (row.statusId == 2 || row.statusId == 10), 'mat-yellow-border-row': (row.statusId == 1 || (row.statusId > 2 && row.statusId < 9)) } "
            
            
            ></mat-row>
        </mat-table>

        <mat-paginator class="mat-elevation-z8" [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 75, 100, 250]"  *ngIf="!isLoading">

        </mat-paginator>
    </div>
</div>