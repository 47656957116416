import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Auth
import { LoginComponent } from './auth/login/login.component';
import { ForgotComponent } from './auth/forgot/forgot.component';
import { ChangeComponent } from './auth/change/change.component';

// Auth Guards
import { AuthGuard } from './auth/guard-auth.guard';
import { AuthPartnerGuard } from './auth/guard-auth-partner.guard';
import { AuthAdminGuard } from './auth/guard-auth-admin.guard';

// User
import { DashboardComponent } from './user/dashboard/dashboard.component';
import { PaymentsComponent } from './user/payments/payments.component';

// Admin
import { AdminAccountsComponent } from './admin/admin-accounts/admin-accounts.component';
import { AdminPartnersComponent } from './admin/admin-partners/admin-partners.component';
import { AdminLabelsComponent } from './admin/admin-labels/admin-labels.component';
import { AdminPaymentsComponent } from './admin/admin-payments/admin-payments.component';
import { AdminReportsComponent } from './admin/admin-reports/admin-reports.component';
import { AdminFugaComponent } from './admin/admin-fuga/admin-fuga.component';
import { AdminNewReportComponent } from './admin/admin-reports/admin-new-report/admin-new-report.component';
import { AdminArtistsComponent } from './admin/admin-artists/admin-artists.component';
import { AdminSubPartnersComponent } from './admin/admin-sub-partners/admin-sub-partners.component';
import { ProfileComponent } from './auth/profile/profile.component';
import { UnavailableComponent } from './components/unavailable/unavailable.component';
import { AdminMappingsComponent } from './admin/admin-mappings/admin-mappings.component';


const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },

  // --------------- NO LOGIN REQUIRED -------------------
  { path: 'login', component: LoginComponent },
  { path: 'forgot', component: ForgotComponent },
  { path: 'maintenance', component: UnavailableComponent },

  // --------------- LOGIN REQUIRED -------------------
  { path: 'change', component: ChangeComponent, canActivate: [AuthGuard] },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },

  // --------------- PARTNER LOGIN REQUIRED -------------------
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthPartnerGuard] },
  { path: 'payments', component: PaymentsComponent, canActivate: [AuthPartnerGuard] },

  // --------------- ADMIN LOGIN REQUIRED -------------------
  {
    path: 'admin', canActivate: [AuthAdminGuard],
    children: [
      { path: 'accounts', component: AdminAccountsComponent },
      { path: 'partners', component: AdminPartnersComponent },
      { path: 'subpartners', component: AdminSubPartnersComponent },
      { path: 'labels', component: AdminLabelsComponent },
      { path: 'artists', component: AdminArtistsComponent },
      { path: 'payments', component: AdminPaymentsComponent },
      { path: 'fuga', component: AdminFugaComponent },
      { path: 'mappings', component: AdminMappingsComponent },
      { path: 'reports', component: AdminReportsComponent },
      { path: 'reports/:reportStatusId/:reportIdentifier', component: AdminNewReportComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }