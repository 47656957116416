import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';

import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
//import {default as _rollupMoment, Moment} from 'moment';
import { Moment } from 'moment';
import { MatStepper } from '@angular/material/stepper';
import { AdminReportService } from '../admin-reports.service';
import { AdminFugaService } from '../../admin-fuga/admin-fuga.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { SnackBarComponent } from 'src/app/components/snack-bar/snack-bar/snack-bar.component';
import { MatSort } from '@angular/material/sort';

import { ExportToCsv } from 'export-to-csv';
import { AuthService } from 'src/app/auth/auth.service';
import { API } from 'src/app/api.component';

//const moment = _rollupMoment || _moment;
const moment = _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface AdminReportMatchElement {
  label: string;
  upc_code: string;
  isrc_code: string;
  total_items: number;
  total_revenue: number;
  fuga_product_id: number;
  fuga_product_upc: string;
  message: string;
}

export interface AdminReportMatchGlobalElement {
  label: string;
  totalItems: number;
  totalRevenue: number;
}

export interface AdminReportFugaElement {
  status: string;
  statusId: number;
  labels: number;
  artists: number;
  products: number;
  productAssets: number;
  start: string;
  finish: string;
  createdBy: string;
  createdOn: string;
  modifiedBy: string;
  modifiedOn: string;
}

export interface AdminPaymentElement {
  identifier: string;
  items: number;
  revenue: number;
  royalty: number;
  net: number;
  month: number;
  year: number;
  partner: string;
  partnerIdentifier: string;
  type: string;
  typeId: number;
  status: string;
  statusId: number;
  reportId: number;
  publishedOn: string;
  createdBy: string;
  createdOn: string;
  modifiedBy: string;
  modifiedOn: string;
}

@Component({
  selector: 'app-admin-new-report',
  templateUrl: './admin-new-report.component.html',
  styleUrls: ['./admin-new-report.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AdminNewReportComponent implements OnInit {
  matchGlobalData: AdminReportMatchGlobalElement[] = [];
  matchGlobalDisplayedColumns = ['label', 'totalItems', 'totalRevenue'];
  matchGlobalDataSource =
    new MatTableDataSource<AdminReportMatchGlobalElement>();

  matchData: AdminReportMatchElement[] = [];
  matchDisplayedColumns = [
    'label',
    'upc_code',
    'isrc_code',
    'total_items',
    'total_revenue',
    'fuga_product_id',
    'fuga_product_upc',
    'error_message',
    'was_mapped'
  ];
  matchDataSource = new MatTableDataSource<AdminReportMatchElement>();

  fugaData: AdminReportFugaElement[] = [];
  fugaDisplayedColumns = [
    'status',
    'labels',
    'artists',
    'products',
    'productAssets',
    'start',
    'finish',
    'createdBy',
    'createdOn',
  ];
  fugaDataSource = new MatTableDataSource<AdminReportFugaElement>();

  paymentsData: AdminPaymentElement[] = [];
  paymentsDisplayedColumns = [
    'year',
    'month',
    'partner',
    'items',
    'gross_revenue',  // gross_revenue
    'royalty',
    'net_revenue',      // net_revenue
    'type',
    'status',
    'publishedOn',
  ];
  paymentsDataSource = new MatTableDataSource<AdminPaymentElement>();

  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.matchDataSource.sort = sort;
  }

  @ViewChild('stepper') private stepper: MatStepper;

  date = new FormControl(moment());

  sending: boolean = false;

  private fileToUpload: File = null;
  private month: number;
  private year: number;

  reportIdentifier: string;
  reportStatusId: number;

  isLinear = false;
  isCompleted = false;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  fifthFormGroup: FormGroup;
  sixthFormGroup: FormGroup;
  seventhFormGroup: FormGroup;

  private _isAdmin: boolean = false;

  constructor(
    private _authService: AuthService,
    private _formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private adminReportService: AdminReportService,
    private adminFugaService: AdminFugaService,
    private snackBarComponent: SnackBarComponent
  ) {
    this._isAdmin = this._authService.isAdmin();
  }

  get isAdmin() {
    return this._isAdmin;
  }

  lowerThan(v1: number, v2: number): boolean {
    return v1 < v2;
  }

  biggerThan(v1: number, v2: number): boolean {
    return v1 > v2;
  }

  ngOnInit(): void {
    this.reportIdentifier = this.route.snapshot.params['reportIdentifier'];
    this.reportStatusId = this.route.snapshot.params['reportStatusId'];

    this.matchDataSource.data = this.matchData;

    this.adminFugaService.getFuga().subscribe((resData) => {
      if (resData.out_succeed === true && resData.out_data != null) {
        this.fugaData = <AdminReportFugaElement[]>resData.out_data;
        this.fugaDataSource.data = this.fugaData;
      }
    });

    if (this.reportIdentifier === 'new') {
      this.reportIdentifier = null;
      if(API.DEBUG_MODE) 
      console.log('Create Mode');
    } else {
      if(API.DEBUG_MODE) 
      console.log('Edit Mode ' + this.reportIdentifier);

      if (this.reportStatusId >= 4) {
        this.adminReportService
          .getMissingMatchesGlobalDetails(this.reportIdentifier)
          .subscribe((resData) => {
            if (resData.out_succeed === true && resData.out_data != null) {
              if(API.DEBUG_MODE) 
              console.log(JSON.stringify(resData.out_data));
              this.matchGlobalData = <AdminReportMatchGlobalElement[]>(
                resData.out_data
              );
              this.matchGlobalDataSource.data = this.matchGlobalData;
            }
          });

        this.adminReportService
          .getMissingMatchesDetails(this.reportIdentifier)
          .subscribe((resData) => {
            if (resData.out_succeed === true && resData.out_data != null) {
              if(API.DEBUG_MODE) 
              console.log(JSON.stringify(resData.out_data));
              this.matchData = <AdminReportMatchElement[]>resData.out_data;
              this.matchDataSource.data = this.matchData;
            }
          });

        this.adminReportService
          .getAllReportsPayments(this.reportIdentifier)
          .subscribe((resData) => {
            if (resData.out_succeed === true && resData.out_data != null) {
              if(API.DEBUG_MODE) 
              console.log('PAYMENTS: ' + JSON.stringify(resData.out_data));
              this.paymentsData = <AdminPaymentElement[]>resData.out_data;
              this.paymentsDataSource.data = this.paymentsData;
            }
          });
      }
    }

    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required],
      firstCtrl2: ['', Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required],
    });
    this.thirdFormGroup = this._formBuilder.group({
      thirdCtrl: ['', Validators.required],
    });

    this.fourthFormGroup = this._formBuilder.group({
      fourthCtrl: ['', Validators.required],
    });

    this.seventhFormGroup = this._formBuilder.group({
      seventhCtrl: ['', Validators.required],
    });
  }

  chosenYearHandler(normalizedYear: Moment): void {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
    this.year = normalizedYear.year();
    if(API.DEBUG_MODE) 
    console.log('Year Changed: ' + normalizedYear.year());
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ): void {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.month = normalizedMonth.month() + 1;
    if(API.DEBUG_MODE) 
    console.log('Month Changed: ' + normalizedMonth.month() + 1);
  }

  handleFileInput(files: FileList): void {
    this.fileToUpload = files.item(0);
  }

  myFilter = (m: Moment | null): boolean => {
    const selectedDate = m.toDate();
    const currentDate = new Date();
    // Prevent Saturday and Sunday from being selected.
    //console.log(m.toDate() + " " + m.year() + " " + m.month());

    if (selectedDate.getFullYear() !== currentDate.getFullYear())
      return selectedDate.getFullYear() <= currentDate.getFullYear();
    else if (selectedDate.getMonth() !== currentDate.getMonth())
      return selectedDate.getMonth() <= currentDate.getMonth();
  };

  cancel(stepper: MatStepper): void {
    this.router.navigate(['/admin/reports']);
  }

  finish(stepper: MatStepper): void {
    this.router.navigate(['/admin/reports']);
  }

  continue(stepper: MatStepper): void {
    stepper.next();
  }

  uploadFile(stepper: MatStepper): void {
    const formData: FormData = new FormData();
    formData.append('fileKey', this.fileToUpload, this.fileToUpload.name);

    this.sending = true;
    this.adminReportService
      .uploadReport(formData, this.year, this.month)
      .subscribe(
        (resData) => {
          if (resData.out_succeed === true && resData.out_data != null) {
            this.reportIdentifier = resData.out_data[0].identifier;
            stepper.next();
          }
          this.sending = false;
        },
        (error) => {
          setTimeout(() => {
            this.sending = false;
            this.router.navigate(['/admin/reports']);
          }, 1000);
        }
      );
  }

  uploadFuga(stepper: MatStepper): void {
    this.sending = true;
    this.adminFugaService.uploadFuga().subscribe((resData) => {
      this.sending = false;
      if (resData.out_succeed === true && resData.out_data != null) {
        if(API.DEBUG_MODE) 
        console.log('Fuga criado com sucesso!\n' + JSON.stringify(resData));
        stepper.next();
      }
    });
  }

  match(stepper: MatStepper): void {
    this.sending = true;
    this.adminReportService
      .matchData(this.reportIdentifier)
      .subscribe((resData) => {
        this.sending = false;
        this.paymentsData = [];
        this.paymentsDataSource.data = this.paymentsData;
        if (resData.out_succeed === true && resData.out_data != null) {

          this.adminReportService
            .getMissingMatchesGlobalDetails(this.reportIdentifier)
            .subscribe((resData) => {
              if (resData.out_succeed === true && resData.out_data != null) {
                this.matchGlobalData = <AdminReportMatchGlobalElement[]>(
                  resData.out_data
                );
                this.matchGlobalDataSource.data = this.matchGlobalData;
                stepper.next();
              } else if (
                resData.out_succeed === false &&
                resData.out_data != null
              ) {
                this.matchGlobalData = <AdminReportMatchGlobalElement[]>(
                  resData.out_data
                );
                this.matchGlobalDataSource.data = this.matchGlobalData;
              }
            });
        } else if (resData.out_succeed === false && resData.out_data != null) {
          this.matchData = <AdminReportMatchElement[]>resData.out_data;
          this.matchDataSource.data = this.matchData;

          this.adminReportService
            .getMissingMatchesGlobalDetails(this.reportIdentifier)
            .subscribe((resData) => {
              if (resData.out_succeed === true && resData.out_data != null) {
                this.matchGlobalData = <AdminReportMatchGlobalElement[]>(
                  resData.out_data
                );
                this.matchGlobalDataSource.data = this.matchGlobalData;

                stepper.next();
              } else if (
                resData.out_succeed === false &&
                resData.out_data != null
              ) {
                this.matchGlobalData = <AdminReportMatchGlobalElement[]>(
                  resData.out_data
                );
                this.matchGlobalDataSource.data = this.matchGlobalData;
              }
            });
        }
      });
  }

  getTotalItems(): number {
    return this.matchData
      .map((element) => element.total_items)
      .reduce((sum, value) => sum + value, 0);
  }

  getTotalRevenue(): number {
    return this.matchData
      .map((element) => element.total_revenue)
      .reduce((sum, value) => sum + value, 0);
  }

  generatePayments(stepper: MatStepper): void {
    this.sending = true;
    this.adminReportService
      .generatePayments(this.reportIdentifier)
      .subscribe((resData) => {
        this.sending = false;
        if (resData.out_succeed === true && resData.out_data != null) {
          this.paymentsData = <AdminPaymentElement[]>resData.out_data;
          this.paymentsDataSource.data = this.paymentsData;
          this.reportStatusId = 7;
        }
      });
  }

  validatePayments(stepper: MatStepper): void {
    this.sending = true;
    this.adminReportService
      .validatePayments(this.reportIdentifier)
      .subscribe((resData) => {
        this.sending = false;
        if (resData.out_succeed === true && resData.out_data != null) {
          this.paymentsData = <AdminPaymentElement[]>resData.out_data;
          this.paymentsDataSource.data = this.paymentsData;
          this.reportStatusId = 8;
        }
      });
  }

  publishPayments(stepper: MatStepper): void {
    this.sending = true;
    this.adminReportService
      .publishPayments(this.reportIdentifier)
      .subscribe((resData) => {
        this.sending = false;
        if (resData.out_succeed === true && resData.out_data != null) {
          this.paymentsData = <AdminPaymentElement[]>resData.out_data;
          this.paymentsDataSource.data = this.paymentsData;
          this.reportStatusId = 9;
        }
      });
  }

  waitForReciptsPayments(stepper: MatStepper): void {
    this.sending = true;
    this.adminReportService
      .waitForReciptsPayments(this.reportIdentifier)
      .subscribe((resData) => {
        this.sending = false;
        if (resData.out_succeed === true && resData.out_data != null) {
          this.paymentsData = <AdminPaymentElement[]>resData.out_data;
          this.paymentsDataSource.data = this.paymentsData;
          stepper.next();
        }
      });
  }

  onDownloadGeneratedPayments(): void {
    if (!this.isAdmin) return;

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Admin Payment Details CSV',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      headers: this.paymentsDisplayedColumns,
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(this.paymentsDataSource.data);
  }

  onDownload(): void {
    if (!this.isAdmin) return;

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Admin Matching Details CSV',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      headers: this.matchDisplayedColumns,
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(this.matchDataSource.data);
  }
}
