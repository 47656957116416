import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { AdminSubPartnerService } from './admin-sub-partner.service';

import { ExportToCsv } from 'export-to-csv';
import { Subscription } from 'rxjs';
import { SnackBarComponent } from 'src/app/components/snack-bar/snack-bar/snack-bar.component';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialog } from 'src/app/components/dialogs/delete-dialog/delete-dialog.component';
import { CreateAdminSubPartnerDialog } from './create-admin-sub-partner-dialog/create-admin-sub-partner-dialog.component';
import { AdminArtistService } from '../admin-artists/admin-artists.service';
import { AdminPartnerService } from '../admin-partners/admin-partner.service';
import { AuthService } from 'src/app/auth/auth.service';

export interface AdminSubPartnerElement {
  identifier: string;
  parentPartner: string;
  name: string;
  isActive: boolean;
  artist: string;
  accounts: number;
  createdBy: string;
  createdOn: string;
  modifiedBy: string;
  modifiedOn: string;
}

export interface AdminSimplePartnerElement {
  identifier: string;
  name: string;
}

export interface AdminSimpleArtistElement {
  identifier: string;
  fugaName: string;
}


@Component({
  selector: 'app-admin-sub-partners',
  templateUrl: './admin-sub-partners.component.html',
  styleUrls: ['./admin-sub-partners.component.scss']
})
export class AdminSubPartnersComponent implements OnInit, AfterViewInit, OnDestroy {

  private _subscriptions: Subscription[] = [];

  isLoading = false;

  showMetadata = false;
  private _adminShowMetadataColumns = ['identifier', 'parentPartner', 'name', 'artist', 'isActive', 'accounts', 'payments', 'createdBy', 'createdOn', 'modifiedBy', 'modifiedOn', 'action'];
  private _adminHideMetadataColumns = ['parentPartner', 'name', 'artist', 'isActive', 'accounts', 'payments', 'action'];
  private _moderatorHideMetadataColumns = ['parentPartner', 'name', 'artist', 'isActive', 'accounts', 'payments'];
  displayedColumns: string[];

  dataSource = new MatTableDataSource<AdminSubPartnerElement>();
  private data: AdminSubPartnerElement[] = [];
  partners: AdminSimplePartnerElement[] = [];
  artists: AdminSimpleArtistElement[] = [];

  editingElement: AdminSubPartnerElement = null;
  changedElement: AdminSubPartnerElement = null;

  private _isAdmin: boolean = false;

  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private _authService: AuthService, private _adminSubPartnerService: AdminSubPartnerService, private _adminPartnerService: AdminPartnerService, private _adminArtistService: AdminArtistService, private _dialog: MatDialog) {
    this._isAdmin = this._authService.isAdmin();
    this.displayedColumns = this._isAdmin ? this._adminHideMetadataColumns : this._moderatorHideMetadataColumns;
  }

  get isAdmin(): boolean {
    return this._isAdmin;
  }

  ngOnInit(): void {
    this.dataSource.data = this.data;

    this._subscriptions.push(this.loadAllSubPartners());

    if (this._isAdmin) {
      this._subscriptions.push(this.loadAllSimplePartners());
      this._subscriptions.push(this.loadAllSimpleArtists());
    }
  }

  private loadAllSubPartners(): Subscription {
    return this._adminSubPartnerService.getAllSubPartners()
    .subscribe(resData => {
      if (resData.out_succeed === true && resData.out_data != null) {
        this.data = <AdminSubPartnerElement[]>resData.out_data;
        this.dataSource.data = this.data;
      }
    });
  }

  private loadAllSimplePartners(): Subscription {
    return this._adminPartnerService.getAllPartnersSimple()
    .subscribe(resData => {
      if (resData.out_succeed === true && resData.out_data != null) {
        this.partners = <AdminSimplePartnerElement[]>resData.out_data;
      }
    });
  }

  private loadAllSimpleArtists(): Subscription {
    return this._adminArtistService.getAllArtistsSimple()
    .subscribe(resData => {
      if (resData.out_succeed === true && resData.out_data != null) {
        this.artists = <AdminSimpleArtistElement[]>resData.out_data;
      }
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());

    this._dialog.closeAll();
  }

  doFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onCreate(): void {

    if (!this.isAdmin)
      return;

    const dialogRef = this._dialog.open(CreateAdminSubPartnerDialog, {
      width: '400px',
      height: '400px',
      disableClose: true,
      data: { name: '', isActive: true, parentPartner: '', artist: '', partners: this.partners, artists: this.artists },
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result != null) {

        this.data.splice(0, 0, result.newSubPartner);
        this.dataSource.data = [...this.data];
      }
    });
  }

  onEdit(selectedElement: AdminSubPartnerElement): void {

    if (!this.isAdmin)
      return;

    this.editingElement = Object.assign({}, selectedElement);
    this.changedElement = Object.assign({}, selectedElement);
  }

  onEditCancel(): void {

    if (!this.isAdmin)
      return;

    this.editingElement = null;
    this.changedElement = null;
  }

  onEditSave(selectedElement: AdminSubPartnerElement): void {

    if (!this.isAdmin)
      return;

    let subscription: Subscription = this._adminSubPartnerService.editSubPartner(selectedElement.identifier, this.changedElement.name, this.changedElement.isActive)
    .subscribe(resData => {
        if (resData.out_succeed === true && resData.out_data != null) {
          var partnerElement: AdminSubPartnerElement = <AdminSubPartnerElement>resData.out_data;
          let index: number = this.data.findIndex(element => element === selectedElement);
          this.data.splice(index, 1, partnerElement);
          this.dataSource.data = [...this.data];
        }
      });

      this._subscriptions.push(subscription);

    this.editingElement = null;
    this.changedElement = null;
  }

  onDelete(selectedElement: AdminSubPartnerElement): void {

    if (!this.isAdmin)
      return;

    const dialogRef = this._dialog.open(DeleteDialog, {
      width: '400px',
      data: { name: selectedElement.name, delete: false }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        let subscription: Subscription = this._adminSubPartnerService.removeSubPartner(selectedElement.identifier)
        .subscribe(resData => {

          if (resData.out_succeed === true) {
            let index: number = this.data.findIndex(element => element === selectedElement);

            this.data.splice(index, 1)
            this.dataSource.data = [...this.data];
           }
        });

        this._subscriptions.push(subscription);
      }
    });
  }

  onMetadata() {

    if (!this.isAdmin)
      return;


    this.showMetadata = !this.showMetadata;

    if (this.showMetadata) {
      this.displayedColumns = this._adminShowMetadataColumns;

    } else {
      this.displayedColumns = this._adminHideMetadataColumns;
    }
  }

  onDownload() {

    if (!this.isAdmin)
      return;

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Admin Sub Partners CSV',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      headers: this.displayedColumns
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(this.dataSource.data);
  }

}