import { Component, OnInit, enableProdMode, OnDestroy } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { API } from './api.component';

enableProdMode();

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription[] = [];

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    if (API.DEBUG_MODE) {
      console.log('App on init');
      console.log('URL: ' + window.location.href);
    }

    let subscription0: Subscription = this.authService.isAlive().subscribe(
      (success) => {
        if (window.location.href.endsWith('forgot')) {
        } else {
          let subscription1: Subscription = this.authService
            .silentRefresh()
            .subscribe();

          this._subscriptions.push(subscription1);
        }

        let subscription2: Subscription =
          this.authService.componentUserObservable.subscribe((user) => {
            const isAuthenticated = !!user;
            if(API.DEBUG_MODE) 
            console.log('APP: is authenticated? ' + isAuthenticated);

            if (isAuthenticated) {
              this.router.navigate(['/dashboard']);
            }
          });

        this._subscriptions.push(subscription2);
      },
      (error) => {
        this.router.navigate(['/maintenance']);
      }
    );

    this._subscriptions.push(subscription0);
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
