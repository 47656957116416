import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';


import { ExportToCsv } from 'export-to-csv';
import { catchError, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError, Subscription } from 'rxjs';
import { SnackBarComponent } from 'src/app/components/snack-bar/snack-bar/snack-bar.component';
import { AdminArtistService } from './admin-artists.service';
import { AuthService } from 'src/app/auth/auth.service';

export interface AdminArtistElement {
  identifier: string;
  fugaName: string;
  fugaId: number;
  subPartner: string;
  createdBy: string;
  createdOn: string;
  modifiedBy: string;
  modifiedOn: string;
}

@Component({
  selector: 'app-admin-artists',
  templateUrl: './admin-artists.component.html',
  styleUrls: ['./admin-artists.component.scss']
})
export class AdminArtistsComponent implements OnInit, AfterViewInit, OnDestroy {

  private _subscriptions: Subscription[] = [];

  isLoading = false;

  showMetadata = false;
  private _adminShowMetadataColumns = ['identifier', 'fugaId', 'fugaName', 'createdBy', 'createdOn', 'modifiedBy', 'modifiedOn'];
  private _adminHideMetadataColumns = ['fugaId', 'fugaName'];
  private _moderatorHideMetadataColumns = ['fugaId', 'fugaName'];
  displayedColumns: string[];

  dataSource = new MatTableDataSource<AdminArtistElement>();
  private _data: AdminArtistElement[] = [];

  editingElement: AdminArtistElement = null;
  changedElement: AdminArtistElement = null;

  private _isAdmin: boolean = false;

  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private _authService: AuthService, private _adminArtistService: AdminArtistService, private _snackBarComponent: SnackBarComponent) {
    this._isAdmin = this._authService.isAdmin();
    this.displayedColumns = this._isAdmin ? this._adminHideMetadataColumns : this._moderatorHideMetadataColumns;
  }

  get isAdmin(): boolean {
    return this._isAdmin;
  }

  ngOnInit() {
    this.dataSource.data = this._data;

    this._subscriptions.push(this.loadAllArtists());
  }

  private loadAllArtists(): Subscription {
    return this._adminArtistService.getAllArtists()
    .subscribe(resData => {
      if (resData.out_succeed === true && resData.out_data != null) {

        this._data = <AdminArtistElement[]>resData.out_data;
        this.dataSource.data = this._data;
      }
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  doFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onMetadata(): void {
    if (!this.isAdmin)
      return;

    this.showMetadata = !this.showMetadata;

    if (this.showMetadata) {
      this.displayedColumns = this._adminShowMetadataColumns;

    } else {
      this.displayedColumns = this._adminHideMetadataColumns;
    }
  }

  onDownload(): void {
    if (!this.isAdmin)
      return;

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Admin Artists CSV',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      headers: this.displayedColumns
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(this.dataSource.data);
  }
}