import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth.service';
import { SnackBarComponent } from 'src/app/components/snack-bar/snack-bar/snack-bar.component';
import { Location } from '@angular/common';
import { API } from 'src/app/api.component';

@Component({
  selector: 'app-change',
  templateUrl: './change.component.html',
  styleUrls: ['./change.component.scss']
})
export class ChangeComponent implements OnInit {

  isLoading = false;
  passwordsDontMatch: boolean = false;

  constructor(private authService: AuthService, private location: Location, private snackBarComponent: SnackBarComponent) { }

  ngOnInit(): void {
  }

  onCancel() {
    this.location.back();
  }

  onSubmit(form: NgForm) {

    if(!form.valid) {
      return;
    }

    let password0: string = form.value.password0;
    let password1: string = form.value.password1;
    let password2: string = form.value.password2;

    if(password1 != password2) {
      this.passwordsDontMatch = true;
      if(API.DEBUG_MODE) 
      console.log("Passwords don't match...");
      this.snackBarComponent.errorMessage("Passwords don't match...");

    } else {

      this.isLoading = true;

      this.authService.change(password0, password1)
      .subscribe(resData => {
       
        if(resData.out_succeed == true) {
          this.authService.logout().subscribe();
        }
        this.isLoading = false;

      }, error => {

        this.isLoading = false;
      });

      form.reset();
    }
  }

}