import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth.service';
import { SnackBarComponent } from 'src/app/components/snack-bar/snack-bar/snack-bar.component';
import { Location } from '@angular/common';
import { API } from 'src/app/api.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  isLoading = false;
  passwordsDontMatch: boolean = false;

  firstNameValue: string;
  lastNameValue: string;
  emailValue: string;

  constructor(private authService: AuthService, private location: Location) {}

  ngOnInit(): void {
    this.authService.componentUserObservable.subscribe((user) => {
      let isAuthenticated = !!user; // user ? true : false;
      if (API.DEBUG_MODE) console.log('is authenticated? ' + isAuthenticated);

      if (isAuthenticated) {
        this.firstNameValue = user.firstName;
        this.lastNameValue = user.lastName;
        this.emailValue = user.email;
      }
    });
  }

  onCancel() {
    this.location.back();
  }

  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }

    let firstName: string = form.value.firstName;
    let lastName: string = form.value.lastName;
    let email: string = form.value.email;

    this.isLoading = true;

    this.authService.editProfile(firstName, lastName, email).subscribe(
      (responseData) => {
        if (responseData.out_succeed) {
          this.location.back();
        }
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }
}
