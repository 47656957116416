<div fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="10px" style="height: 100%;">
    <h1 mat-dialog-title>New Account</h1>
    <div fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="10px" style="height: 100%;">
        <p>New account details:</p>


        <mat-form-field>
            <mat-label>First Name</mat-label>
            <input matInput placeholder="First name" [(ngModel)]="data.firstName" [value]="data.firstName">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Last Name</mat-label>
            <input matInput placeholder="Last name" [(ngModel)]="data.lastName" [value]="data.lastName">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Email</mat-label>
            <input type="email" matInput placeholder="Email" [(ngModel)]="data.email" [value]="data.email">
        </mat-form-field>
        <mat-form-field>
            <mat-select [(value)]="data.authority" placeholder="Select authority...">
                <mat-option *ngFor="let option of data.authorities" [value]="option.name">{{ option.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-select [(value)]="data.partner" placeholder="Select partner...">
                <mat-option *ngFor="let option of data.partners" [value]="option.name">{{ option.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div mat-dialog-actions>
            <button mat-button (click)="onCancel()" cdkFocusInitial>Cancel</button>
            <button mat-button (click)="onCreate()">Create</button>
        </div>
    </div>
</div>