import { Injectable } from '@angular/core';
import { User } from './user.model';
import { catchError, tap } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { API } from '../api.component';
import { ResponseData } from '../response-data.model';
import { SnackBarComponent } from '../components/snack-bar/snack-bar/snack-bar.component';


export interface AuthResponseData {
  identifier: string;
  firstName: string;
  lastName: string;
  email: string;
  authorityId: number;
  isBlocked: boolean;
  partner: string;
  partnerAuthorityId: number;
  accessToken: string;
  accessTokenExpiresIn: number;
  refreshToken: string;
  refreshTokenExpiresIn: number;
}

@Injectable({ providedIn: 'root' })
export class AuthService {

  private _componentUser = new BehaviorSubject<User>(null);
  componentUserObservable = this._componentUser.asObservable();

  private _serviceAccessToken = new BehaviorSubject<string>(null);
  serviceAccessTokenObservable = this._serviceAccessToken.asObservable();

  private accessTokenAutoRefreshTimer: any;
  private _refreshToken: string;
  private _accessToken: string; 
  private _available: boolean = false;


  private static USER_ADMIN = 3;

  constructor(private httpClient: HttpClient, private router: Router, private _snackBarComponent: SnackBarComponent) {

    if (window.addEventListener) {
      window.addEventListener("storage", this._listener, false);
    }
  }

  private _listener = () => {
    if(API.DEBUG_MODE) 
    console.log("logout from storage");
    let value = localStorage.getItem('action');

    if (value === 'logout')
      this.logoutAction();

    if (value === 'refresh')
      this.silentRefresh().subscribe();
  }

  getUser() {
    return { ...this._componentUser }; // Copia do user (sem passar a referencia do atributo)
  }

  isAuthenticated() {
    return this._componentUser != null;
  }

  isAvailable() {
    return this._available;
  }

  isAdmin() {
    return this.isAuthenticated() && this._componentUser.value.authorityId === AuthService.USER_ADMIN;
  }

  private publicHeader() {
    return new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      });
  }

  private privateHeader() {
    return new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this._accessToken
      });
  }

  private refreshHeader() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Refresh-Token': 'Bearer ' + this._refreshToken
    });
  }



  isAlive() {
    return this.httpClient.get(API.AUTH_ALIVE, { headers: this.publicHeader() })
      .pipe(
        catchError(this.handleUnexpectedError),
        tap(resData => {
          this._available = true;
        })
      );
  }

  forgot(email: string) {
    const httpBody = { email: email };
    return this.httpClient.post<ResponseData>(API.AUTH_FORGOT, httpBody, { headers: this.publicHeader() })
      .pipe(
        catchError(this.handleUnexpectedError),
        tap(resData => this.defaultResponseHandling(resData, "An email was successfully sent to your inbox.", true))
      );
  }

  resetCode(email: string, code: string) {
    const httpBody = { email: email, code: code };
    return this.httpClient.post<ResponseData>(API.AUTH_RESET_CODE, httpBody, { headers: this.publicHeader() })
      .pipe(
        catchError(this.handleUnexpectedError),
        tap(resData => this.defaultResponseHandling(resData, "Your reset code has been successfully validated.", true))
      );
  }

  reset(email: string, code: string, password: string) {
    const httpBody = { email: email, code: code, password: password };
    return this.httpClient.post<ResponseData>(API.AUTH_RESET, httpBody, { headers: this.publicHeader() })
      .pipe(
        catchError(this.handleUnexpectedError),
        tap(resData => this.defaultResponseHandling(resData, "Your password has been successfully reset.", true))
      );
  }

  change(password0: string, password1: string) {
    const httpBody = { current: password0, new: password1, };
    return this.httpClient.post<ResponseData>(API.AUTH_CHANGE, httpBody, { headers: this.privateHeader() })
      .pipe(
        catchError(this.handleUnexpectedError),
        tap(resData => this.defaultResponseHandling(resData, "Your password has been successfully changed.", true))
      );
  }

  editProfile(firstName: string, lastName: string, email: string) {
    const httpBody = { first: firstName, last: lastName, email: email };
    return this.httpClient.post<ResponseData>(API.AUTH_EDIT_PROFILE, httpBody, { headers: this.privateHeader() })
      .pipe(
        catchError(this.handleUnexpectedError),
        tap(resData => {
          this.defaultResponseHandling(resData, "Your profile has been successfully edited.", true);

          if (resData.out_succeed == true) {
            let userCopy: User = this._componentUser.getValue();
            userCopy.setFirstName(<string>resData.out_data.firstName);
            userCopy.setLastName(<string>resData.out_data.lastName);
            userCopy.setEmail(<string>resData.out_data.email);

            this._componentUser.next(userCopy);
            localStorage.setItem('userData', JSON.stringify(userCopy));
          }
        })
      );
  }


  login(email: string, password: string, remember: boolean) {
    const httpBody = { email: email,  password: password, remember: remember };
    return this.httpClient.post<ResponseData>(API.AUTH_LOGIN, httpBody, { headers: this.publicHeader(), observe: 'response', responseType: "json", withCredentials: true })
      .pipe(
        catchError(this.handleUnexpectedError),
        tap(response => {
          if(API.DEBUG_MODE) 
          console.log(JSON.stringify(response));

          let resData = <ResponseData>response.body;

          this.defaultResponseHandling(resData, "You have successfully logged in.", true);

          if (resData.out_succeed == true) {
            
            if (this.accessTokenAutoRefreshTimer) {
              clearTimeout(this.accessTokenAutoRefreshTimer);
            }
            this.accessTokenAutoRefreshTimer = null;

            var authResponseData: AuthResponseData = <AuthResponseData>resData.out_data;
            this.handleAuthentication(authResponseData, true);
          }
        })
      );
  }


  autoRefresh() {
    return this.refresh(false);
  }

  silentRefresh() {
    return this.refresh(true);
  }

  private refresh(silentRefresh: boolean) {
    return this.httpClient.post<ResponseData>(API.AUTH_REFRESH, {}, { headers: this.refreshHeader(), withCredentials: true })
      .pipe(
        catchError(this.handleUnexpectedError),
        tap(resData => {

          if (resData.out_succeed == true) {
          
            if (this.accessTokenAutoRefreshTimer) {
              clearTimeout(this.accessTokenAutoRefreshTimer);
            }
            this.accessTokenAutoRefreshTimer = null;

            var authResponseData: AuthResponseData = <AuthResponseData>resData.out_data;
            this.handleAuthentication(authResponseData, false);
          }

          if(resData.out_succeed == false) {
            if(this._componentUser != null) {
              this.logoutAction();
              if(!silentRefresh)
                this._snackBarComponent.errorMessage("Your session expired, please login again.");
            }
          }
        })
      );
  }

  private handleAuthentication(authResponseData: AuthResponseData, loginMode: boolean) {

    const accessTokenExpirationDate = new Date(new Date().getTime() + authResponseData.accessTokenExpiresIn * 60 * 1000);

    this._refreshToken = authResponseData?.refreshToken;
    this._accessToken = authResponseData?.accessToken;

    const user = new User(
      authResponseData.identifier,
      authResponseData.firstName,
      authResponseData.lastName,
      authResponseData.email,
      authResponseData.authorityId,
      authResponseData.isBlocked,
      authResponseData.partner);

      if(this._componentUser.value == null) {
        this._componentUser.next(user);
        this._serviceAccessToken.next(this._accessToken);
      } else {
        this._serviceAccessToken.next(this._accessToken);
      }

      if(API.DEBUG_MODE) { 

        console.log("accessTokenExpiresIn: " + authResponseData.accessTokenExpiresIn);
        console.log("accessTokenExpiresIn *60*1000: " + (authResponseData.accessTokenExpiresIn * 60 * 1000));
      }
      

    this.autoLogout(authResponseData.accessTokenExpiresIn * 60 * 1000);
    
    if(loginMode) {
      localStorage.removeItem('action');
      localStorage.setItem("action", "refresh");
    }
  }

  logout() {
    const httpBody = {};

    const httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this._accessToken,
        'Refresh-Token': 'Bearer ' + this._refreshToken
      }),
      withCredentials: true
    };

    return this.httpClient.post<ResponseData>(API.AUTH_LOGOUT, httpBody, httpHeaders)
      .pipe(
        catchError(errorRes => {
          if(API.DEBUG_MODE) 
          console.log(JSON.stringify(errorRes));

          let errorMessage = 'An unknown error occurred!';
          if (!errorRes.error || !errorRes.error.out_msg) {
            return throwError(errorMessage);

          } else {
            errorMessage = errorRes.status + " " + errorRes.statusText;
          }

          if (errorRes.status == 401) {
            switch (errorRes.error.out_msg) {
              case 'INVALID_REFRESH_TOKEN':

                this._refreshToken = null;
                this._accessToken = null;
                this._componentUser.next(null);
                this._serviceAccessToken.next(null);
                //window.removeEventListener("storage", this._listener, false);
                localStorage.setItem('action', 'logout');
                this.router.navigate(['/login']);
                break;
            }
          }
          return throwError(errorMessage);
        }),
        tap(resData => {

          if (resData.out_succeed == true) {
            if(API.DEBUG_MODE) 
            console.log("logout executado com sucesso");
            this._refreshToken = null;
            this._accessToken = null;
            this._componentUser.next(null);
            this._serviceAccessToken.next(null);
            //window.removeEventListener("storage", this._listener, false);
            localStorage.setItem('action', 'logout');
            this.router.navigate(['/login']);
          }
        })
      );
  }

  private logoutAction() {
    this._refreshToken = null;
    this._accessToken = null;
    this._componentUser.next(null);
    this._serviceAccessToken.next(null);
    this.router.navigate(['/login']);
  }

  private autoLogout(expirationDuration: number) {
    this.accessTokenAutoRefreshTimer = setTimeout(() => {
      if(API.DEBUG_MODE) 
      console.log("Auto Refresh");
      this.silentRefresh().subscribe();
    }, 
    
    expirationDuration - 5000);
  }


  private defaultResponseHandling(resData: ResponseData, successMessage: string, condition: boolean) {
    if (resData.out_succeed == true && (condition == null || (condition != null && condition))) {
      this._snackBarComponent.successMessage(successMessage);
    } else {
      this.handleResponseWarning(resData);
    }

    if (resData.out_error == true) {
      this.handleResponseError(resData);
    }
  }

  private handleResponseWarning(resData: ResponseData) {
    
    if(resData.out_succeed === false)

    switch (resData.out_msg) {
      case 'INVALID_CREDENTIALS':
        this._snackBarComponent.warningMessage("Invalid credentials.");
        break;

      case 'BLOCKED_ACCOUNT':
        this._snackBarComponent.warningMessage("Your account is blocked.");
        break;

      case 'PARTNER_NOT_ACTIVE':
        this._snackBarComponent.warningMessage("Your partner account is not active.");
        break;

      case 'INVALID_RESET_CODE':
        this._snackBarComponent.warningMessage("Invalid reset code.");
        break;

      case 'EMAIL_ALREADY_IN_USE':
        this._snackBarComponent.warningMessage("Email already in use.");
        break;

      case 'WRONG_PASSWORD':
        this._snackBarComponent.warningMessage("Wrong password.");
        break;
      
      case 'UNABLE_TO_LOGOUT':
        this._snackBarComponent.warningMessage("An unexpected error occurred, please contact your system admin.");
        break;

      case 'INVALID_REFRESH_CODE':
        this._snackBarComponent.warningMessage("An unexpected error occurred, please contact your system admin.");
        break;

      case 'REFRESH_TOKEN_EXPIRED':
        this._snackBarComponent.warningMessage("An unexpected error occurred, please contact your system admin.");
        break;

      case 'UNAUTHORIZED':
        this._snackBarComponent.warningMessage("Unauthorized.");
        break;

      case 'UNKNOWN_ERROR':
        this._snackBarComponent.warningMessage("An unexpected error occurred, please contact your system admin.");
        break;
    }
  }
  
  private handleResponseError(resData: ResponseData) {
        if(resData.out_error) {
        switch (resData.out_msg) {

          case 'UNAUTHORIZED':
            this._snackBarComponent.errorMessage("Unauthorized.");
            break;

          case 'UNKNOWN_ERROR':
            this._snackBarComponent.errorMessage("An unknown error occurred, please contact your system admin.");
            break;
        }
      }
  }

  private handleUnexpectedError(errorRes: HttpErrorResponse) {
    if(API.DEBUG_MODE) 
    console.log("Errors: " + JSON.stringify(errorRes));


    if (errorRes.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      if(API.DEBUG_MODE) 
      console.log('An error occurred:', errorRes.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      if(API.DEBUG_MODE) 
      console.log(
        `Backend returned code ${errorRes.status}, ` +
        `body was: ${errorRes.error}`);

        let errorMessage = 'An unknown error occurred!';
        if (!errorRes.error || !errorRes.error.error) {
          return throwError(errorMessage);
        }
    
        switch (errorRes.status) {
          case 400:
            this._snackBarComponent.errorMessage("A problem occurred, please contact your system admin.");
            break;

          case 401:
            this._snackBarComponent.errorMessage("Unauthorized.");
            break;

          case 500:
            this._snackBarComponent.errorMessage("An unknown error occurred, please contact your system admin.");
            break;

        }
        return throwError(errorMessage);
    }
  }

}