<section class="center">

    <div >
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="isLoading">
            <app-loading-spinner></app-loading-spinner>
        </div>
 
        <div class="mat-elevation-z8  mid" *ngIf="!isLoading"> 
            <form fxLayout="column" fxLayoutAlign="flex-start center" fxLayoutGap="30px" #f="ngForm" (ngSubmit)="onSubmit(f)" *ngIf="!isLoading">
                <h1>Sign in</h1>

                <mat-form-field class="expand-width margin-top">
                    <input matInput type="email" placeholder="Your email" ngModel name="email" email required #emailInput="ngModel">

                    <mat-error *ngIf="!emailInput.hasError('required')">Invalid email!</mat-error>
                    <mat-error *ngIf="emailInput.hasError('required')">Field can't be empty!</mat-error>
                </mat-form-field>


                <mat-form-field class="expand-width" hintLabel="Should be at least 8 characters long.">
                    <input matInput type="password" placeholder="Your password" ngModel name="password" required minlength="8" #pwInput="ngModel">
                    <!--    <mat-hint align="end">{{ pwInput.value?.length }} / 8</mat-hint> -->
                    <mat-error *ngIf="pwInput.hasError('required')">Field can't be empty!</mat-error>
                    <mat-error *ngIf="!pwInput.hasError('required') && pwInput.errors?.minlength">Should be at least 8
                        characters long.</mat-error>
                </mat-form-field>

                <mat-checkbox class="expand-width" color="primary" name="remember" (change)="rememberMe($event.checked)" required>Remember me!</mat-checkbox>

                <!--
        <mat-checkbox ngModel name="agree" required color="primary" labelPosition="before">Agree to Terms and Conditions.</mat-checkbox> 
-->
                <button mat-raised-button class="expand-width margin-top" type="submit" color="primary" [disabled]="f.invalid">Submit</button>

                <a mat-list-item routerLink="/forgot">
                    <span class="nav-caption">Forgot password?</span>
                </a>

            </form>
        </div>


    </div>


</section>