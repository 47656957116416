import { Component, OnInit, ViewChild, OnDestroy, HostListener } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { ExportToCsv } from 'export-to-csv';
import { PaymentService } from './payments.service';
import { Subscription } from 'rxjs/internal/Subscription';


export interface PaymentElement {
  identifier: string;
  items: number;
  revenue: number;
  royalty: number;
  net: number;
  type: string;
  status: string;
  month: number;
  year: number;
  partner: string;
  publishedOn: string;
}

export interface PaymentElementToFile {
  items: number;
  revenue: number;
  royalty: number;
  net: number;
  type: string;
  status: string;
  month: number;
  year: number;
  publishedOn: string;
}

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit, OnDestroy {

  private _subscriptions: Subscription[] = [];

  private _data: PaymentElement[] = [];

  isLoading = false;

  showMetadata = false;
  displayedColumns = ['year', 'month', 'partner', 'items', 'revenue', 'royalty', 'net', 'type', 'status', 'publishedOn', 'action'];

  dataSource = new MatTableDataSource<PaymentElement>();
  private _isDownloading: boolean = false;

  single: any[];  
  view: any[];

  colorScheme = {
    domain: [
      '#a9b957',
      '#d5ce71',
      '#ffee8c',
    ],
  };
  cardColor: string = '#232837';

  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private _paymentService: PaymentService) { }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.view = null;
  }
  

  get isDownloading() {
    return this._isDownloading;
  }

  getTotalItems() {
    return this._data.map(element => element.items).reduce((sum, value) => sum + value, 0);
  }

  getTotalRevenue() {
    return this._data.map(element => element.revenue).reduce((sum, value) => sum + value, 0);
  }

  getTotalNet() {
    return this._data.map(element => element.net).reduce((sum, value) => sum + value, 0);
  }

  ngOnInit() {
    this._subscriptions.push(this.isDownloadingStatus());
    this._subscriptions.push(this.loadAllPayments());    
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  doFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private isDownloadingStatus(): Subscription {
    return this._paymentService.isDownloadingObservable
    .subscribe(isDownloading => this._isDownloading = isDownloading)
  }

  private loadAllPayments(): Subscription {
    return this._paymentService.getAllPayments()
    .subscribe(resData => {

          if (resData.out_succeed === true && resData.out_data != null) {
            this.single = resData.out_data.payments.overall;

            this._data = <PaymentElement[]>resData.out_data.payments.monthly;
            this.dataSource.data = this._data;
          }

          this.isLoading = false;

        }, error => {

          this.isLoading = false;
        }
      );
  }

  onDownloadData(element: PaymentElement) {
    this._paymentService.getPaymentData(element.identifier)
    .subscribe(resData => {
      if (resData != null) {
        const blob = new Blob([resData], { type: 'text/csv;charset=utf-8' });
        let filename = "Report-" + element.partner.replace(new RegExp(' ', 'g'), "_") + "-" + element.year + "-" + element.month + ".csv"
        saveAs(blob, filename);
      } 
    });
  }

  onDownload() {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Payments CSV',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      headers: this.displayedColumns
    };

    const csvExporter = new ExportToCsv(options);
    var aux: PaymentElementToFile[] = this.dataSource.data.map(element => <PaymentElementToFile>{year: element.year, month: element.month, items: element.items, revenue: element.revenue, publishedOn: element.publishedOn});
    csvExporter.generateCsv(aux);
  }

}
