import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MatDatepicker } from '@angular/material/datepicker';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
//import {default as _rollupMoment, Moment} from 'moment';
import { Moment } from 'moment';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { FormControl } from '@angular/forms';
import { AdminSimplePartnerElement } from '../admin-payments.component';
import { SnackBarComponent } from 'src/app/components/snack-bar/snack-bar/snack-bar.component';
import { API } from 'src/app/api.component';



//const moment = _rollupMoment || _moment;
const moment = _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};



export interface CreateAdminPaymentDialogData {
  year: number;
  month: number;
  revenue: number;
  royalty: number;
  partnerName: string;
  partners: AdminSimplePartnerElement[];
}

@Component({
  selector: 'app-create-admin-payment-dialog',
  templateUrl: './create-admin-payment-dialog.component.html',
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CreateAdminPaymentDialog {

  date = new FormControl(moment());

  private month: number = -1;
  private year: number = -1;
  
  constructor(public dialogRef: MatDialogRef<CreateAdminPaymentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: CreateAdminPaymentDialogData, private _snackBarComponent: SnackBarComponent) {
    }

    chosenYearHandler(normalizedYear: Moment) {
      const ctrlValue = this.date.value;
      ctrlValue.year(normalizedYear.year());
      this.date.setValue(ctrlValue);
      this.year = normalizedYear.year();
      if(API.DEBUG_MODE) 
      console.log("Year Changed: " + normalizedYear.year());
    }
  
    chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
      const ctrlValue = this.date.value;
      ctrlValue.month(normalizedMonth.month());
      this.date.setValue(ctrlValue);
      datepicker.close();
      this.month = normalizedMonth.month() + 1;
      if(API.DEBUG_MODE) 
      console.log("Month Changed: " + normalizedMonth.month() + 1);
    }
  
    myFilter = (m: Moment | null): boolean => {
      const selectedDate = m.toDate();
      const currentDate = new Date();

      if (selectedDate.getFullYear() !== currentDate.getFullYear())
        return selectedDate.getFullYear() <= currentDate.getFullYear();
  
      else if (selectedDate.getMonth() !== currentDate.getMonth())
        return selectedDate.getMonth() <= currentDate.getMonth();
    }
    
  onCancel(): void {
    this.dialogRef.close();
  }

  onCreate(): void {
    if(this.year === null || +this.year === -1)
      this._snackBarComponent.errorMessage("Invalid year.");

    else if(this.month === null || +this.month === -1)
      this._snackBarComponent.errorMessage("Invalid month.");

    else if(this.data.partnerName === null)
      this._snackBarComponent.errorMessage("A partner must be selected.");

    else if(this.data.revenue === null || +this.data.revenue < 0)
      this._snackBarComponent.errorMessage("Revenue must be >= 0.");

    else if(this.data.royalty === null || +this.data.royalty < 0 || +this.data.royalty > 100)
      this._snackBarComponent.errorMessage("Royalty must be a percentage >= 0 and <= 100.");

    else {
      this.data.month = this.month;
      this.data.year = this.year;
      this.dialogRef.close(this.data);
    }
  }
}
