<div fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="10px" style="height: 100%;">
    <h1 mat-dialog-title>New Payment</h1>
    <div fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="10px" style="height: 100%;">
        <p>New payment details:</p>

        <mat-form-field class="example-full-width">
            <mat-label>Month and Year</mat-label>
            <input matInput [matDatepickerFilter]="myFilter" [matDatepicker]="dp" [formControl]="date"
                [disabled]=true>
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp startView="multi-year"
                (yearSelected)="chosenYearHandler($event)"
                (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker" [disabled]=false>
            </mat-datepicker>
        </mat-form-field>

        <mat-form-field>
            <mat-select [(value)]="data.partnerName" placeholder="Select partner/subpartner...">
                <mat-option *ngFor="let option of data.partners" [value]="option.name">{{ option.name }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Revenue [€]</mat-label>
            <input type="number" matInput placeholder="Revenue" [(ngModel)]="data.revenue">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Royalty [%]</mat-label>
            <input type="number" matInput placeholder="Royalty" [(ngModel)]="data.royalty">
        </mat-form-field>

        <div mat-dialog-actions>
            <button mat-button (click)="onCancel()" cdkFocusInitial>Cancel</button>
            <button mat-button (click)="onCreate()">Create</button>
        </div>
    </div>
</div>