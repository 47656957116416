import { Component, OnInit, HostListener, ViewChild, Input, OnDestroy } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DashboardService, DataChartElement } from 'src/app/user/dashboard/dashboard.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { API } from 'src/app/api.component';

/*
var single = [
  {
    "name": "Germany",
    "value": 8940000
  },
  {
    "name": "USA",
    "value": 5000000
  },
  {
    "name": "France",
    "value": 7200000
  },
  {
    "name": "UK",
    "value": 6200000
  }
];

*/

@Component({
  selector: 'app-type-of-sale-chart',
  templateUrl: './type-of-sale-chart.component.html',
  styleUrls: ['./type-of-sale-chart.component.scss']
})
export class TypeOfSaleChartComponent implements OnInit, OnDestroy {

  @Input() showTypeOfSaleChart: boolean;

  displayedColumns: string[] = ['name', 'revenue', 'items'];
  dataSource = new MatTableDataSource<DataChartElement>();
  footer: DataChartElement;

  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  private _subscriptions: Subscription[] = [];

  single: any[];
  view: any[];

  // options
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = true;
  legendPosition: string = 'right';

  colorScheme = {
    domain: ['#00876c', '#459e70', '#72b373', '#9fc878', '#cedc80', '#ffee8c', '#fdcd70', '#f9ab5c', '#f18851', '#e5644e', '#d43d51']
  };
  
  constructor(private _dashboardService: DashboardService) {
    //Object.assign(this, { single });
  }

  ngOnInit(): void {
    
    let subscription: Subscription = this._dashboardService.typeOfSaleDataObservable
    .subscribe(data => {
      this.footer = data.shift();
      this.dataSource.data = data;
      this.single = data;
    });


    this._subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }


  onSelect(data): void {
    if(API.DEBUG_MODE) 
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    if(API.DEBUG_MODE) 
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    if(API.DEBUG_MODE) 
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.view = null;
  }
}

