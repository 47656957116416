

<div style="height: 100%;">
    <div style="width: 100%; height: 100%; padding: 0;" [hidden]="!showLabelChart">
        <ngx-charts-bar-vertical
            [view]="view"
            [scheme]="colorScheme"
            [results]="single"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            (select)="onSelect($event)"
            (activate)="onActivate($event)"
            (deactivate)="onDeactivate($event)"
            [hidden]="!showLabelChart">
        </ngx-charts-bar-vertical>
  
    </div>

    <div [hidden]="showLabelChart">
        <mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header="name">Name</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.name }}</mat-cell>
                <mat-footer-cell *matFooterCellDef> {{ footer != null ? footer.name : '' }}</mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="revenue">
                <mat-header-cell *matHeaderCellDef mat-sort-header="revenue">Revenue [€]</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.value }}</mat-cell>
                <mat-footer-cell *matFooterCellDef> {{ footer != null ? footer.value : '' }}</mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="items">
                <mat-header-cell *matHeaderCellDef mat-sort-header="items">Items</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.items }}</mat-cell>
                <mat-footer-cell *matFooterCellDef> {{ footer != null ? footer.items : '' }}</mat-footer-cell>
            </ng-container>

            <mat-header-row style="min-height: 25px;" *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row style="min-height: 25px;" *matRowDef="let row; columns: displayedColumns;"></mat-row>
            <mat-footer-row style="min-height: 25px;" class="table-row-make-black" *matFooterRowDef="displayedColumns; sticky: true"></mat-footer-row>
        </mat-table>  
    </div>
</div>