import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';


import { ExportToCsv } from 'export-to-csv';
import { Subscription } from 'rxjs';
import { AdminPaymentService } from './admin-payments.service';
import { SnackBarComponent } from 'src/app/components/snack-bar/snack-bar/snack-bar.component';

import { saveAs } from 'file-saver';
import { CreateAdminPaymentDialog } from './create-admin-payment-dialog/create-admin-payment-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AdminPartnerService } from '../admin-partners/admin-partner.service';
import { DeleteDialog } from 'src/app/components/dialogs/delete-dialog/delete-dialog.component';
import { AuthService } from 'src/app/auth/auth.service';
import { API } from 'src/app/api.component';


export interface AdminPaymentElement {
  identifier: string;
  items: number;
  revenue: number;
  royalty: number;
  net: number;
  month: number;
  year: number;
  partner: string;
  partnerIdentifier: string;
  type: string;
  typeId: number;
  status: string;
  statusId: number;
  reportId: number;
  publishedOn: string;
  createdBy: string;
  createdOn: string;
  modifiedBy: string;
  modifiedOn: string;
}

export interface AdminSimplePartnerElement {
  identifier: string;
  name: string;
}

export interface AdminSimplePaymentStatusElement {
  id: string;
  name: string;
}


@Component({
  selector: 'app-admin-payments',
  templateUrl: './admin-payments.component.html',
  styleUrls: ['./admin-payments.component.scss']
})
export class AdminPaymentsComponent implements OnInit, AfterViewInit, OnDestroy {

  private _subscriptions: Subscription[] = [];

  isLoading = false;

  showMetadata = false;
  private _adminShowMetadataColumns = ['identifier', 'year', 'month', 'partner', 'partnerIdentifier', 'items', 'revenue', 'royalty', 'net', 'type', 'typeId', 'notes', 'status', 'statusId', 'reportId', 'publishedOn', 'createdBy', 'createdOn', 'modifiedBy', 'modifiedOn', 'action'];
  private _adminHideMetadataColumns = ['year', 'month', 'partner', 'items', 'revenue', 'royalty', 'net', 'type', 'status', 'publishedOn', 'action'];
  private _moderatorHideMetadataColumns = ['year', 'month', 'partner', 'items', 'revenue', 'royalty', 'net', 'type', 'status', 'publishedOn', 'action'];
  displayedColumns: string[];

  dataSource = new MatTableDataSource<AdminPaymentElement>();
  private _data: AdminPaymentElement[] = [];
  partners: AdminSimplePartnerElement[] = [];
  paymentStatusData: AdminSimplePaymentStatusElement[] = [];

  editingElement: AdminPaymentElement = null;
  changedElement: AdminPaymentElement = null;

  private _isAdmin: boolean = false;

  private _isDownloading: boolean = false;


  single: any[];  
  view: any[];

  colorScheme = {
    domain: [
      '#a9b957',
      '#d5ce71',
      '#ffee8c',
    ],
  };
  cardColor: string = '#232837';


  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;


  constructor(private _authService: AuthService, private _adminPaymentService: AdminPaymentService, private _adminPartnerService: AdminPartnerService, private _snackBarComponent: SnackBarComponent, public _dialog: MatDialog) {
    this._isAdmin = this._authService.isAdmin();
    this.displayedColumns = this._isAdmin ? this._adminHideMetadataColumns : this._moderatorHideMetadataColumns;
  }

  get isDownloading() {
    return this._isDownloading;
  }

  get isAdmin() {
    return this._isAdmin;
  }

  ngOnInit() {
    this.dataSource.data = this._data;

    this._subscriptions.push(this.isDownloadingStatus());
    this._subscriptions.push(this.loadAllSimplePaymentStatus());
    this._subscriptions.push(this.loadAllPayments());

    if(this.isAdmin) {
      this._subscriptions.push(this.loadAllSimplePartners());
    }
  }

  lowerThan(v1: number, v2: number): boolean {
    return v1 < v2;
  }

  biggerThan(v1: number, v2: number): boolean {
    return v1 > v2;
  }

  private isDownloadingStatus(): Subscription {
    return this._adminPaymentService.isDownloadingObservable
    .subscribe(isDownloading => this._isDownloading = isDownloading)
  }

  private loadAllPayments(): Subscription {
    return this._adminPaymentService.getAllPayments()
    .subscribe(resData => {

          if (resData.out_succeed === true && resData.out_data != null) {
            this.single = resData.out_data.payments.overall;

            this._data = <AdminPaymentElement[]>resData.out_data.payments.monthly;
            this.dataSource.data = this._data;
          }

          this.isLoading = false;

        }, error => {

          this.isLoading = false;
        }
      );
  }

  private loadAllSimplePartners(): Subscription {
    return this._adminPartnerService.getAllPartnersSimple()
    .subscribe(resData => {
      if (resData.out_succeed === true && resData.out_data != null) {
        this.partners = <AdminSimplePartnerElement[]>resData.out_data;
      }
    });
  }

  private loadAllSimplePaymentStatus(): Subscription {
    return this._adminPaymentService.getAllPaymentStatusSimple()
    .subscribe(resData => {
      if (resData.out_succeed === true && resData.out_data != null) {
        this.paymentStatusData = <AdminSimplePaymentStatusElement[]>resData.out_data;
      }
    });
  }

  onDownloadData(element: AdminPaymentElement) {
    this._adminPaymentService.getPaymentData(element.identifier)
    .subscribe(resData => {
      if (resData != null) {
        const blob = new Blob([resData], { type: 'text/csv;charset=utf-8' });
        let filename = "Report-" + element.partner.replace(new RegExp(' ', 'g'), "_") + "-" + element.year + "-" + element.month + ".csv"
        saveAs(blob, filename);
      } 
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());

    this._dialog.closeAll();
  }

  doFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  onCreate(): void {

    if (!this.isAdmin)
    return;
    
    const dialogRef = this._dialog.open(CreateAdminPaymentDialog, {
      width: '400px',
      height: '500px',
      disableClose: true,
      data: { year: -1, month: -1, revenue: null, royalty: null, partnerName: null, partners: this.partners }
    });

    let subscription: Subscription  = dialogRef.afterClosed()
    .subscribe(result => {

      if (result != null) {

        let partnerIdentifier = null;

        if (result.partnerName === null || result.partnerName === "") {
          partnerIdentifier = null;
        } else {
          const partner = this.partners.find(element => element.name === result.partnerName);

          if (partner != null)
            partnerIdentifier = partner.identifier;
          else
            partnerIdentifier = null;
        }

        let subscription: Subscription = this._adminPaymentService.createPayment(result.year, result.month, result.revenue, result.royalty, partnerIdentifier)
        .subscribe(resData => {
          if (resData.out_succeed === true && resData.out_data != null) {
            var partnerElement: AdminPaymentElement = <AdminPaymentElement>resData.out_data;
            this._data.splice(0, 0, partnerElement);
            this.dataSource.data = [...this._data];
          }
        });

          this._subscriptions.push(subscription);
      }
    });

    this._subscriptions.push(subscription);
  }

  onEdit(selectedElement: AdminPaymentElement): void {
    this.editingElement = Object.assign({}, selectedElement);
    this.changedElement = Object.assign({}, selectedElement);
  }

  onEditCancel(): void {
    this.editingElement = null;
    this.changedElement = null;
  }

  onEditSave(selectedElement: AdminPaymentElement): void {
    if(this.changedElement.royalty === this.editingElement.royalty && this.changedElement.status === this.editingElement.status) {
      this.editingElement = null;
      this.changedElement = null;
    }
    
    if(this.changedElement.royalty === null || +this.changedElement.royalty < 0 || +this.changedElement.royalty > 100) {
      this._snackBarComponent.errorMessage("Royalty must be a percentage >= 0 and <= 100.");
      return;
    }

    let paymentStatusId = null;

    if (this.changedElement.status === null || this.changedElement.status === "") {
      paymentStatusId = null
    } else {
      const paymentStatus = this.paymentStatusData.find(element => element.name === this.changedElement.status);
      paymentStatusId = paymentStatus.id;
    }

    let subscription: Subscription = this._adminPaymentService.editPayment(selectedElement.identifier, this.changedElement.royalty, paymentStatusId)
    .subscribe(resData => {
      if (resData.out_succeed === true && resData.out_data != null) {
        var paymentElement: AdminPaymentElement = <AdminPaymentElement>resData.out_data;

        let index: number = this._data.findIndex(element => element === selectedElement);
        if(API.DEBUG_MODE) 
        console.log(this._data.findIndex(element => element.identifier === selectedElement.identifier));

        this._data.splice(index, 1, paymentElement);
        this.dataSource.data = [...this._data];
      }
    });

      this._subscriptions.push(subscription);

    this.editingElement = null;
    this.changedElement = null;
  }

  onDelete(selectedElement: AdminPaymentElement): void {

    if (!this.isAdmin)
    return;
    if(API.DEBUG_MODE) 
    console.log("Delete Listener: " + JSON.stringify(selectedElement));

    const dialogRef = this._dialog.open(DeleteDialog, {
      width: '400px',
      data: { name: selectedElement.partner + "'s payment", delete: false }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let subscription: Subscription = this._adminPaymentService.removePayment(selectedElement.identifier)
        .subscribe(resData => {
            if (resData.out_succeed === true) {
              let index: number = this._data.findIndex(element => element === selectedElement);
              if(API.DEBUG_MODE) 
              console.log(this._data.findIndex(element => element.identifier === selectedElement.identifier));

              this._data.splice(index, 1)
              this.dataSource.data = [...this._data];
            }
          });

          this._subscriptions.push(subscription);
      }
    });
  }

  onMetadata(): void {
    
    if (!this.isAdmin)
      return;

    this.showMetadata = !this.showMetadata;

    if (this.showMetadata) {
      this.displayedColumns = this._adminShowMetadataColumns;

    } else {
      this.displayedColumns = this._adminHideMetadataColumns;
    }
  }

  onDownload(): void {
    
    if (!this.isAdmin)
      return;

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Admin Payments CSV Olá',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      headers: this.displayedColumns
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(this.dataSource.data);
  }

}