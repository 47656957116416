<ngx-charts-area-chart
    [view]="view"
    [scheme]="colorScheme"
    [legend]="legend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxis]="xAxis"
    [yAxis]="yAxis"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [timeline]="timeline"
    [results]="multi2"
    [gradient] ="gradient"
    (select)="onSelect($event)">
</ngx-charts-area-chart>